import { SwitchVerticalIcon } from "@heroicons/react/solid";
import Button from "../common/Button";
import CustomToast from "../common/CustomToast";
import CurrencyInput from "../CurrencyInput";
import useSwap from "./useSwap";
import ConfirmationModal from "../CustomModal/ConfirmationModal";

const initialSwapResponse = {
  message: "",
  status: "",
};

const initialAmount = {
  from: 0,
  to: 0,
};

const Swap: React.FC<{ userId: string }> = ({ userId }) => {
  const {
    amount,
    currency,
    loading,
    showModal,
    swapResponse,
    handleAmountChange,
    handleCurrencyChange,
    handleSwap,
    isSwapButtonDisabled,
    setShowModal,
    setSwapResponse,
  } = useSwap(userId, initialAmount, initialSwapResponse);
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-sm font-semibold text-gray-600">Swap</h3>

      <div className="mb-3">
        <CurrencyInput
          onAmountChange={handleAmountChange}
          onCurrencyChange={handleCurrencyChange}
          selectedCurrency={currency.from}
          amount={amount.from}
          name="from"
        />
      </div>
      <div className="mb-3">
        <CurrencyInput
          onAmountChange={handleAmountChange}
          onCurrencyChange={handleCurrencyChange}
          selectedCurrency={currency.to}
          amount={amount.to}
          name="to"
        />
      </div>

      <div className="mt-2">
        <Button
          variant="outline"
          colorScheme="cyan"
          className="w-full border-indigo-300"
          disabled={isSwapButtonDisabled()}
          onClick={() => setShowModal(true)}
          leftIcon={<SwitchVerticalIcon className="h-5 w-5" />}
        >
          Swap
        </Button>
      </div>

      {(swapResponse as any).status && (
        <CustomToast
          variant={(swapResponse as any).status}
          message={(swapResponse as any).message}
          onClose={() => setSwapResponse(initialSwapResponse)}
        />
      )}

      <ConfirmationModal
        isOpen={showModal}
        label={
          <>
            <h3>
              Are you sure you want to swap &nbsp;
              <span className="text-indigo-500 text-center">
                {amount.from}&nbsp;{currency.from.code}&nbsp;
              </span>
              to &nbsp;
              <span className="text-indigo-500">
                {amount.to}&nbsp;{currency.to.code}
              </span>
              ?
            </h3>
            <div className="text-sm font-light mt-3">
              Double check the amount before clicking confirm
            </div>
          </>
        }
        cancelAction={() => setShowModal(false)}
        cancelActionLabel="No"
        confirmAction={handleSwap}
        confirmActionLabel="Yes"
        loadingLabel={loading ? "Swapping..." : ""}
        loading={loading}
        title="Confirm Swap"
      />
    </div>
  );
};

export default Swap;
