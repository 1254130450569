import { useEffect, useState } from "react";
import Select from "react-select";
import { FilterInputProps } from "./types";
const mapOptionsToList = (options: Record<any, string>) => {
  return Object.keys(options).map((key) => ({
    value: key,
    label: options[key],
  }));
};

const SelectFilter = ({
  onChange,
  value,
  attribute,
  options,
}: FilterInputProps) => {
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>();

  useEffect(() => {
    if (!options) return;
    setSelectOptions(mapOptionsToList(options as Record<any, string>));
    onChange(selectedOption, attribute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (item: any) => {
    setSelectedOption(item);
    onChange(item?.value?.toString(), attribute);
  };

  return (
    <Select
      options={selectOptions}
      value={selectedOption}
      onChange={handleChange}
      className="rounded-l-md border-r-1 border-l-1  border-gray-300  text-gray-900 focus:ring-indigo-500 focus:border-blue-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
    />
  );
};

export default SelectFilter;
