import { useCallback, useEffect, useState } from "react";
import { kycService } from "../../../services/kycService";
import {
  AfriexKYCProcessor,
  AfriexPerson,
  AfriexVerificationStatus,
  AfriexVirtualAccount,
  AfriexVirtualApplication,
} from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { KYCDetailsReturnProps } from "./types";
import { userService } from "../../../services/userService";

const useKYCDetails = (userId: string): KYCDetailsReturnProps => {
  const [userDetails, setUserDetails] = useState<AfriexPerson>(
    {} as AfriexPerson
  );
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [virtualAccountApplication, setVirtualAccountApplication] =
    useState<AfriexVirtualApplication>({} as AfriexVirtualApplication);

  const [virtualAccountInfo, setVirtualAccountInfo] =
    useState<AfriexVirtualAccount>({} as AfriexVirtualAccount);

  const toggleModal = () => setShowModal(!showModal);

  const fetchKYCDetails = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        throw new Error("User Id is required");
      }
      setIsLoadingUserDetails(true);
      const [kycResponse, virtualAccountResponse, userResponse] =
        await Promise.all([
          await kycService.getKYCDetails(userId),
          await kycService.getVirtualAccount(userId),
          await userService.getUser(userId),
        ]);
      setUserDetails(kycResponse);
      setVirtualAccountApplication(
        userResponse?.metadata
          ?.virtualAccountApplication as AfriexVirtualAccount
      );
      setVirtualAccountInfo(virtualAccountResponse[0]);
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage("Failed to fetch KYC details:" + errorMessage);
    } finally {
      setIsLoadingUserDetails(false);
    }
  }, [userId]);

  const handleVerify = useCallback(
    async (shouldVerify: boolean): Promise<void> => {
      try {
        if (!userId) {
          throw new Error("User Id is required");
        }
        setIsLoadingUserDetails(true);
        const status = shouldVerify
          ? AfriexVerificationStatus.Success
          : AfriexVerificationStatus.Failed;
        await kycService.verifyUser(userId, status);
        showSuccessMessage("KYC status updated successfully");
      } catch (err: any) {
        const errorMessage = err.message
          ? err.message
          : err.response?.data.message;
        showErrorMessage("Failed to update KYC status:" + errorMessage);
      } finally {
        setIsLoadingUserDetails(false);
      }
    },
    [userId]
  );

  const handleSubmission = useCallback(
    async (
      processor: AfriexKYCProcessor = AfriexKYCProcessor.Veriff
    ): Promise<void> => {
      try {
        if (!userId) {
          throw new Error("User Id is required");
        }
        setIsLoadingUserDetails(true);
        await kycService.submitKYC(userId, processor);
        showSuccessMessage("KYC submitted successfully for verification");
      } catch (err: any) {
        const errorMessage = err.message
          ? err.message
          : err.response?.data.message;
        showErrorMessage(
          "Failed to submit KYC for verification:" + errorMessage
        );
      } finally {
        setIsLoadingUserDetails(false);
      }
    },
    [userId]
  );

  const handleDeleteSession = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        throw new Error("User Id is required");
      }
      setIsLoadingUserDetails(true);
      await kycService.deleteVeriffSesion(userId);
      showSuccessMessage("Veriff session successfully deleted");
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage("Failed to delete Veriff session" + errorMessage);
    } finally {
      setIsLoadingUserDetails(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchKYCDetails();
  }, [fetchKYCDetails]);

  return {
    handleVerify,
    handleSubmission,
    handleDeleteSession,
    isLoadingUserDetails,
    showModal,
    toggleModal,
    user: userDetails,
    virtualAccountApplication,
    virtualAccountInfo,
  };
};

export default useKYCDetails;
