import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { appPaths } from "../config/routesConfig";
import { TransactionTypes } from "../types/Transaction";
import { transactionService } from "../services/transactionService";
import { showErrorMessage } from "../utils/showErrorMessage";
import { AfriexTransactionStatus } from "../types";

type TransactionContextType = {
  totalPendingTransactions: number | null;
  fetchPendingTransactions: (limit?: number, page?: number) => Promise<any>;
  totalFailedTransactions: number | null;
  fetchFailedTransactions: (limit?: number, page?: number) => Promise<any>;
  menuSuffixes: any;
};

// initialize context
const TransactionContext = createContext<TransactionContextType>(
  {} as TransactionContextType
);

// Provider
export default function TransactionProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [totalPendingTransactions, setTotalPendingTransactions] = useState<
    number | null
  >(null);
  const [totalFailedTransactions, setTotalFailedTransactions] = useState<
    number | null
  >(null);

  // Text Added After a menu Label
  const [menuSuffixes, setMenuSuffixes] = useState({});

  const handleSetTotalPendingTransactions = (total: number) => {
    setTotalPendingTransactions(total);
    // update pending Transactions with the total Number of Pending Transactions
    setMenuSuffixes({
      ...menuSuffixes,
      [`${appPaths.transactions}/pending`]: `(${total})`,
    });
  };

  const handleSetTotalFailedTransactions = (total: number) => {
    setTotalFailedTransactions(total);
    // update pending Transactions with the total Number of Pending Transactions
    setMenuSuffixes({
      ...menuSuffixes,
      [`${appPaths.transactions}/failed`]: `(${total})`,
    });
  };

  async function fetchPendingTransactions(limit = 10, page = 0): Promise<any> {
    try {
      const response = await transactionService.getTransactionList({
        status: AfriexTransactionStatus.PENDING,
        type: TransactionTypes.WITHDRAW,
        limit,
        page,
      });
      handleSetTotalPendingTransactions(response.total);
      return response.data;
    } catch (err) {
      showErrorMessage("Failed to fetch pending transactions");
    }
  }

  async function fetchFailedTransactions(limit = 10, page = 0): Promise<any> {
    try {
      const response = await transactionService.getTransactionList({
        status: AfriexTransactionStatus.FAILED,
        type: TransactionTypes.WITHDRAW,
        limit,
        page,
      });
      handleSetTotalFailedTransactions(response.total);
      return response.data;
    } catch (err) {
      showErrorMessage("Failed to fetch failed transactions");
    }
  }

  const memoedValue = useMemo(
    () => ({
      totalPendingTransactions,
      totalFailedTransactions,
      fetchPendingTransactions,
      fetchFailedTransactions,
      menuSuffixes,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuSuffixes]
  );

  return (
    <TransactionContext.Provider value={memoedValue}>
      {children}
    </TransactionContext.Provider>
  );
}

export function useTransactionContext() {
  return useContext(TransactionContext);
}
