import { Button, CustomInput } from "../../../components";

const TierUserSingleUploadForm = ({
  onClose,
  userEmail,
  handleSubmit,
  setUserEmail,
  isDisabled,
  submitLabel,
}: any) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}
    className="flex flex-col space-y-5"
  >
    <div className="mt-5">
      <CustomInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setUserEmail(e.target?.value ?? userEmail)
        }
        name="singleUpload"
        label="Email, Phone Number or ID"
        type="text"
        value={userEmail ?? ""}
        className="w-full isFull"
        placeholder="Enter text..."
      />
    </div>

    <div className="flex w-full justify-end space-x-2 py-5">
      <Button
        type="button"
        onClick={onClose}
        variant="outline"
        colorScheme="cyan"
      >
        Cancel
      </Button>

      <Button
        disabled={isDisabled}
        type="submit"
        variant="solid"
        colorScheme="cyan"
      >
        {submitLabel}
      </Button>
    </div>
  </form>
);

export default TierUserSingleUploadForm;
