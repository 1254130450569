export enum AfriexAccountError {
  FailedToConnect = "FAILED_TO_CONNECT",
  FailedToSignup = "FAILED_TO_SIGNUP",
  FailedToSaveAccount = "FAILED_TO_SAVE_ACCOUNT",
  FailedToSaveUser = "FAILED_TO_SAVE_USER",
  FailedToSavePerson = "FAILED_TO_SAVE_PERSON",
  FailedToSaveToRedis = "FAILED_TO_SAVE_TO_REDIS",
  FailedToCreateStripeAccount = "FAILED_TO_CREATE_STRIPE_ACCOUNT",
  FailedToCreateStripeCustomer = "FAILED_TO_CREATE_STRIPE_CUSTOMER",
}
