import { useParams } from "react-router-dom";
import { Header, TableSkeleton } from "../../../components";
import useUserDetails from "../../../hooks/useUserDetails";
import useRewardsTransactions from "./useRewardsTransactions";
import RewardTransactionTable from "./RewardTransactionTable";

const RewardTransactions = () => {
  const { userId } = useParams();
  const { loading, transactions } = useRewardsTransactions(userId ?? "");
  const { isLoadingUserDetails, userDetails } = useUserDetails(userId ?? "");
  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Reward Transactions" />

      <section className="flex pt-5 md:pt-5 justify-center">
        {loading || isLoadingUserDetails ? (
          <TableSkeleton />
        ) : (
          <RewardTransactionTable items={transactions} user={userDetails} />
        )}
      </section>
    </main>
  );
};

export default RewardTransactions;
