import React from "react";
import { getMonth, getYear } from "date-fns";
import DatePicker from "react-datepicker";
import range from "lodash/range";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { CustomSelect } from "..";

export interface CalendarProps {
  label?: string;
  selectedDate?: Date;
  setSelectedDate?: (date: Date) => void;
  showTime?: boolean;
  [key: string]: unknown;
}

export const months = [
  { key: "Jan", value: "January" },
  { key: "Feb", value: "February" },
  { key: "Mar", value: "March" },
  { key: "Apr", value: "April" },
  { key: "May", value: "May" },
  { key: "Jun", value: "June" },
  { key: "Jul", value: "July" },
  { key: "Aug", value: "August" },
  { key: "Sep", value: "September" },
  { key: "Oct", value: "October" },
  { key: "Nov", value: "November" },
  { key: "Dec", value: "December" },
];

const Datepicker = ({
  label,
  selectedDate,
  setSelectedDate,
  showTime = false,
  ...props
}: CalendarProps) => {
  const years = range(1970, getYear(new Date()) + 2, 1);

  return (
    <div style={{ width: "auto" }}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <DatePicker
        {...props}
        dateFormat={showTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
        selected={selectedDate}
        autoComplete="off"
        showTimeSelect={showTime}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        onChange={(date) => setSelectedDate?.(date as Date)}
        className={`block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        renderCustomHeader={({ date, changeYear, changeMonth }) => {
          return (
            <div className="flex items-center justify-center gap-3 b-1">
              <CustomSelect
                name="startDate"
                className="bg-gray-200 rounded-md border-none w-24 text-sm font-sans"
                value={months[getMonth(date)].value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  changeMonth(
                    months.findIndex((month) => month.value === e.target.value)
                  )
                }
                options={months}
              />
              <CustomSelect
                className="bg-gray-200 rounded-md border-none w-24 text-sm font-sans"
                value={getYear(date)}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  changeYear(Number(e.target.value))
                }
                options={years.map((year) => ({
                  key: year.toString(),
                  value: year.toString(),
                }))}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default Datepicker;
