import { isEmpty, isFinite, isNumber, pickBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { Button } from "../../components";
import FeeEntry, { FeeEntryRef } from "./FeeEntry";
import { TransactionFee } from "./type";
import { baseFeeSchema } from "./validation";
import { ValidationError } from "yup";
import Visibility from "../../components/Visibility";
import { toast } from "react-toastify";
import { isolateError } from "../../utils/isolateError";
import { logger } from "../../utils/logger";
import FeeOverrideSection from "./FeeOverrides";
import { AfriexActions } from "../../types";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../types";

const Fees = () => {
  const [fees, setFees] = useState<{
    baseFee?: TransactionFee;
    overrides: TransactionFee[];
  }>({ overrides: [] }); // fees from API

  const baseEntryRef = useRef<FeeEntryRef>(null);
  const [submitting, setSubmitting] = useState(false);
  const updateFees = async () => {
    const baseFee = validateBaseFee(baseEntryRef.current?.getFee() ?? {});
    if (!baseFee) {
      return;
    }
    setSubmitting(true);
    const [_, error] = await isolateError(
      axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v2/admin/transactions/fees",
        {
          // ensure id is preserved, diffing will be done on the BE
          baseFee: { ...(fees.baseFee ?? {}), ...baseFee },
          overrideFees: fees.overrides,
        }
      )
    );
    setSubmitting(false);
    if (error) {
      toast.error("Error updating fees");
      return;
    }
    toast.success("Fees updated");
  };

  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );

  const validateBaseFee = (values: Partial<TransactionFee>) => {
    const { baseValue, percentValue, maxFeeValue, type } = values;
    const baseFee: Pick<
      TransactionFee,
      "baseValue" | "percentValue" | "maxFeeValue" | "type"
    > = {
      baseValue: isNumber(baseValue) ? Number(baseValue) : undefined,
      percentValue: isNumber(percentValue) ? Number(percentValue) : undefined,
      maxFeeValue: isNumber(maxFeeValue) ? Number(maxFeeValue) : undefined,
      type: type ?? "default",
    };

    try {
      setValidationError(undefined);
      const cleanBaseFee = pickBy(
        baseFee,
        (value) => isFinite(value) || !isEmpty(value)
      );
      return baseFeeSchema.validateSync(cleanBaseFee) as TransactionFee;
    } catch (e) {
      if (e instanceof ValidationError) {
        setValidationError(e.message as string);
        return;
      }
      setValidationError("Unknown error");
    }
    return undefined;
  };

  const [loading, setLoading] = useState(false);

  const fetchFees = async () => {
    setLoading(true);
    const [result, error] = await isolateError(
      axios.get<{
        baseFee: TransactionFee;
        overrideFees: TransactionFee[];
      }>(process.env.REACT_APP_API_BASE_URL + "/v2/admin/transactions/fees")
    );
    setLoading(false);
    if (error) {
      toast.error("Error fetching fees");
      return;
    }

    return result?.data;
  };

  useEffect(() => {
    fetchFees()
      .then((result) => {
        const { baseFee, overrideFees = [] } = result ?? {};
        setFees({
          baseFee,
          overrides: overrideFees,
        });
      })
      .catch((error) => {
        logger.log(error);
      });
  }, []);

  const baseFee = fees?.baseFee ?? ({ type: "default" } as TransactionFee);

  return (
    <div className="container p-6 mt-9">
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl">Fee Management</h1>
        <br />
        <PermissionsProvider
          permission={AfriexPermissions.FEES_PAGE}
          action={AfriexActions.CLICK_FEES_UPDATE}
        >
          <Button
            className="bg-blue-500 text-white"
            onClick={updateFees}
            isLoading={submitting}
          >
            Update Fees
          </Button>
        </PermissionsProvider>
      </div>
      <br />
      <div>
        <h1 className="text-2xl">Default Fee</h1>
        <p className="text-gray-500 text-sm">
          Fixed fees are applied on the source currency.
        </p>
        <br />
        <div className="section w-1/2">
          <FeeEntry
            key={`base-fee${baseFee.id}`}
            editStyle="inline"
            innerRef={baseEntryRef}
            type="default"
            layout="row"
            fee={baseFee}
          />
        </div>
        <div>
          {validationError && (
            <div className="text-red-500 text-sm">{validationError}</div>
          )}
        </div>
      </div>
      <Visibility visible={!loading || fees.overrides.length > 0}>
        <div className="mt-9">
          <FeeOverrideSection
            overrides={fees.overrides}
            onDelete={(_, index) => {
              setFees((prev) => ({
                ...prev,
                overrides: prev.overrides.filter((_, i) => i !== index),
              }));
            }}
            onSave={(override) => {
              setFees((prev) => ({
                ...prev,
                // check if override already exists and update index otherwise add
                overrides: isEmpty(override.id)
                  ? [override].concat(prev.overrides)
                  : prev.overrides.map((cur) => {
                      if (cur.id === override.id) {
                        // preserve no editable fields
                        return { ...cur, ...override };
                      }
                      return cur;
                    }),
              }));
            }}
          />
        </div>
      </Visibility>
      <Visibility visible={loading && !fees.overrides.length}>
        <div className="mt-9 text-center justify-center">
          <h3 className="text-xl">Loading...</h3>
        </div>
      </Visibility>
    </div>
  );
};

export default Fees;
