import { ReactElement } from "react";
import { UserDetailsReturnProps } from "../../screens/User/Details/types";
import NoteTable from "./NoteTable";

type UserSecurityNotesProps = Pick<
  UserDetailsReturnProps,
  | "isLoadingSecurityNotes"
  | "securityNotes"
  | "hasMoreNotes"
  | "loadMoreSecurityNotes"
  | "isFetchingMore"
>;
const UserSecurityNotes = ({
  securityNotes,
  isLoadingSecurityNotes,
  hasMoreNotes,
  loadMoreSecurityNotes,
  isFetchingMore,
}: UserSecurityNotesProps): ReactElement => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-sm text-slate-500 w-full">
        {isLoadingSecurityNotes ? (
          <p className="text-sm">Loading...</p>
        ) : !securityNotes.length ? (
          <div className="text-sm text-gray-500">No security notes</div>
        ) : (
          <NoteTable
            securityNotes={securityNotes}
            hasMoreNotes={hasMoreNotes}
            loadMoreSecurityNotes={loadMoreSecurityNotes}
            isFetchingMore={isFetchingMore}
          />
        )}
      </div>
    </div>
  );
};

export default UserSecurityNotes;
