import { ReactElement } from "react";
import { Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";

import useOTCVolumeList from "./useOTCVolumeList";
import { PaginationProps } from "../../../components/common/Pagination";
import OTCVolumeFilters from "./OTCVolumeFilters";
import OTCVolumeTable from "./OTCVolumeTable";

const OTCVolumes = (): ReactElement => {
  const {
    isLoading,
    isSearch,
    isPageReset,
    pageInfo,
    otcList,
    fetchOTCList,
    filterCount,
    currentFilters,
    filtersToApply,
    clearFilters,
    selectAppliedFilters,
    selectCurrentFilters,
    handleApplyFilters,
    handleApprove,
  } = useOTCVolumeList();

  return (
    <main className="my-5 mx-4 md:mx-10">
      <OTCVolumeFilters
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <OTCVolumeTable items={otcList} handleApprove={handleApprove} />
        )}
      </section>

      {otcList && otcList.length ? (
        <Pagination
          onPageChange={fetchOTCList as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default OTCVolumes;
