import { useState } from "react";
import { FilterProps } from "./types";

const useFilter = ({ onSubmit }: FilterProps) => {
  const [filters, setFilters] = useState({} as any);

  const handleChange = (value: any, attribute: string) => {
    //  const value = event.target.value;
    setFilters({
      ...filters,
      [attribute]: value,
    });
  };

  const handleSubmit = () => {
    onSubmit(filters);
  };
  const resetFilters = () => {
    setFilters({});
  };
  return { filters, resetFilters, handleChange, handleSubmit };
};

export default useFilter;
