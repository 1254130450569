import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AfriexRoles } from "../types";

type AfriexAdminUser = {
  firstName: string | null;
  email: string | null;
};

type AuthContextType = {
  isAuthenticated: boolean;
  login: (irole: AfriexRoles, admin: AfriexAdminUser) => void;
  logout: VoidFunction;
  role?: AfriexRoles;
  adminUser?: AfriexAdminUser;
  authLoading?: boolean;
};
const defaultValue = {} as AuthContextType;

const AuthContext = createContext(defaultValue);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState<AfriexRoles>();
  const [adminUser, setAdminUser] = useState<AfriexAdminUser | undefined>();
  const [authLoading, setAuthLoading] = useState<boolean>(true);

  const login = useCallback((irole: AfriexRoles, admin: AfriexAdminUser) => {
    setIsAuthenticated(true);
    setRole(irole);
    setAdminUser(admin);
  }, []);

  const logout = useCallback(async () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setRole(undefined); // Clear role on logout
    setAdminUser(undefined); // Clear adminUser on logout
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("afriex-token");
    const role = localStorage.getItem("admin-role");
    const email = localStorage.getItem("admin-email");
    const firstName = localStorage.getItem("admin-firstname");

    if (token && token?.length > 5) {
      login(role as AfriexRoles, { email, firstName });
    }
  }, [login]);

  const memoedValue = useMemo(
    () => ({
      isAuthenticated,
      login,
      role,
      logout,
      adminUser,
      authLoading,
    }),
    [isAuthenticated, login, role, logout, adminUser, authLoading]
  );

  useEffect(() => {
    const token = localStorage.getItem("afriex-token");
    if (token && token?.length > 5) {
      setIsAuthenticated(true);
    }
    setAuthLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
