export const calculatePercentageArbitrage = (
  forwardRate: number,
  backwardRate: number,
  percentageThreshold: number
) => {
  const product = forwardRate * backwardRate;
  if (product <= 1) return 0;
  const percentageGain = (product - 1) * 100;
  return percentageGain >= percentageThreshold ? Math.trunc(percentageGain) : 0;
};
