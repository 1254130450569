export const isolateError = async <T>(
  asyncFunction: Promise<T>
): Promise<[(T | undefined)?, (any | undefined)?]> => {
  try {
    const done = await asyncFunction;
    return [done as T, undefined];
  } catch (err: any) {
    return [undefined, err];
  }
};
