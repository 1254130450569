/**
 * Afriex Reward: information on the various redeemable items on offer
 * =====================
 * - Tier: The tier of the reward
 * - Points: The number of reward points
 * - Description: The description of the reward
 * - Name: The name of the reward
 * - Image Link: The link to the image of the reward
 * - Supported Countries: The supported countries of the reward. An empty array means all countries are supported
 * - Provider: The provider of the reward
 * - Quantity: The quantity in stock of the reward item: for finite, exhaustible, tangible rewards
 * - Is Deactivated: Whether the reward is active or not
 */

import { AfriexAdminUser } from "./AfriexAdmin";
import { SupportedCountryCodes } from "./SupportedCountryCodes";

export enum AfriexRewardTier {
  Member = "member",
  Blue = "blue",
  Gold = "gold",
  Platinum = "platinum",
}

export enum AfriexRewardType {
  "Gift Card" = "gift_card",
  Coupon = "coupon",
  "Health Insurance" = "health_insurance",
  "Airport Lounge" = "airport_lounge",
  "Concert Ticket" = "concert_ticket",
}

export enum AfriexRewardProvider {
  Uber = "uber",
  SouthWest = "southwest",
  Amazon = "amazon",
  AirBnB = "airbnb",
  Curacel = "curacel",
  Inclusivity = "inclusivity",
}

export interface AfriexReward {
  id?: string | any;
  type?: AfriexRewardType;
  tier: AfriexRewardTier;
  ttl?: string;
  pointsValue: number;
  dollarValue: number | string;
  description: string;
  name: string;
  imageLink?: string;
  supportedCountries?: SupportedCountryCodes[];
  provider: AfriexRewardProvider;
  updatedBy?: AfriexAdminUser;
  quantity?: number;
  isDeactivated?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AfriexRewardCard {
  id?: string | any;
  rewardId?: string;
  code?: string;
  hash: string;
  isDeactivated?: boolean;
  redeemedBy: {
    userId: string;
    name: string;
    rewardTier: AfriexRewardTier;
    redeemedAt: Date;
  };
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
