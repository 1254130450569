import { Link } from "react-router-dom";
import { Button } from "../../../components";
import SpiningLoader from "../../../components/common/SpinningLoader";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexReferralTier,
} from "../../../types";
import * as formatDate from "../../../utils/dateFormatter";
import { formatName } from "../../../utils/formatName";
import TierUpdateButton from "./TierUpdateButton";
import React from "react";
import { formatMonetaryValue } from "../../../helpers/dashboard";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

type TierSummaryProp = {
  item: AfriexReferralTier;
  isLoading?: boolean;
  isDetail?: boolean;
};

const TierSummary = ({ item, isDetail, isLoading }: TierSummaryProp) => {
  const shouldSkipAllPayout =
    item.shouldSkipReferrerPayout && item.shouldSkipReferreePayout;
  const status = item?.isDeactivated ? "Inactive" : "Active";
  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{item.name}</h2>
        {!isDetail ? (
          <Link
            to={`/referral/tiers/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="ghost">
              {item.referralCode ? "View Tier Details" : "Manage Tier Users"}
            </Button>
          </Link>
        ) : null}
      </div>

      <div>
        {!shouldSkipAllPayout && (
          <React.Fragment>
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">
                Cumulative Transaction Threshold
              </p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {formatMonetaryValue(item.cumulativeThreshold)} USD
              </p>
            </div>

            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">
                Bonus Payout Amount Per Referrer
              </p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {formatMonetaryValue(item.bonusAmountPerReferrer)} USD
              </p>
            </div>

            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">
                Bonus Payout Amount Per Referred User
              </p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {formatMonetaryValue(item.bonusAmountPerReferree)} USD
              </p>
            </div>

            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Transaction Window</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {formatMonetaryValue(item.transactionWindow)} USD
              </p>
            </div>

            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Should Automate Payout</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {item?.shouldAutomatePayout ? "Yes" : "No"}
              </p>
            </div>

            {item?.shouldSkipReferrerPayout && (
              <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
                <p className="text-slate-500 text-sm">
                  Should Skip Referrer Payout
                </p>
                <p className="text-sm font-medium text-gray-700 w-1/2">
                  {item?.shouldSkipReferrerPayout ? "Yes" : "No"}
                </p>
              </div>
            )}

            {item?.shouldSkipReferreePayout && (
              <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
                <p className="text-slate-500 text-sm">
                  Should Skip Referee Payout
                </p>
                <p className="text-sm font-medium text-gray-700 w-1/2">
                  {item?.shouldSkipReferreePayout ? "Yes" : "No"}
                </p>
              </div>
            )}
          </React.Fragment>
        )}

        {item?.referralCode && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Referral Code</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {item.referralCode}
            </p>
          </div>
        )}

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">{status}</p>
        </div>

        {item.admin && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Approved By</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {formatName(item.admin)}
            </p>
          </div>
        )}

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date Created</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(item.createdAt?.toString() ?? "")}
          </p>
        </div>
      </div>

      <PermissionsProvider
        permission={AfriexPermissions.REFERRAL_PAGE}
        action={AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER}
      >
        <div className="flex items-center justify-end gap-6 mt-4">
          <TierUpdateButton initialData={item} />
        </div>
      </PermissionsProvider>
    </div>
  );
};

export default TierSummary;
