import { PairwiseDiscrepancyResult } from "../../../types";

export const ArbitragePairwiseReporter = ({
  remarks,
}: PairwiseDiscrepancyResult) => {
  if (!remarks?.length) return null;
  // Parse remarks into structured data
  const parsedRemarks = remarks
    .map((remark) => {
      // Normalize the remark to remove extra spaces and newlines
      const normalizedRemark = remark.replace(/\s+/g, " ").trim();

      const regex =
        /(\w+\/\w+)=(\d+(\.\d+)?).*?1\/(\w+\/\w+)=(\d+(\.\d+)?).*?Gain=(\d+)%/;
      const match = normalizedRemark.match(regex);

      if (match) {
        return {
          pair1: match[1], // First currency pair
          rate1: parseFloat(match[2]), // Rate for first pair
          pair2: match[4], // Second currency pair
          rate2: parseFloat(match[5]), // Rate for second pair
          gain: parseInt(match[7], 10), // Gain percentage
        };
      }
      return null;
    })
    .filter(Boolean); // Filter out any null results in case parsing fails

  if (!parsedRemarks.length) return null;

  return (
    <div className="mt-6">
      <h2 className="font-bold mb-2 text-gray-700">Pairwise Discrepancy</h2>
      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead className="bg-red-50">
          <tr>
            <th className="border px-4 py-2">Currency Pair 1</th>
            <th className="border px-4 py-2">Rate 1</th>
            <th className="border px-4 py-2">Currency Pair 2</th>
            <th className="border px-4 py-2">Rate 2</th>
            <th className="border px-4 py-2">Gain (%)</th>
          </tr>
        </thead>
        <tbody>
          {parsedRemarks.map((data, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{data?.pair1}</td>
              <td className="border px-4 py-2">{data?.rate1.toFixed(4)}</td>
              <td className="border px-4 py-2">{data?.pair2}</td>
              <td className="border px-4 py-2">{data?.rate2.toFixed(4)}</td>
              <td className="border px-4 py-2 text-center">{data?.gain}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
