import { Link } from "../../../components";
import { AfriexPerson } from "../../../types";
import UserProfile from "../Details/UserProfile";

type UserSummaryProp = {
  user: AfriexPerson;
  extraRow?: React.ReactNode;
};

const UserSummary = ({ user, extraRow }: UserSummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{user.name?.fullName}</h2>
        <Link
          to={`/users/${user.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-cyan-700 mr-2 hover:underline"
        >
          View User Profile
        </Link>
      </div>

      <UserProfile
        user={user}
        showUserID
        showPhone
        showPhoneVerified
        showReferredBy
        showSecurityEnabled
        showKYCStatus
        showUXCamSessions
        showLastLogin
        showViewTransactions
        showStripeUser
        showRewardPoints
        showInteracEmails
      />

      {extraRow ?? ""}
    </div>
  );
};

export default UserSummary;
