export function removeUndefinedKeys(obj: any) {
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      // Recursively call removeUndefinedKeys for nested objects
      removeUndefinedKeys(obj[key]);
    }
    if (key === "undefined") {
      delete obj[key]; // Remove key if it is 'undefined'
    }
  }
  return obj;
}
