import axios from "../api/axios";
import {
  AfriexPaginatedResponse,
  AfriexPerson,
  AfriexReferral,
  AfriexReferralTier,
} from "../types";
import { replaceUnderScoreIds } from "../utils/replaceUnderScoreIds";
import { AfriexQueryParams } from "../types/AfriexQueryParams";

export const referralService = {
  getReferralList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexReferral[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/referrals?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },

  fulfillReferral: async (referralId: string) => {
    const response = await axios.post(`/v2/admin/referrals/pay/${referralId}`);
    return response.data;
  },

  blockPayout: async (referralId: string) => {
    const response = await axios.post(
      `/v2/admin/referrals/block/${referralId}`
    );
    return response.data;
  },
  searchAutoComplete: async (searchTerm: string): Promise<AfriexReferral[]> => {
    const params = {
      searchTerm: searchTerm?.trim()?.toString(),
    } as any;

    const url = `/v2/admin/referrals/autocomplete?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexReferral>(response.data ?? []);
    return data;
  },
  getTierList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexReferralTier[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/referral-tiers?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getSignupCodeList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexReferralTier[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
      type: "signup",
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/referral-tiers?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getTierUserList: async (
    tierId: string,
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<Partial<AfriexPerson>[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/referral-tiers/users/${tierId}?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getTier: async (tierId: string): Promise<AfriexReferralTier> => {
    const url = `/v2/admin/referral-tiers/${tierId}`;
    const response = await axios.get(url);
    return response?.data;
  },
  updateTier: async (
    tierId: string,
    tier: Partial<AfriexReferralTier>
  ): Promise<AfriexReferralTier> => {
    const url = `/v2/admin/referral-tiers/${tierId}`;
    const response = await axios.patch(url, tier);
    return response?.data;
  },
  createTier: async (
    tier: Partial<AfriexReferralTier>
  ): Promise<AfriexReferralTier> => {
    const url = `/v2/admin/referral-tiers`;
    const response = await axios.post(url, tier);
    return response?.data;
  },
  addUserToTier: async (
    tierId: string,
    userId: string
  ): Promise<AfriexReferralTier> => {
    const url = `/v2/admin/referral-tiers/users/${userId}/${tierId}`;
    const response = await axios.post(url);
    return response?.data;
  },

  addUsersToTier: async (
    tierId: string,
    userIds: string[]
  ): Promise<AfriexReferralTier> => {
    const url = `/v2/admin/referral-tiers/users/${tierId}`;
    const response = await axios.post(url, { userIds });
    return response?.data;
  },
  removeUserFromTier: async (tierId: string, userId: string): Promise<any> => {
    const url = `/v2/admin/referral-tiers/users/${userId}/${tierId}`;
    const response = await axios.delete(url);
    return response?.data;
  },
};
