import { useParams } from "react-router-dom";
import { Header } from "../../../components";
import UserOverview from "./UserOverview";
import useUserDetails from "./useUserDetails";
import TierInformation from "./TierInformationProfile";
import { isEmpty } from "lodash";
import TransactionsView from "./TransactionsView";
import SecurityView from "../../../components/SecurityNotes";
import React from "react";
import DeactivateAccount from "../../../components/DeactivateAccount";

const UserDetails: React.FC = () => {
  const params = useParams();
  const userId = params?.userId ?? "";
  const {
    isAddingSecurityNote,
    isLoadingWalletBalances,
    isLoadingSecurityNotes,
    isLoadingUserDetails,
    securityNotes,
    hasMoreNotes,
    isFetchingMore,
    loadMoreSecurityNotes,
    addSecurityNote,
    showModal,
    toggleModal,
    user,
    walletBalances,
    toggleComplianceModal,
    showComplianceModal,
  } = useUserDetails(userId);
  const { tierInfo } = user;
  if (isLoadingUserDetails || isEmpty(user)) {
    return <div>Loading...</div>;
  }

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10 mb-14">
      <Header title="User Details" />

      {/* User Profile */}
      <UserOverview
        isLoadingUserDetails={isLoadingUserDetails}
        showModal={showModal}
        toggleModal={toggleModal}
        user={user}
        toggleComplianceModal={toggleComplianceModal}
        showComplianceModal={showComplianceModal}
      />

      {/* Security */}
      <SecurityView
        addSecurityNote={addSecurityNote}
        isAddingSecurityNote={isAddingSecurityNote}
        securityNotes={securityNotes}
        isLoadingSecurityNotes={isLoadingSecurityNotes}
        user={user}
        hasMoreNotes={hasMoreNotes}
        loadMoreSecurityNotes={loadMoreSecurityNotes}
        isFetchingMore={isFetchingMore}
      />

      {/* Transactions */}
      <TransactionsView
        user={user}
        walletBalances={walletBalances}
        isLoadingWalletBalances={isLoadingWalletBalances}
      />

      {/* Promo Tiers */}
      <TierInformation
        id={tierInfo?.id}
        transactionCount={tierInfo?.transactionCount}
        userId={user?.id}
      />

      {/* Deactivate User */}
      <DeactivateAccount user={user} />
    </main>
  );
};

export default UserDetails;
