import { useEffect, useState } from "react";
import { Tabs } from "../../../components";
import { TabItem } from "../../../types";
import { Metrics } from "./types";
import MetricsDashboard from "./MetricDashboard";
import ProcessorMetricsGrouped from "./MetricsGrouped";
import RawMetrics from "./RawMetrics";
import { processorService } from "../../../services/processorService";

const MetricPage = () => {
  const [data, setData] = useState([]);
  const [metrics, setMetrics] = useState<Metrics | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await processorService.getProcessorMetrics();
      setData(response.data);
      setMetrics(response.metrics);
    };

    fetchData();
  }, []);

  const tabs: TabItem[] = [
    {
      id: "metrics-overview",
      title: "Overview",
      content: <MetricsDashboard data={data} metrics={metrics} />,
    },
    {
      id: "metric-groups",
      title: "Metric Groups",
      content: <ProcessorMetricsGrouped data={metrics} />,
    },
    {
      id: "metric-raw",
      title: "Raw Data",
      content: <RawMetrics data={data} />,
    },
  ];

  if (!data || !metrics) {
    return (
      <div className="h-56 flex items-center justify-center">Loading...</div>
    );
  }

  return <Tabs tabs={tabs} queryKey={"tab"} />;
};

export default MetricPage;
