import { ReactElement, useEffect, useState } from "react";
import { AfriexAmount, SupportedCurrencies } from "../../types";

interface CurrencyPickersProps {
  onChange: (value: AfriexAmount) => void;
  value?: AfriexAmount;
  label?: string;
  fixedCurrency?: SupportedCurrencies;
}

const mapOptionsToList = (options: SupportedCurrencies[]) =>
  options.map((currency: SupportedCurrencies) => ({
    value: currency,
    label: currency,
  }));

const options = mapOptionsToList(Object.values(SupportedCurrencies));

const AmountInput = ({
  label,
  onChange,
  value,
  fixedCurrency,
}: CurrencyPickersProps): ReactElement => {
  const [currency, setCurrency] = useState<SupportedCurrencies>(
    SupportedCurrencies.NGN
  );

  useEffect(() => {
    if (fixedCurrency) {
      setCurrency(fixedCurrency);
    }
  }, [fixedCurrency]);

  useEffect(() => {
    if (value?.currency) {
      setCurrency(value.currency);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAmount = event.target.value;
    if (!selectedAmount || !currency) return;
    onChange({
      amount: Number(selectedAmount),
      currency: currency,
    } as AfriexAmount);
  };
  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCurrency = event.target.value as SupportedCurrencies;
    if (!selectedCurrency) return;
    setCurrency(selectedCurrency);
    onChange({
      currency: selectedCurrency,
    } as AfriexAmount);
  };
  return (
    <div className="mb-4 mr-2">
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          name="price"
          id="price"
          defaultValue={value?.amount}
          onChange={handleChange}
          className="block w-full rounded-md border-0 p-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="0.00"
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            id="currency"
            name="currency"
            onChange={handleCurrencyChange}
            value={currency}
            disabled={!!fixedCurrency}
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AmountInput;
