import countries from "../../../constants/countries";
import { AfriexPerson } from "../../../types";
import KYCImage from "./KYCImage";

type KYCSummaryProps = { user: AfriexPerson; extraRow?: React.ReactNode };
const Summary = ({ user, extraRow }: KYCSummaryProps) => {
  const country = user?.currentCountry?.toUpperCase() ?? "-";
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Summary".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex bg-white rounded-lg w-full px-5 py-5 gap-5 border border-gray-200">
        <div className="w-1/4">
          <KYCImage src={user?.kyc?.images?.selfie} alt="User selfie" />
        </div>
        <div className="bg-white rounded-lg w-full">
          <div className="flex items-center gap-4 mb-3">
            <h2 className="font-semibold text-2xl mr-3">
              {user.name?.fullName}
            </h2>
          </div>

          <div>
            {/* Country */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Country</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {`${country} ${countries[country] ?? ""}`}
              </p>
            </div>

            {/* Date of Birth */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Date of Birth</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.dateOfBirth ?? "-"}
              </p>
            </div>

            {/* Verification Status */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Verification</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.status?.toUpperCase()}
              </p>
            </div>

            {/* UserID */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">User ID</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user.id}
              </p>
            </div>
          </div>

          {extraRow ?? ""}
        </div>
      </div>
    </div>
  );
};

export default Summary;
