import { useState } from "react";
import { formatMonetaryValue } from "../../../helpers/dashboard";
import { SupportedCurrencies } from "../../../types";

type RatesTableProp = {
  rates: any;
  onRatesEdited?: (
    fromCurrency: SupportedCurrencies,
    toCurrency: SupportedCurrencies,
    newRate: number
  ) => void;
  isEditable?: boolean;
};
export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const RatesTable = ({ rates, onRatesEdited }: RatesTableProp) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
          {Object.keys(rates)?.map((currencySymbol) => (
            <th
              key={currencySymbol}
              scope="col"
              className="py-4 px-2 text-xs text-left font-medium text-gray-600 uppercase tracking-wider"
            >
              {currencySymbol?.trim()}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {Object.keys(rates).map((baseCurrency) => (
          <TRow
            rates={rates}
            key={baseCurrency}
            onRatesEdited={onRatesEdited}
            baseCurrency={baseCurrency}
          />
        ))}
      </tbody>
    </table>
  );
};

const TRow = ({
  rates,
  baseCurrency,
  onRatesEdited,
}: {
  rates: any;
  baseCurrency: string;
  onRatesEdited: RatesTableProp["onRatesEdited"];
}) => {
  const textClassName = "text-sm text-gray-800";
  return (
    <>
      <tr className="zebra hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td
          className={`sticky left-0 py-4 px-2 ${textClassName} text-gray-800 font-medium bg-white`}
        >
          {baseCurrency}
        </td>
        <>
          {Object.keys(rates).map((quoteCurrency: string, index: number) => (
            <TableData
              key={index}
              rate={
                rates?.[baseCurrency] ? rates?.[baseCurrency][quoteCurrency] : 0
              }
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              isEditable={true}
              onRatesEdited={onRatesEdited}
            />
          ))}
        </>
      </tr>
    </>
  );
};

const TableData = ({
  rate,
  baseCurrency,
  quoteCurrency,
}: {
  rate: number;
  baseCurrency: string;
  quoteCurrency: string;
  onRatesEdited: RatesTableProp["onRatesEdited"];
  isEditable: RatesTableProp["isEditable"];
}) => {
  const textClassName = "text-sm text-gray-800";
  const [isControlsVisible, setIsControlsVisible] = useState<boolean>(false);
  const title = rate > 0 ? `1 ${baseCurrency} = ${rate} ${quoteCurrency}` : "";

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    //todo
    const detail = e.detail || 0;
    if (detail > 0) {
      setIsControlsVisible(true);
    }
  };

  return (
    <td
      className={`px-2 py-4 whitespace-nowrap text-left ${textClassName} ${isControlsVisible && "border border-blue-500 "}`}
    >
      <div className="flex justify-between" onClick={handleClick} title={title}>
        {formatMonetaryValue(Number(rate), false, 6)}
      </div>
    </td>
  );
};

export default RatesTable;
