import React, { useState } from "react";
import { AfriexReferralTier } from "../../../types";
import { statusColors } from "../../../types/Transaction";
import { Badge, Button, Link } from "../../../components";
import { formatMonetaryValue } from "../../../helpers/dashboard";
import {
  ChevronRightIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import * as formatDate from "../../../utils/dateFormatter";
import SignupBonusSummary from "./SignupBonusSummary";

const SignupBonusTiersTable = ({ items }: { items: AfriexReferralTier[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Name
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Amount
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Status
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <SignupBonusTiersTableRow item={item} key={index} />;
        })}
      </tbody>
    </table>
  );
};

const SignupBonusTiersTableRow = ({ item }: { item: AfriexReferralTier }) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const status = item?.isDeactivated ? "Inactive" : "Active";
  const statusColor =
    statusColors[item?.isDeactivated ? "processing" : "successful"];
  return (
    <React.Fragment>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td className="pr-6 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item.name}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {formatMonetaryValue(item.bonusAmountPerReferree)} {item.currencyCode}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Badge label={status} colorScheme={statusColor} />
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(item.createdAt?.toString() ?? "")}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <div className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500">
            <ChevronRightIcon className=" w-5 h-5" />
          </div>
        </td>
      </tr>

      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <SignupBonusSummary item={item} />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default SignupBonusTiersTable;
