export enum AfriexBlockReason {
  SANCTIONS_MATCH = "SANCTIONS_MATCH",
  IDENTITY_THEFT = "IDENTITY_THEFT",
  SOCIAL_ENGINEERING = "SOCIAL_ENGINEERING",
  MULTIPLE_DISPUTES = "MULTIPLE_DISPUTES",
  OTHERS = "OTHERS",
  POSITIVE_DOW_JONES_MATCH = "POSITIVE_DOW_JONES_MATCH",
  CONFIRMED_FRAUDULENT_ACTIVITY = "CONFIRMED_FRAUDULENT_ACTIVITY",
  FRAUDULENT_ACTIVITY_INVESTIGATION_ONGOING = "FRAUDULENT_ACTIVITY_INVESTIGATION_ONGOING",
  ACCOUNT_TAKEOVER_CASE = "ACCOUNT_TAKEOVER_CASE",
  UNAUTHORIZED_TRANSACTIONS_INVESTIGATION_COMPLETED = "UNAUTHORIZED_TRANSACTIONS_INVESTIGATION_COMPLETED",
  IDENTITY_THEFT_CASE = "IDENTITY_THEFT_CASE",
  ACH_FRAUD = "ACH_FRAUD",
}

export enum AfriexUnblockReason {
  KYC_IN_PLACE = "KYC_IN_PLACE",
  EDD_PROVIDED = "EDD_PROVIDED",
  WATCHLIST_MATCH_CLEARED = "WATCHLIST_MATCH_CLEARED",
  DISPUTE_RESOVED = "DISPUTE_RESOLVED",
  DISPUTE_FUNDS_RECOVERED_FROM_WALLET = "DISPUTE_FUNDS_RECOVERED_FROM_WALLET",
  OTHERS = "OTHERS",
  CARDS_REVIWED_ON_STRIPE = "CARDS_REVIEWED_ON_STRIPE",
  DATE_OF_BIRTH_UPDATED = "DATE_OF_BIRTH_UPDATED",
  LEGAL_NAMES_UPDATED = "LEGAL_NAMES_UPDATED",
}
