import axios from "../api/axios";
import {
  AfriexReward,
  AfriexPaginatedResponse,
  SupportedCurrencies,
  TransactionTypes,
  AfriexRewardCard,
} from "../types";
import { AfriexQueryParams } from "../types/AfriexQueryParams";
import { TransactionProcessors } from "../types/ProcessorTypes";

const commonEndpoint = "/v2/admin/rewards";
const rewardCardEndpoint = `${commonEndpoint}/cards`;

export type RewardResponse = AfriexPaginatedResponse<AfriexReward[]>;
export type RewardCardResponse = AfriexPaginatedResponse<AfriexRewardCard[]>;
export type RewardFilters = AfriexQueryParams;

export type UpdateProcessorRequest = {
  reward: SupportedCurrencies;
  type: TransactionTypes;
  paymentMethodName: TransactionProcessors;
};

export const rewardService = {
  getList: async (filters: RewardFilters): Promise<RewardResponse> => {
    const paginationParams = {
      page: 0,
      limit: 20,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `${commonEndpoint}?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  getCardList: async (
    filters: RewardFilters,
    rewardId: string
  ): Promise<RewardCardResponse | any> => {
    if (!rewardId) return;
    const paginationParams = {
      page: 0,
      limit: 20,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `${rewardCardEndpoint}/${rewardId}?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  getItem: async (rewardId: string): Promise<AfriexReward> => {
    const response = await axios.get(commonEndpoint + `/${rewardId}`);
    return response.data;
  },
  createItem: async (body: Partial<AfriexReward>): Promise<AfriexReward> => {
    const response = await axios.post(commonEndpoint, body);
    return response.data;
  },
  createCardItems: async (
    codes: string[],
    rewardId: string
  ): Promise<AfriexReward> => {
    const response = await axios.post(`${rewardCardEndpoint}/${rewardId}`, {
      codes,
    });
    return response.data;
  },
  saveOne: async (
    itemId: string,
    body: Partial<AfriexReward>
  ): Promise<AfriexReward> => {
    const response = await axios.patch(commonEndpoint + `/${itemId}`, body);
    return response.data;
  },
};
