import { useState } from "react";
import ReferralSettingsForm from "./SettingsForm";
import { UpdateFormProps } from "./types";
import { Button } from "../../../components";
import {
  AfriexActions,
  AfriexPermissions,
  SupportedCurrencies,
} from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const Settings = ({ item, handleSave }: UpdateFormProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return (
    <div className="bg-white rounded-lg w-full px-10 pb-2">
      <div className="text-sm text-slate-500 w-full">
        {item?.code !== SupportedCurrencies.USD && (
          <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
            <div>Referral Payout Rate </div>
            <div className="col-span-2">
              {item?.params?.referralPayoutRate
                ? `${item?.params?.referralPayoutRate} ${item?.code}`
                : "Not Set"}
            </div>
          </div>
        )}
        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Withdrawal Processors </div>
          <div className="col-span-2">
            {item?.params?.processors?.withdraw
              ? `${item?.params?.processors?.withdraw?.join(", ")}`
              : "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Deposit Processors </div>
          <div className="col-span-2">
            {item?.params?.processors?.deposit
              ? `${item?.params?.processors?.deposit?.join(", ")}`
              : "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Withdrawal Fee Basis Points </div>
          <div className="col-span-2">
            {item?.params?.withdrawalFeeBasisPoints
              ? `${item?.params?.withdrawalFeeBasisPoints}`
              : "Not Set"}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Fixed Withdrawal Fee </div>
          <div className="col-span-2">
            {item?.params?.fixedWithdrawalFee
              ? `${item?.params?.fixedWithdrawalFee}`
              : "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Fixed Deposit Fee </div>
          <div className="col-span-2">
            {item?.params?.fixedDepositFee
              ? `${item?.params?.fixedDepositFee}`
              : "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3 border-b-1 pb-2">
          <div>Fee Structure</div>
          <div className="col-span-2">
            {item?.params?.feeStructure
              ? `${item?.params?.feeStructure}`
              : "Not Set"}
          </div>
        </div>

        <PermissionsProvider
          permission={AfriexPermissions.SETTINGS_PAGE}
          action={AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL}
        >
          <div className="grid grid-cols-3 gap-3 my-2">
            <div className="col-span-6">
              <div className="flex justify-between">
                <span className="text-red-500"></span>
                {showFullScreen ? (
                  <ReferralSettingsForm
                    item={item}
                    onClose={toggleFullScreen}
                    handleSave={handleSave}
                  />
                ) : (
                  <Button
                    variant={"solid"}
                    className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
                    onClick={toggleFullScreen}
                  >
                    Update Settings
                  </Button>
                )}
              </div>
            </div>
          </div>
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default Settings;
