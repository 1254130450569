import { useEffect, useState } from "react";
import { userService } from "../../../services/userService";
import { AfriexPerson } from "../../../types";
import { UserUpdateBody } from "../../../types/UserUpdateBody";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { UserUpdateReturnProps } from "./types";

const useUserUpdate = (
  user: AfriexPerson,
  toggleModal: () => void
): UserUpdateReturnProps => {
  // State variables for form fields
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);

  // Flags to indicate if fields have been updated
  const [isNameUpdated, setIsNameUpdated] = useState<boolean>(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState<boolean>(false);
  const [isUsernameUpdated, setIsUsernameUpdated] = useState<boolean>(false);
  const [isPhoneUpdated, setIsPhoneUpdated] = useState<boolean>(false);
  const [isPhoneVerifiedUpdated, setIsPhoneVerifiedUpdated] =
    useState<boolean>(false);

  // Other state variables
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  // Initialize form fields with user data when the user prop changes
  useEffect(() => {
    if (user) {
      setName(user.name?.fullName || "");
      setEmail(user.currentEmail || "");
      setPhone(user.currentPhone || "");
      setUsername(user.name?.userName || "");
      setIsPhoneVerified(user.isPhoneVerified || false);
    }
  }, [user]);

  // Enable the submit button once the user data is loaded
  useEffect(() => {
    if (user) {
      setIsButtonDisabled(false);
    }
  }, [user]);

  // Handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (!name || !email || !phone) {
        throw new Error("Please fill all required fields");
      }

      const payload: Partial<UserUpdateBody> = {};

      if (isNameUpdated) {
        payload["name"] = name;
      }
      if (isEmailUpdated) {
        payload["email"] = email;
      }
      if (isPhoneUpdated) {
        payload["phone"] = phone;
      }
      if (isUsernameUpdated) {
        payload["username"] = username;
      }
      if (isPhoneVerifiedUpdated) {
        payload["isPhoneVerified"] = isPhoneVerified;
      }

      await userService.updateUser(user.id, payload);

      showSuccessMessage("User updated successfully");
      setIsSubmitting(false);
      toggleModal();
      window.location.reload();
    } catch (err: any) {
      const errorMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message;
      showErrorMessage("Failed to update user: " + errorMessage);
      setIsSubmitting(false);
    }
  };

  const handlePhoneVerifiedChange = (value: boolean) => {
    if (Boolean(value) !== Boolean(user.isPhoneVerified)) {
      setIsPhoneVerifiedUpdated(true);
      setIsPhoneVerified(value);
    }
  };

  // Handler for input changes
  const handleUserUpdate = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    switch (name) {
      case "fullName":
        if (value !== user.name?.fullName) {
          setIsNameUpdated(true);
        } else {
          setIsNameUpdated(false);
        }
        setName(value);
        break;
      case "currentEmail":
        if (value !== user.currentEmail) {
          setIsEmailUpdated(true);
        } else {
          setIsEmailUpdated(false);
        }
        setEmail(value);
        break;
      case "userName":
        if (value !== user.name?.userName) {
          setIsUsernameUpdated(true);
        } else {
          setIsUsernameUpdated(false);
        }
        setUsername(value);
        break;
      case "currentPhone":
        if (value !== user.currentPhone) {
          setIsPhoneUpdated(true);
        } else {
          setIsPhoneUpdated(false);
        }
        setPhone(value);
        break;
      default:
        break;
    }
  };

  return {
    user: {
      fullName: name,
      currentEmail: email,
      currentPhone: phone,
      userName: username,
    },
    isButtonDisabled,
    isPhoneVerified,
    isSubmitting,
    setIsPhoneVerified,

    // Handlers
    handlePhoneVerifiedChange,
    handleSubmit,
    handleUserUpdate,
  };
};

export default useUserUpdate;
