import { AfriexReferralTier } from "../../../types";
import { TierAction, TierActionType } from "./types";

const updateTierReducer = (state: AfriexReferralTier, action: TierAction) => {
  switch (action.type) {
    case TierActionType.UPDATE_TIER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default updateTierReducer;
