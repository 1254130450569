import Button from "../common/Button";
import Modal from "../common/Modal";
import { ICurrency } from "../../types/Currency";
import SpinningLoader from "../common/SpinningLoader";
import { AfriexPerson } from "../../types";
import { capitalize } from "lodash";

type LedgerModalProps = {
  loading: boolean;
  action: "credit" | "debit";
  currency: ICurrency;
  amount: number;
  confirmFunction: () => void;
  onClose: () => void;
  user: AfriexPerson;
  reason: string | undefined;
};

export const LedgerModal: React.FC<LedgerModalProps> = ({
  loading,
  currency,
  amount,
  action,
  confirmFunction,
  onClose,
  user,
  reason,
}) => {
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {loading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <h3 className="text-lg ">
              Are you sure you want to {action}{" "}
              <span className="text-indigo-500 text-center">
                {amount} {currency?.code}
              </span>{" "}
              {action === "credit" ? "to " : "from "} {user.name?.firstName}{" "}
              {user.name?.lastName}?
            </h3>
            <p className="text-sm font-medium">
              Reason:{" "}
              <span
                className={`font-regular ${
                  action === "credit"
                    ? "text-successful-500"
                    : "text-failed-500"
                }`}
              >
                {capitalize(reason?.replace(/_/g, " "))}
              </span>
            </p>
            <div className="text-sm font-light mt-3">
              Double check the amount before clicking confirm
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline">
                cancel
              </Button>
              <Button
                onClick={confirmFunction}
                variant="outline"
                className="bg-indigo-200 hover:g"
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
