import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

type SearchBarProps = {
  handleSearch: (searchTerm: string) => void;
  handleAutoComplete?: (searchTerm: string) => void;
};
type SearchBarReturnProps = {
  handleSearchTermChange: (event: any) => void;
  searchTerm: string;
};
const useSearchBar = ({
  handleSearch,
  handleAutoComplete,
}: SearchBarProps): SearchBarReturnProps => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleSearchTermChange = useCallback(
    (event: any) => {
      setSearchTerm(event.target.value);
      const debouncedHandler = debounce(handleAutoComplete!, 500);
      if (handleAutoComplete && event.target.value.length > 2) {
        debouncedHandler(event.target.value);
      }
    },
    [handleAutoComplete]
  );

  useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter" ||
        event.charCode === 13 ||
        event.keyCode === 13
      ) {
        event.preventDefault();
        handleSearch(searchTerm);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchTerm, handleSearch]);

  return {
    handleSearchTermChange,
    searchTerm,
  };
};

export default useSearchBar;
