import { useState } from "react";
import { swap } from "../../api/transactions";
import currencies from "../../constants/currencies";
import useRates from "../../hooks/useRates";
import { ICurrency } from "../../types/Currency";
import { convertCurrency } from "../../utils/currencyConverter";
import { convertRatesListToMap } from "../../utils/rates/convertRatesListToMap";

const useSwap = (
  userId: string,
  initialAmount: NonNullable<unknown>,
  initialSwapResponse: NonNullable<unknown>
) => {
  const [amount, setAmount] = useState<{ [key: string]: number }>(
    initialAmount
  );
  const [currency, setCurrency] = useState<{ [key: string]: ICurrency }>({
    from: currencies[0],
    to: currencies[1],
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [swapResponse, setSwapResponse] = useState(initialSwapResponse);
  const { rateInfo } = useRates();
  const rates = convertRatesListToMap(rateInfo?.appRates ?? []);

  const handleAmountChange = (name: string, value: number) => {
    // onChange of one input, the equivalent should be shown in the other input

    // so if the person types in the to box, the input to convert should be the from
    const inputToConvert = name === "from" ? "to" : "from";

    // to get the converted value, I have to call the convert currency method
    const convertedValue = convertCurrency({
      from: currency[name].code,
      to: currency[inputToConvert].code,
      value,
      rates,
    });
    setAmount({ [name]: value, [inputToConvert]: convertedValue });
  };

  const handleCurrencyChange = (name: string, value: ICurrency) => {
    setCurrency({ ...currency, [name]: value });
    const currencyToConvertTo = value;
    // so if the user changes the currency the equivalent should be shown in the other input field
    const inputToConvertFrom = name === "from" ? "to" : "from";
    // to get the converted value, I have to call the convert currency method
    const convertedValue = convertCurrency({
      from: currency[inputToConvertFrom].code,
      to: currencyToConvertTo.code,
      value: amount[inputToConvertFrom],
      rates,
    });
    setAmount({ ...amount, [name]: convertedValue });
  };

  async function handleSwap() {
    try {
      setLoading(true);
      const response = await swap({
        fromCurrency: currency.from.code,
        toCurrency: currency.to.code,
        amount: amount.from,
        owner: userId,
      });
      if (response !== null || response !== undefined) {
        setLoading(false);
        setShowModal(false);
        // fetchUserAssets();
        setAmount(initialAmount);
      }

      setSwapResponse({
        status: "success",
        message: `Successfully swapped ${amount.from} ${currency.from.code} to ${amount.to} ${currency.to.code}`,
      });
    } catch (err: unknown) {
      setLoading(false);
      setShowModal(false);
      let message = "Unknown Error! Contact Admin";
      if (typeof err === "object" && err !== null) {
        const error = err as {
          response?: { data?: { message?: string } };
          message?: string;
        };
        message = error.response?.data?.message || error.message || message;
      }

      setSwapResponse({ status: "error", message });
    }
  }

  // Check that value is a number and its greater than 0
  // Also check that the from and to currency are not same
  function isSwapButtonDisabled() {
    return (
      currency.from.code === currency.to.code ||
      amount.from <= 0 ||
      isNaN(amount.from) ||
      amount.to <= 0 ||
      isNaN(amount.to)
    );
  }

  return {
    rates,
    amount,
    currency,
    loading,
    showModal,
    swapResponse,
    handleAmountChange,
    handleCurrencyChange,
    handleSwap,
    isSwapButtonDisabled,
    setShowModal,
    setSwapResponse,
  };
};

export default useSwap;
