import { TransactionTypes } from "../../types";

export enum FeePaymentMethod {
  REGULATED_CARD = "REGULATED_CARD",
  UNREGULATED_CARD = "UNREGULATED_CARD",
  ACH_BANK = "ACH_BANK",
  BANK = "BANK",
  WALLET = "WALLET",
  VIRTUAL_BANK_ACCOUNT = "VIRTUAL_BANK_ACCOUNT",
}
export type TransactionFee = {
  id?: string; // is draft when undefined
  paymentMethod?: FeePaymentMethod;
  baseValue?: number;
  percentValue?: number;
  maxFeeValue?: number;
  limitType?: "above" | "below";
  limit?: number;
  currencyPair?: string;
  type: "override" | "default";
  transactionType?: TransactionTypes;
};
