import { useParams } from "react-router-dom";
import { Header, Pagination, TableSkeleton } from "../../../components";
import useReward from "../List/useReward";
import { useEffect } from "react";
import CardUpdateButton from "./CardUpdateButton";
import RewardCardTable from "./RewardCardTable";
import { PaginationProps } from "../../../components/common/Pagination";
import { AfriexPermissions } from "../../../types";
import { AfriexActions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const Details = () => {
  const params = useParams();
  const rewardId = params?.id ?? "";
  const {
    isCardLoading,
    fetchCardList,
    cardList: list,
    cardTotal: total,
    item,
  } = useReward();

  useEffect(() => {
    if (rewardId) {
      fetchCardList({}, rewardId);
    }
  }, [rewardId, fetchCardList]);

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`${item?.name ?? ""} Reward Item`} />

      <PermissionsProvider
        permission={AfriexPermissions.REWARDS_PAGE}
        action={AfriexActions.CLICK_REWARDS_UPLOAD_REMOVE}
      >
        <div className="w-full flex justify-end">
          <CardUpdateButton rewardId={rewardId} />
        </div>
      </PermissionsProvider>

      <section className="pt-5 md:pt-5">
        {isCardLoading ? <TableSkeleton /> : <RewardCardTable items={list} />}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchCardList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};
export default Details;
