import { SearchIcon, XIcon } from "@heroicons/react/solid";
import { Fragment, ReactElement } from "react";
import DateFilter from "./DateFilter";
import SelectFilter from "./SelectFilter";
import { FilterAttributeType, FilterProps } from "./types";
import useFilter from "./useFilter";

const componentMap: Record<FilterAttributeType, any> = {
  [FilterAttributeType.Date]: DateFilter,
  [FilterAttributeType.Select]: SelectFilter,
  [FilterAttributeType.CheckBox]: undefined,
  [FilterAttributeType.Number]: undefined,
  [FilterAttributeType.Text]: undefined,
};

const FilterBar = ({ onSubmit, attributes }: FilterProps): ReactElement => {
  const { filters, resetFilters, handleChange, handleSubmit } = useFilter({
    attributes,
    onSubmit,
  });
  return (
    <div className="w-full flex flex-wrap md:flex-row md:items-center">
      {attributes.map((attribute) => {
        const Component = componentMap[attribute.type] ?? Fragment;
        return (
          <div
            className="mb-4 md:mb-0 md:mr-2 w-full md:w-auto"
            key={attribute.label}
          >
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor={attribute.label}
            >
              {attribute.title}
            </label>
            <Component
              attribute={attribute.label}
              value={filters[attribute.label] || attribute?.defaultValue || ""}
              options={attribute.options}
              onChange={handleChange}
            />
          </div>
        );
      })}

      <span className="inline-flex items-center p-2 rounded-md border border-md border-gray-300 text-gray-900 sm:text-sm">
        <button onClick={handleSubmit as any} className="hover:text-indigo-500">
          <SearchIcon className="h-5 w-5" />
        </button>
      </span>
      <div className="flex items-center p-5">
        <button
          onClick={() => {
            setTimeout(() => {
              resetFilters();
              handleSubmit();
            }, 1000);
          }}
          title="Clear filters"
        >
          <XIcon className="h-5 w-5 mr-2 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
