import { Button } from "../../../components";
import { CustomTextArea } from "../../../components/common/Input";

const TierUserBulkUploadForm = ({
  onClose,
  userEmail,
  handleSubmit,
  setUserEmail,
  isDisabled,
  submitLabel,
}: any) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleSubmit() as any;
    }}
    className="flex flex-col space-y-5"
  >
    <div className="mt-5">
      <CustomTextArea
        name="bulkUpload"
        label="Enter email, Phone number or User Id of each user one per line"
        type="text"
        value={userEmail ?? ""}
        className="w-full isFull"
        aria-rowspan={3}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setUserEmail(e.target?.value ?? userEmail)
        }
      />
    </div>

    <div className="flex w-full justify-end space-x-2 py-5">
      <Button
        type="button"
        onClick={onClose}
        variant="outline"
        colorScheme="cyan"
      >
        Cancel
      </Button>

      <Button
        disabled={isDisabled}
        type="submit"
        variant="outline"
        colorScheme="cyan"
      >
        {submitLabel}
      </Button>
    </div>
  </form>
);

export default TierUserBulkUploadForm;
