import { useMemo } from "react";
import { ArbitrageCycle } from "../../../types";

export const ArbitrageReporter = ({
  amounts,
  currencies,
  tolerance,
}: ArbitrageCycle & { tolerance: number }) => {
  // Calculate total conversion
  const totalAmount = useMemo(() => {
    return amounts?.reduce((accumulatedAmount, currentAmount) => {
      const current = parseFloat(currentAmount?.toString()); // Ensure the value is a number
      return accumulatedAmount * (isNaN(current) ? 1 : current);
    }, 1);
  }, [amounts]);

  // Calculate profit and profit percentage
  const profit = (totalAmount ?? 1) - 1;
  const profitPercentage = (profit * 100).toFixed(2);

  // If profit is less than the tolerance, do not render the table
  if (Number(profitPercentage) < tolerance) return null;

  return (
    <div>
      <p className="mb-2">
        <strong>Path:</strong> {currencies?.join(" → ")}
      </p>
      <table className="table-auto border-collapse w-full mb-4">
        <thead className="bg-red-50">
          <tr>
            <th className="border px-4 py-2">Path</th>
            <th className="border px-4 py-2">Currency Pair</th>
            <th className="border px-4 py-2">Conversion Factor</th>
          </tr>
        </thead>
        <tbody>
          {currencies?.map((currency, index) => {
            const conversionFactor = parseFloat(
              amounts?.[index]?.toString() as string
            ); // Ensure value is a number

            if (currencies[index + 1]) {
              return (
                <tr key={index}>
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">
                    {currency} → {currencies[index + 1] ?? currencies[0]}
                  </td>
                  <td className="border px-4 py-2">
                    {isNaN(conversionFactor)
                      ? "N/A"
                      : conversionFactor.toFixed(6)}
                  </td>
                </tr>
              );
            }
          })}
          {/* Add Total Conversion and Profit rows */}
          <tr className="bg-gray-50 font-semibold">
            <td className="border px-4 py-2" colSpan={2}>
              Total Conversion
            </td>
            <td className="border px-4 py-2">{totalAmount?.toFixed(8)}</td>
          </tr>
          <tr className="bg-gray-50 font-semibold">
            <td className="border px-4 py-2" colSpan={2}>
              Profit
            </td>
            <td className="border px-4 py-2">
              {profit.toFixed(8)} ({profitPercentage}%)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
