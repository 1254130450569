import useFilter from "./useFilter";

type SelectOptions = Record<any, string>;

export interface DateOptions {
  minDate?: Date;
  maxDate?: Date;
}

type FilterAttributeOptions = SelectOptions | DateOptions;

export enum FilterAttributeType {
  Select = "select",
  CheckBox = "checkbox",
  Number = "number",
  Date = "date",
  Text = "text",
}

export type FilterAttribute = {
  type: FilterAttributeType;
  label: string;
  title: string;
  defaultValue?: string;
  options?: FilterAttributeOptions;
};

type FilterResult = Record<string, string | number | boolean>;

export type FilterProps = {
  attributes: FilterAttribute[];
  onSubmit: (filters: FilterResult) => void;
};

export type FilterReturnProps = ReturnType<typeof useFilter>;
export type FilterInputProps = {
  onChange: (value: any, attribute: string) => void;
  attribute: string;
  value: string;
  options?: FilterAttributeOptions;
};
