export enum TransactionProcessors {
  STRIPE = "STRIPE",
  MONO = "MONO",
  OKRA = "OKRA",
  BEYONIC = "BEYONIC",
  CELLULANT = "CELLULANT",
  VFD = "VFD",
  BINANCE = "BINANCE",
  PROVIDUS = "PROVIDUS",
  SHUTTERSCORE = "SHUTTERSCORE",
  ZEEPAY = "ZEEPAY",
  ZENITH = "ZENITH",
  MONCASH = "MONCASH",
  TERRAPAY = "TERRAPAY",
  MFS = "MFS",
  DWOLLA = "DWOLLA",
  DUPLO = "DUPLO",
  CHAPA = "CHAPA",
  PLAID = "PLAID",
  HUB2 = "HUB2",
  FAIRMONEY = "FAIRMONEY",
  DLOCAL = "DLOCAL",
  CHECKOUT = "CHECKOUT",
  FINCRA = "FINCRA",
  ZILMONEY = "ZILMONEY",
  MOORWAND = "MOORWAND",
  MONIEPOINT = "MONIEPOINT",
  ALPAY = "ALPAY",
  PAYSTACK = "PAYSTACK",
  ECSA = "ECSA",
  WAPIPAY = "WAPIPAY",
  KORAPAY = "KORAPAY",
}

export type NigerianBankWithdrawalProcessors = Extract<
  TransactionProcessors,
  | TransactionProcessors.PROVIDUS
  | TransactionProcessors.VFD
  | TransactionProcessors.SHUTTERSCORE
  | TransactionProcessors.ZENITH
>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NigerianBankWithdrawalProcessors = {
  [TransactionProcessors.PROVIDUS]: TransactionProcessors.PROVIDUS,
  [TransactionProcessors.VFD]: TransactionProcessors.VFD,
  [TransactionProcessors.SHUTTERSCORE]: TransactionProcessors.SHUTTERSCORE,
  [TransactionProcessors.ZENITH]: TransactionProcessors.ZENITH,
};

export type MobileMoneyWithdrawalProcessors = Extract<
  TransactionProcessors,
  | TransactionProcessors.BEYONIC
  | TransactionProcessors.CELLULANT
  | TransactionProcessors.ZEEPAY
>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileMoneyWithdrawalProcessors = {
  [TransactionProcessors.BEYONIC]: TransactionProcessors.BEYONIC,
  [TransactionProcessors.CELLULANT]: TransactionProcessors.CELLULANT,
  [TransactionProcessors.ZEEPAY]: TransactionProcessors.ZEEPAY,
};
