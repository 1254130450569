import { Modal, Button, SpinningLoader, Select } from "../../../components";
import { SupportedCurrencies, TransactionTypes } from "../../../types";
import { UpdateProcessorRequest } from "../../../services/processorService";
import { TransactionProcessors } from "../../../types/ProcessorTypes";
import { useEffect, useMemo, useState } from "react";

type FormProps = {
  onClose: VoidFunction;
  handleUpdate: (data: UpdateProcessorRequest) => void;
  processors: TransactionProcessors[];
  currency: SupportedCurrencies;
};

const UpdateForm = ({
  onClose,
  handleUpdate,
  processors,
  currency,
}: FormProps) => {
  const [transactionType, setTransactionType] = useState<TransactionTypes>(
    TransactionTypes.DEPOSIT
  );
  const [processor, setProcessor] = useState<TransactionProcessors>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const processorOptions = useMemo(
    () =>
      processors.map((p) => ({
        label: p,
        value: p,
      })),
    [processors]
  );

  const transactionTypeOptions = [
    {
      label: "Deposit",
      value: "DEPOSIT",
    },
    {
      label: "Withdrawal",
      value: "WITHDRAW",
    },
  ];

  const handleSubmit = async () => {
    const payload: UpdateProcessorRequest = {
      currency,
      type: transactionType as TransactionTypes,
      processorName: processor as TransactionProcessors,
    };
    setIsLoading(true);
    await handleUpdate(payload);
    setIsLoading(false);
  };

  useEffect(() => {
    setProcessor(processors[0]);
    setTransactionType(TransactionTypes.DEPOSIT);
  }, [processors]);
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">
          Choose Default Processor for {currency}
        </h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <Select
                  options={transactionTypeOptions}
                  label="Transaction Type"
                  onChange={(e: any) => setTransactionType(e?.target?.value)}
                />
              </div>
              <div className="mt-5">
                <Select
                  options={processorOptions}
                  label="Processor"
                  onChange={(e: any) => setProcessor(e?.target?.value)}
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateForm;
