import { Button } from "../../../components";
import { CustomInput } from "../../../components/common/Input";
import { LoginFormProps } from "./useLogin";

const LoginForm = ({
  isLoading,
  handleLogin,
  handleChange,
}: LoginFormProps) => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="w-2/5 flex flex-col justify-center items-center border rounded-md py-7">
        <h1 className="text-center text-gray-700 font-semibold text-xl">
          Afriex Admin Portal
        </h1>
        <div className="flex flex-col justify-center items-center w-full my-5 p-5 ">
          <form
            className="w-3/5 flex flex-col space-y-10"
            onSubmit={handleLogin}
          >
            <CustomInput
              onChange={handleChange}
              label="Email"
              type="email"
              name="email"
              required
              className="w-full"
            />

            <CustomInput
              onChange={handleChange}
              label="Password"
              type="password"
              name="password"
              required
              className="w-full"
            />

            <Button
              variant="solid"
              colorScheme="cyan"
              type="submit"
              isLoading={isLoading}
              loadingText="Logging in..."
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
