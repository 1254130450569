import { usePromotions } from "../../../pages/Promotions/usePromotions";
import { AfriexTier } from "../../../pages/Promotions";
import { useEffect, useState } from "react";
import useUserDetails from "./useUserDetails";
import UserTierTable from "./UserTierTable";
import UserCardListTable from "./UserCardListTable";
import { AxiosError } from "axios";
import { CustomInput } from "../../../components/common/Input";
import { Button } from "../../../components";
import { AfriexActions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../../types";

const TierInformation = ({ id, transactionCount, userId }: any) => {
  const { getPromotion, updatePromotionForUser, deletePromotionForUser } =
    usePromotions();
  const [promotion, setPromotion] = useState<AfriexTier | null>(null);
  const [tierIdInput, setTierIdInput] = useState<string>("");
  const { fetchUserDetails, activeCards, updateCardBankRegulatedStatus } =
    useUserDetails(userId);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTierIdInput(event.target.value);
  };

  const handleUpdatePromotion = async () => {
    if (!tierIdInput.trim()) {
      alert("Please provide a Tier ID.");
      return;
    }

    try {
      await updatePromotionForUser(userId, tierIdInput);
      await fetchUserDetails();
      setTierIdInput("");
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        alert("Tier not found.");
        return;
      }
      alert((error as AxiosError)?.message || "Error updating tier for user.");
    }
  };

  const handleDeletePromotion = async () => {
    try {
      await deletePromotionForUser(userId);
      await fetchUserDetails();
    } catch (error) {
      alert((error as AxiosError)?.message || "Error deleting tier for user.");
    }
  };

  useEffect(() => {
    if (id) {
      getPromotion(id).then((promotion) => {
        setPromotion(promotion);
      });
    }
  }, [id]);

  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Promos & Tiers".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex flex-col gap-3">
        <h3 className="text-sm font-semibold text-gray-600">
          Tiers / Promotions
        </h3>

        <div className={"max-w"}>
          {promotion && id && (
            <UserTierTable
              tierInfo={promotion as any}
              transactionCount={transactionCount}
            />
          )}

          <PermissionsProvider
            permission={AfriexPermissions.KYC_PAGE}
            action={AfriexActions.CLICK_USER_TIERS_UPDATE_DELETE}
          >
            <div className="update-tier-form flex flex-col mt-5">
              <h1 className="text-sm font-semibold mb-2 text-gray-600">
                {id ? "Modify / Delete " : "Add "} Tier
              </h1>

              <div className="flex gap-5">
                <CustomInput
                  type="text"
                  value={tierIdInput}
                  onChange={handleInputChange}
                  placeholder="Tier ID"
                  className="w-60"
                />

                <div className="flex gap-3">
                  <Button
                    variant="solid"
                    colorScheme="cyan"
                    onClick={handleUpdatePromotion}
                  >
                    {(promotion ? "Update" : "Add User to") + " Tier"}
                  </Button>

                  {promotion && id && (
                    <Button
                      variant="solid"
                      colorScheme="red"
                      onClick={handleDeletePromotion}
                    >
                      {"Delete Tier"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </PermissionsProvider>
        </div>

        <div className="mt-3">
          <h2 className="text-sm font-semibold text-gray-600 mb-2">
            Active Cards
          </h2>
          <UserCardListTable
            activeCardList={activeCards}
            userId={userId}
            updateCardBankRegulatedStatus={updateCardBankRegulatedStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default TierInformation;
