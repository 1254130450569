import UserSecurityNoteForm from "./UserSecurityNoteForm";
import UserSecurityNotes from "./UserSecurityNotes";
import { UserDetailsReturnProps } from "../../screens/User/Details/types";

type SecurityViewProps = Pick<
  UserDetailsReturnProps,
  | "addSecurityNote"
  | "isAddingSecurityNote"
  | "securityNotes"
  | "isLoadingSecurityNotes"
  | "user"
  | "hasMoreNotes"
  | "loadMoreSecurityNotes"
  | "isFetchingMore"
>;

const SecurityView = ({
  addSecurityNote,
  isAddingSecurityNote,
  securityNotes,
  isLoadingSecurityNotes,
  user,
  hasMoreNotes,
  loadMoreSecurityNotes,
  isFetchingMore,
}: SecurityViewProps) => {
  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Security".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex flex-col gap-3">
        <UserSecurityNoteForm
          addSecurityNote={addSecurityNote}
          isAddingSecurityNote={isAddingSecurityNote}
          user={user}
        />
        <UserSecurityNotes
          securityNotes={securityNotes}
          isLoadingSecurityNotes={isLoadingSecurityNotes}
          hasMoreNotes={hasMoreNotes}
          loadMoreSecurityNotes={loadMoreSecurityNotes}
          isFetchingMore={isFetchingMore}
        />
      </div>
    </div>
  );
};

export default SecurityView;
