import {
  XIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";

const colors: { [key: string]: string } = {
  success: "successful",
  error: "failed",
  info: "pending",
};
const icons: { [key: string]: JSX.Element } = {
  success: <CheckCircleIcon className="w-4 h-4" />,
  error: <XCircleIcon className="w-4 h-4" />,
  info: <InformationCircleIcon className="w-4 h-4" />,
};

export default function CustomToast({
  message,
  variant = "info",
  onClose,
}: {
  message: string;
  variant?: string;
  onClose: () => void;
}) {
  return (
    <div
      className={`w-full items-center bg-${colors[variant]}-100 text-${colors[variant]}-500 text-sm text-light rounded py-1 px-4`}
    >
      <div className="flex w-full justify-end">
        <button className="font-bold" onClick={onClose}>
          <XIcon className="w-3 h-3" />
        </button>
      </div>
      <div className="w-full flex items-center">
        <span className=" p-1">{icons[variant]} </span>
        {message}
      </div>
    </div>
  );
}
