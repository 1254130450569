import { AfriexPerson } from "../../../types";
import { useEffect, useMemo, useState } from "react";
import { renderFile } from "../../../utils/imageFiles";
import { kycBucketKey, fileService } from "../../../services/fileService";
type KYCRequestedDcumentsProps = {
  user: AfriexPerson;
};

const KYCRequestedDcuments = ({ user }: KYCRequestedDcumentsProps) => {
  const [fileUrls, setFileUrls] = useState<{ [key: string]: string }>({});

  const documents = useMemo(
    () => user?.kyc?.complianceDocuments || {},
    [user?.kyc?.complianceDocuments]
  );

  useEffect(() => {
    const fetchAllUrls = async () => {
      const urls: { [key: string]: string } = {};
      for (const [docType, fileName] of Object.entries(documents)) {
        if (typeof fileName === "string") {
          try {
            const url = await fileService.fetchUploadedFile(
              fileName,
              kycBucketKey
            );
            urls[docType] = url;
          } catch (error) {
            console.error(`Failed to fetch URL for ${docType}:`, error);
          }
        }
      }
      setFileUrls(urls);
    };

    fetchAllUrls();
  }, [documents]);
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Requested Documents".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="mb-2 py-2 flex flex-col gap-3 justify-between">
        <p className="text-sm font-medium items-center gap-4 flex text-gray-700">
          {user.kyc?.requestedAdditionalDocuments?.map((title, index) => (
            <span key={index}>
              [
              {title
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
              ]{" "}
            </span>
          ))}
        </p>

        <div className="flex flex-wrap gap-1">
          {Object.keys(fileUrls).length > 0 ? (
            Object.entries(fileUrls).map(([docType, url]) => (
              <div key={docType} className="w-1/4 pr-2">
                {renderFile(url, docType)}
              </div>
            ))
          ) : (
            <p>No documents requested</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default KYCRequestedDcuments;
