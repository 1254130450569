import { AfriexReferralTier } from "../../../types";
import { AfriexQueryParams } from "../../../types/AfriexQueryParams";

export type TierListProps<T> = {
  error: any;
  fetchList: (params: AfriexQueryParams) => void;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  total: number;
  list: T[];
};

export enum TierActionType {
  UPDATE_TIER = "UPDATE_TIER",
}

interface UpdateTierAction {
  type: TierActionType.UPDATE_TIER;
  payload: Partial<AfriexReferralTier>;
}

export type TierAction = UpdateTierAction;
