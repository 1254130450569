import { useCallback, useReducer, useState } from "react";
import { ratesService } from "../../../services/ratesService";
import {
  AfriexOTCRate,
  AfriexOTCVolume,
  SupportedCountryCodes,
  SupportedCurrencies,
} from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import updateOTCReducer from "./updateOTCReducer";
import { omit } from "lodash";
import { cleanNumber } from "../../../utils/rates/cleanNumber";
import Papa from "papaparse";
import { removeEmptyRows } from "../../../constants/formatters";

const useOTCVolumeUpdate = (
  onClose: VoidFunction,
  initialData: Partial<AfriexOTCVolume> = {
    fromSymbol: SupportedCurrencies.USD,
    toSymbol: SupportedCurrencies.NGN,
    country: SupportedCountryCodes.US,
  }
) => {
  const [otcRate, dispatch] = useReducer(
    updateOTCReducer,
    initialData as AfriexOTCVolume
  );
  const [isLoading, setIsLoading] = useState(false);
  const [otcRates, setOTCRates] = useState<AfriexOTCRate[]>([]);

  const handleSubmit = async (rawRates: AfriexOTCVolume) => {
    setIsLoading(true);
    try {
      if (!rawRates.country) {
        showErrorMessage(`Please select a country`);
        return;
      }
      if (!rawRates.fromSymbol) {
        showErrorMessage(`Please select the origin currency`);
        return;
      }
      if (!rawRates.toSymbol) {
        showErrorMessage(`Please select the destination currency`);
        return;
      }
      if (!rawRates.fromAmount) {
        showErrorMessage(`Please enter the amount sent`);
        return;
      }
      if (!rawRates.toAmount) {
        showErrorMessage(`Please enter the amount received`);
        return;
      }
      if (!rawRates.country) {
        showErrorMessage(`Please select a country`);
        return;
      }

      const rates: AfriexOTCVolume = {
        ...rawRates,
        timestamp: rawRates.timestamp ?? new Date(),
        rate: Number(rawRates.toAmount) / Number(rawRates.fromAmount),
      };

      if (rawRates.id) {
        const fieldsToOmit = [
          "submittedBy",
          "approvedBy",
          "createdAt",
          "updatedAt",
        ];
        const otcVolume = omit(rates, fieldsToOmit) as AfriexOTCVolume;

        await ratesService.updateOTCVolume(otcVolume);
        showSuccessMessage(`OTC volume updated successfully`);
      } else {
        await ratesService.saveOTCVolume({ otcRates, otcVolume: rates });
        showSuccessMessage(`OTC volume saved successfully`);
      }

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      showErrorMessage(`Error saving OTC rate: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCSVToOTCRate = useCallback(async (csvRows: any) => {
    if (!Array.isArray(csvRows) || csvRows.length === 0) {
      return showErrorMessage(
        "Upload a valid OTC rates csv file to view rates"
      );
    }

    const rates = csvRows
      .filter((row) => row?.["From"] !== row?.["TO"])
      .map((row: any) => ({
        fromSymbol: row?.["From"]?.trim().toUpperCase(),
        toSymbol: row?.["TO"]?.trim().toUpperCase(),
        value: cleanNumber(row?.["RATE"]),
        inverse: cleanNumber(row?.["Rate(inverse)"]),
        source: row?.["source"]?.trim()?.toLowerCase() ?? "afriex",
      }));
    setOTCRates(removeEmptyRows(rates as AfriexOTCRate[]));
  }, []);

  const handleOTCRatesUpload = useCallback(
    async (event: any): Promise<void> => {
      try {
        if (event.target.files) {
          const file = event.target.files[0];

          Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
              if (results.data) {
                formatCSVToOTCRate(results.data);
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage("Upload a valid OTC rates csv file to view rates");
      }
    },
    [formatCSVToOTCRate]
  );

  return { otcRate, isLoading, dispatch, handleSubmit, handleOTCRatesUpload };
};

export default useOTCVolumeUpdate;
