import { Button, CustomSelect } from "../../../components";
import { CustomTextArea } from "../../../components/common/Input";
import {
  AfriexBlockReasonsMap,
  AfriexUnblockReasonsMap,
} from "../../../constants/formatters";
import { AfriexPerson } from "../../../types";
import CustomModal, { ModalProps } from "../../CustomModal";

interface BlockUserModalProps extends Omit<ModalProps, "title"> {
  user: AfriexPerson;
  isLoading: boolean;
  onSubmit: () => void;
  securityNote: string;
  handleSecurityNoteChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlockReason: (val: string) => void;
  handleUnblockReason: (val: string) => void;
  unblockReason: string;
  blockReason: string;
}

export function BlockUserModal({
  user,
  isLoading,
  onClose,
  onSubmit,
  securityNote,
  handleSecurityNoteChange,
  handleBlockReason,
  blockReason,
  unblockReason,
  handleUnblockReason,
  isOpen,
}: BlockUserModalProps) {
  const securityLabel = user.isSecurityFlagged ? "Unblock" : "Block";
  const label = `${securityLabel} ${user.name.firstName}`;
  const hasReason = blockReason || unblockReason ? true : false;
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} width="w-3/12" title={label}>
      <>
        <h3 className="text-sm">Enter security note to {label}</h3>
        <div className="text-sm font-light mt-3">
          <CustomTextArea
            name="securityNote"
            required
            onChange={handleSecurityNoteChange}
            value={securityNote ?? ""}
            aria-rowspan={5}
            type="text"
            className="w-full isFull"
            placeholder={`${label} because ...`}
          />
        </div>

        <div className="flex sm:mt-5">
          <CustomSelect
            onChange={
              user.isSecurityFlagged ? handleUnblockReason : handleBlockReason
            }
            value={user.isSecurityFlagged ? unblockReason : blockReason}
            options={
              user.isSecurityFlagged
                ? AfriexUnblockReasonsMap
                : AfriexBlockReasonsMap
            }
            name="reason"
            placeholder={`Select ${user.isSecurityFlagged ? "Unblock" : "Block"} reason`}
            label="Select Reason"
          />
        </div>

        <div className="flex w-full justify-end space-x-2 py-5">
          <Button onClick={onClose} variant="outline" colorScheme="cyan">
            cancel
          </Button>
          <Button
            disabled={isLoading || !securityNote || !hasReason}
            onClick={onSubmit}
            colorScheme={user.isSecurityFlagged ? "orange" : "red"}
          >
            {label}
          </Button>
        </div>
      </>
    </CustomModal>
  );
}
