import afriexLogo from "../../assets/svg/afriex-new-logo.png";

const ReceiptHeader = () => {
  return (
    <div className="m-7">
      <div className="logoCon items-center flex gap-1 w-50 mb-3">
        <div id="logoPng" className="logoSvg">
          <img src={afriexLogo} alt="Afriex Logo" />
        </div>
      </div>
      <hr className="border-receipt-line" />
    </div>
  );
};

export default ReceiptHeader;
