export const transactionHeaders = [
  { key: "_id", label: "Transaction ID" },
  { key: "createdAt", label: "Date" },
  { key: "amount", label: "Amount" },
  { key: "currency", label: "Currency" },
  { key: "state", label: "Status" },
  { key: "customer_details.email", label: "Email" },
  { key: "bank_account_info.account_number", label: "Account Number" },
  { key: "bank_account_info.bank_code", label: "Bank Code" },
  { key: "", label: "Comment" },
];
