import { useCallback, useEffect, useState } from "react";
import { transactionService } from "../../../services/transactionService";
import { AfriexQueryParams, AfriexTransaction } from "../../../types";

const useRewardsTransactions = (userId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState<AfriexTransaction[]>([]);

  const getRewardTransactions = useCallback(
    async (params?: AfriexQueryParams) => {
      try {
        setLoading(true);
        const txns = await transactionService.getRewardTransactions(
          userId as string,
          params as AfriexQueryParams
        );
        setTransactions(txns.data);
      } catch (error) {
        setError(error as any);
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    getRewardTransactions({});
  }, [getRewardTransactions]);

  return {
    error,
    loading,
    transactions,
  };
};

export default useRewardsTransactions;
