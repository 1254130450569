import { useState } from "react";
import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import CountryList from "../Country";
import CurrencyList from "../Currency";

const ConfigPage = () => {
  const [countryTotal, setCountryTotal] = useState("");
  const [currencyTotal, setCurrencyTotal] = useState("");

  const configTabs: TabItem[] = [
    {
      id: "countryList",
      title: `Countries ${countryTotal}`,
      content: <CountryList setCountryTotal={setCountryTotal} />,
    },
    {
      id: "currenciesList",
      title: `Currencies ${currencyTotal}`,
      content: <CurrencyList setCurrencyTotal={setCurrencyTotal} />,
    },
  ];
  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Configurations" />
      <Tabs tabs={configTabs} />
    </main>
  );
};

export default ConfigPage;
