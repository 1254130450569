import { groupBy, Dictionary } from "lodash";
import {
  AfriexRate,
  RatesMap,
  RatesValueMap,
  SupportedCurrencies,
} from "../../types";

export const convertRatesListToMap = (rates: AfriexRate[]): RatesMap => {
  const collapseRates = (arr: AfriexRate[]): RatesValueMap =>
    arr.reduce((acc, x) => ({ ...acc, [x.toSymbol]: x.value }), {});

  const ratesToReturn: Partial<Record<SupportedCurrencies, RatesValueMap>> = {};
  const ratesByBase = groupBy(rates, (rate) => rate.fromSymbol) as Dictionary<
    AfriexRate[]
  >;

  for (const [key, value] of Object.entries(ratesByBase)) {
    ratesToReturn[key as SupportedCurrencies] = collapseRates(
      value as AfriexRate[]
    );
  }

  return ratesToReturn;
};
