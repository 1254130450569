import { ChangeEvent, useState } from "react";
import { ledger } from "../../../api/transactions";
import { AfriexPerson } from "../../../types";
import { ICurrency } from "../../../types/Currency";
import { LedgerReasons } from "../../../types/Transaction";
import { capitalize } from "lodash";

type Response = {
  message: string;
  status: string;
};

const useFundUserWallet = (user: AfriexPerson, initialResponse: Response) => {
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<ICurrency | null>(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState(initialResponse);
  const [action, setAction] = useState<"credit" | "debit">("debit");
  const [fundDebitReason, setFundDebitReason] = useState<LedgerReasons>();

  async function handleSelectReason(event: ChangeEvent<HTMLFormElement>) {
    setFundDebitReason(event.target.value);
  }

  const handleAmountChange = (name: string, value: number) => {
    setAmount(value);
  };

  const handleCurrencyChange = (name: string, value: ICurrency) => {
    setCurrency(value);
  };

  const handleLedgerButtonClick = (action: "debit" | "credit") => {
    setAction(action);
    setShowModal(true);
  };

  // isValidTransaction -> amount is greater than 0, amount is not Nan, fundDebitReason exists, currency exists
  const isValidTransaction = () => {
    if (currency && fundDebitReason && (amount > 0 || !isNaN(amount))) {
      return false;
    }

    return true;
  };

  async function handleLedger() {
    try {
      setLoading(true);
      const response = await ledger({
        currency: (currency as ICurrency)?.code,
        amount: amount,
        action: action,
        userId: user.id,
        reason: fundDebitReason as LedgerReasons,
      });
      if (response) {
        setLoading(false);
        setShowModal(false);
        setAmount(0);
      }

      setResponse({
        status: "success",
        message: `Successfully ${action}ed ${currency?.code} ${amount} ${
          action === "debit" ? "from" : "to"
        } ${user.name?.firstName} ${
          user.name?.lastName
        }'s Wallet for ${capitalize(fundDebitReason?.replace(/_/g, " "))}`,
      });
    } catch (err: any) {
      setLoading(false);
      setShowModal(false);
      const message = err.response?.data.message
        ? err.response?.data.message
        : err.message || "Unknown Error! Contact Admin";
      setResponse({ status: "error", message });
    }
  }
  return {
    amount,
    currency,
    loading,
    showModal,
    response,
    action,
    handleAmountChange,
    handleCurrencyChange,
    handleLedgerButtonClick,
    isValidTransaction,
    handleLedger,
    setShowModal,
    setResponse,
    handleSelectReason,
    fundDebitReason,
  };
};

export default useFundUserWallet;
