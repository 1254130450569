import Spinner from "../../../assets/svg/Spinner";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import { RateInfoProps } from "../types";
import RatesTable from "./RatesTable";

const RatesList = ({ isLoading, rateInfo }: RateInfoProps) => {
  const appRates = convertRatesListToMap(rateInfo?.appRates ?? []);
  return (
    <>
      {isLoading ? (
        <div className="w-full flex pt-5 sm:pt-10 justify-center">
          <Spinner size={8} className="text-cyan-700" />
        </div>
      ) : (
        <RatesTable rates={appRates} />
      )}
    </>
  );
};

export default RatesList;
