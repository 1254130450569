import { ArbitrageCheckInfo, SupportedCurrencies } from "../../types";
import { calculateArbitrageProfit } from "./calculateArbitrageProfit";
import { checkPairwiseDiscrepancy } from "./checkPairwiseDiscrepancy";
import { transformCycleToAmounts } from "./transformCycleToAmounts";
import { transformToNegativeLog } from "./transformToNegativeLog";

/**
 * This function detects arbitrage in a given rates matrix using Bellman-Ford algorithm
 * The following steps are taken:
 * 1. Transform the rates map to
 * 2. Transform the rates matrix to negative log values
 * 3. Relax edges V-1 times
 * 4. Check for negative-weight cycle after relaxing edges V-1 times
 * If a negative-weight cycle is found, then arbitrage is detected
 * @param matrix
 * @param currencySymbols
 * @returns
 */

export const detectArbitrage = (
  matrix: number[][],
  currencySymbols: SupportedCurrencies[],
  tolerancePercentage = 1
): ArbitrageCheckInfo | null => {
  // Transform the rates matrix to negative log values
  const pairWiseDiscrepancy = checkPairwiseDiscrepancy(
    matrix,
    currencySymbols,
    tolerancePercentage
  );

  const transformedMatrix = transformToNegativeLog(matrix);
  const numCurrencies = transformedMatrix.length;

  // Initialize distances to Infinity except for the start node
  const distances = new Array(numCurrencies).fill(Infinity);
  distances[0] = numCurrencies - 1; // Start with the first currency

  // Create predecessor array to keep track of the path.
  const predecessor: number[] = new Array(numCurrencies).fill(-1);

  // Relax edges V-1 times
  for (let i = 0; i < numCurrencies - 1; i++) {
    for (let src = 0; src < numCurrencies; src++) {
      for (let dest = 0; dest < numCurrencies; dest++) {
        if (distances[src] + transformedMatrix[src][dest] < distances[dest]) {
          distances[dest] = distances[src] + transformedMatrix[src][dest];
          predecessor[dest] = src;
        }
      }
    }
  }

  // Check for negative-weight cycle after full relaxation
  const cycles = [];
  for (let src = 0; src < numCurrencies; src++) {
    for (let dest = 0; dest < numCurrencies; dest++) {
      if (distances[src] + transformedMatrix[src][dest] < distances[dest]) {
        const startNode = dest;
        const printCycle: number[] = [dest, src];
        // Start from the source and go backwards until you see the source vertex again or any vertex that already exists in the printCycle array
        while (!printCycle.includes(predecessor[src])) {
          printCycle.push(predecessor[src]);
          src = predecessor[src];
        }
        printCycle.push(startNode);
        printCycle.reverse();
        const amounts = transformCycleToAmounts(printCycle, matrix);
        const profitPercentage = calculateArbitrageProfit({ amounts });
        if (profitPercentage > tolerancePercentage) {
          cycles.push({
            amounts,
            currencies: printCycle.map((p) => currencySymbols[p]),
            printCycle,
          });
        }
      }
    }
  }

  return { cycles, pairWiseDiscrepancy };
};
