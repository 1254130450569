import React, { useState } from "react";
import { AfriexPermissions } from "../../types";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Button } from "..";
import { PermissionsProvider } from "../common/PermissionsProvider";
import { AfriexActions } from "../../types";
import ActivationModal from "../CustomModal/ActivationModal";
import { DeactivateAccountProps } from "./types";

const DeactivateAccount: React.FC<
  React.PropsWithChildren<DeactivateAccountProps>
> = ({ user, type = "user" }) => {
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const action =
    type === "user"
      ? AfriexActions.CLICK_DEACTIVATE_USER
      : AfriexActions.CLICK_SETTINGS_DEACTIVATE_ADMIN;
  const permission =
    type === "user"
      ? AfriexPermissions.USERS_PAGE
      : AfriexPermissions.SETTINGS_PAGE;

  const toggleActivationModal = () =>
    setIsActivationModalOpen(!isActivationModalOpen);

  return (
    <React.Fragment>
      <PermissionsProvider permission={permission} action={action}>
        <div className="px-3 pt-3 mt-8">
          <h3 className="flex items-center gap-2 font-semibold text-failed-500 mb-2">
            {"Danger Zone".toLocaleUpperCase()}{" "}
            <ExclamationIcon className="w-6 h-6 text-failed-500" />
          </h3>
          <hr className="mb-5" />

          <div>
            <p className="text-gray-500">
              {user.isDeactivated
                ? "Activating a user account gives them access to all Afriex products and services."
                : "Deactivating a user account removes access to all Afriex products and services for the user."}
            </p>

            <div className="flex justify-end mt-6 gap-6">
              <Button
                colorScheme={user.isDeactivated ? "green" : "orange"}
                onClick={toggleActivationModal}
                className="h-10"
              >
                {user.isDeactivated ? "Activate" : "Deactivate"} Account
              </Button>
            </div>
          </div>
        </div>
      </PermissionsProvider>

      <ActivationModal
        isOpen={isActivationModalOpen}
        onClose={() => setIsActivationModalOpen(false)}
        user={user}
        type={type}
      />
    </React.Fragment>
  );
};

export default DeactivateAccount;
