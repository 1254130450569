import { AfriexCountry } from "../types";
import countryFlags from "../constants/countries";

export const mapCountryListToOptions = (countryList: AfriexCountry[]) => {
  return countryList.reduce(
    (acc: Record<string, string>, country: AfriexCountry) => {
      acc[country.iso2] = `${country.name} ${countryFlags[country?.iso2]}`;
      return acc;
    },
    {}
  );
};

export const mapCountryListToCurrencies = (
  countryList: AfriexCountry[]
): Record<string, string> => {
  return countryList.reduce(
    (acc: Record<string, string>, country: AfriexCountry) => {
      if (country.currency) {
        acc[country.currency] =
          `${country.name} ${countryFlags[country?.iso2]}`;
      }
      return acc;
    },
    {}
  );
};
