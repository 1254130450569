import { useCallback, useEffect, useState } from "react";
import { userService } from "../../../services/userService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { AfriexLog } from "../../../types/AfriexLog";
import { useParams } from "react-router-dom";

const useUserLogList = () => {
  const [userLogList, setUserLogList] = useState<AfriexLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);
  const params = useParams();
  const userId = params?.userId ?? "";

  const fetchUserLogList = useCallback(
    async (params: any) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: logs, total } = await userService.getUserLogs(
          userId,
          params
        );
        setUserLogList(logs);
        setUserTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchUserLogList({});
  }, [fetchUserLogList]);

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      try {
        setError(null);
        setIsLoading(true);
        setShowPagination(false);
        if (!searchTerm?.trim()) {
          await fetchUserLogList({});
          return;
        }
        const log = await userService.searchLog(searchTerm);
        if (log) {
          setUserLogList([log]);
          setUserTotal(1);
          setIsSearch(true);
        } else {
          showErrorMessage("No log matches " + searchTerm);
        }
      } catch (e) {
        setError(e as any);
      }
      setIsLoading(false);
    },
    [fetchUserLogList]
  );

  const handleAutoComplete = useCallback(
    async (searchTerm: string) => {
      try {
        setError(null);
        setIsLoading(true);
        setShowPagination(false);
        if (!searchTerm?.trim() || searchTerm.length < 3) {
          await fetchUserLogList({});
          return;
        }
        const logs = await userService.searchLogAutoComplete(searchTerm);
        if (logs) {
          setUserLogList(logs);
          setUserTotal(1);
          setIsSearch(true);
        }
      } catch (e) {
        setError(e as any);
      }
      setIsLoading(false);
    },
    [fetchUserLogList]
  );

  return {
    error,
    fetchUserLogList,
    handleAutoComplete,
    handleSearch,
    isLoading,
    isSearch,
    page,
    setPage,
    showPagination,
    total: userTotal,
    userLogList,
  };
};

export default useUserLogList;
