import { useEffect } from "react";
import { Header, Pagination, Table, TBody } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import UpdateButton from "./UpdateButton";
import useCurrencyUpdate from "./useCurrencyUpdate";
import { PaginationProps } from "../../../components/common/Pagination";
import { AfriexActions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../../types";

const CurrencyList = ({
  setCurrencyTotal,
}: {
  setCurrencyTotal: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { isLoading, isSearch, fetchList, handleAdd, pageInfo, list } =
    useCurrencyUpdate();
  useEffect(() => {
    const tots = pageInfo.total ? ` (${pageInfo.total.toLocaleString()})` : "";
    setCurrencyTotal(tots);
  }, [pageInfo, setCurrencyTotal]);

  /**
   * These are the filter attributes that will be passed to the filter bar
   */

  return (
    <>
      <PermissionsProvider
        permission={AfriexPermissions.SETTINGS_PAGE}
        action={AfriexActions.CLICK_SETTINGS_ADD_CURRENCY}
      >
        <div className="w-full flex justify-end mb-3">
          <UpdateButton handleSave={handleAdd} />
        </div>
      </PermissionsProvider>

      <main className="pt-5 py-10 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {list?.map((item) => <ListItem item={item} key={`${item.id}`} />)}
            </TBody>
          </Table>
        )}
      </main>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={pageInfo?.total}
          isSearch={isSearch}
        />
      ) : null}
    </>
  );
};

export default CurrencyList;
