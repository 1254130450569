import React, { useState } from "react";
import { Header, Pagination, TableSkeleton } from "../../components";
import DisputeTable from "./DisputeTable";
import useDisputeList from "./useDisputeList";
import UpdateDisputeModal from "./UpdateDisputeModal";
import DisputeCountBox from "./DisputeCountBox";
import { PaginationProps } from "../../components/common/Pagination";

const Disputes = () => {
  const {
    fetchDisputes,
    fetchDisputeCounts,
    disputes,
    disputeCounts,
    pageInfo,
    isLoading,
  } = useDisputeList();

  const [open, setOpen] = useState(false);
  const [selectedDisputeId, setSelectedDisputeId] = useState<string | null>(
    null
  );
  const handleUpdateDisputeModal = (disputeId: string) => {
    setSelectedDisputeId(disputeId);
    setOpen(!open);
  };

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Disputes" />

      <section className="flex flex-wrap p-3 pl-0 gap-4 mt-2">
        {disputeCounts && (
          <React.Fragment>
            <DisputeCountBox
              label="Today"
              count={disputeCounts.todayCount}
              color="bg-blue-100"
            />
            <DisputeCountBox
              label="Pending"
              count={disputeCounts.pendingCount}
              color="bg-yellow-100"
            />
            <DisputeCountBox
              label="Closed"
              count={disputeCounts.closedCount}
              color="bg-green-100"
            />
            <DisputeCountBox
              label="Created"
              count={disputeCounts.createdCount}
              color="bg-purple-100"
            />
            <DisputeCountBox
              label={
                disputeCounts.withNotesCount > 1 ? "Attended" : "With Notes"
              }
              count={disputeCounts.withNotesCount}
              color={
                disputeCounts.withNotesCount > 1
                  ? "bg-indigo-100"
                  : "bg-red-100"
              }
            />
          </React.Fragment>
        )}
      </section>

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <DisputeTable
            items={disputes}
            handleUpdateDisputeModal={handleUpdateDisputeModal}
          />
        )}
      </section>

      {disputes && disputes.length ? (
        <Pagination
          onPageChange={fetchDisputes as PaginationProps["onPageChange"]}
          total={pageInfo.total}
        />
      ) : null}

      <UpdateDisputeModal
        isOpen={open}
        onClose={() => setOpen(false)}
        setSelectedDisputeId={setSelectedDisputeId}
        selectedDisputeId={selectedDisputeId}
        fetchDisputes={fetchDisputes}
        fetchDisputeCounts={fetchDisputeCounts}
      />
    </main>
  );
};

export default Disputes;
