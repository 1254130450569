import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { toast } from "react-toastify";
import { kycService } from "../../../services/kycService";
import {
  AfriexIDType,
  AfriexImageView,
  AfriexKYCInfo,
  AfriexPerson,
} from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { kycBucketKey, fileService } from "../../../services/fileService";
import moment from "moment";

const useKYCUpdate = (user: AfriexPerson, hasSecondaryID = false) => {
  // image names and links
  const [selfiePhoto, setSelfiePhoto] = useState<string>();
  const [idFront, setIdFront] = useState<string>();
  const [idBack, setIdBack] = useState<string>();
  const [primaryIDType, setPrimaryIDType] = useState<AfriexIDType>();
  const [primaryIDNumber, setPrimaryIDNumber] = useState<string>("");
  const [secondaryIDType, setSecondaryIDType] = useState<AfriexIDType>();
  const [secondaryIDNumber, setSecondaryIDNumber] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [isSelfieChanged, setIsSelfieChanged] = useState(false);
  const [isIDFrontChanged, setIsFrontChanged] = useState(false);
  const [isIDBackChanged, setIsBackChanged] = useState(false);

  // loading states
  const [isIDFrontLoading, setIsIDFrontLoading] = useState(false);
  const [isIDBackLoading, setIsIDBackLoading] = useState(false);
  const [isSelfieLoading, setIsSelfieLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelfiePhoto(user?.kyc?.images?.selfie);
    setIdFront(user?.kyc?.images?.front);
    setIdBack(user?.kyc?.images?.back);
    setDateOfBirth(user?.kyc?.dateOfBirth);
    setPrimaryIDType(user?.kyc?.primary?.idType);
    setPrimaryIDNumber(user?.kyc?.primary?.idNumber as string);
    if (hasSecondaryID) {
      setSecondaryIDType(user?.kyc?.secondary?.idType);
      setSecondaryIDNumber(user?.kyc?.secondary?.idNumber || "");
    }
  }, [hasSecondaryID, user]);

  const _handleError = (message: string) => {
    showErrorMessage("KYC Upload Error:" + message);
  };

  const handleDateOfBirth = useCallback((date: Date | undefined) => {
    const dateStr = date ? moment(date).toISOString() : undefined;
    setDateOfBirth(dateStr);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      if (!dateOfBirth) {
        throw new Error("invalid date of birth provided");
      }

      if (isIDBackChanged && !idFront) {
        throw new Error("no image provided for front of ID");
      }

      if (isIDBackChanged && !idBack) {
        throw new Error("no image provided for back of ID");
      }
      if (isSelfieChanged && !selfiePhoto) {
        throw new Error("no image provided for selfie");
      }

      if (!user?.id) {
        throw new Error("No user in scope");
      }

      const selfiePhotoURL = isSelfieChanged
        ? `${user.id}-selfie.jpg`
        : undefined;
      const idFrontURL = isIDFrontChanged ? `${user.id}-front.jpg` : undefined;
      const idBackURL = isIDBackChanged ? `${user.id}-back.jpg` : undefined;
      const primaryId =
        primaryIDType === AfriexIDType.NinSlip
          ? AfriexIDType.Nin
          : primaryIDType;

      const payload = {
        dateOfBirth,
        primary: {
          idType: primaryId,
          idNumber: primaryIDNumber,
        },
        secondary: hasSecondaryID
          ? {
              idType: secondaryIDType,
              idNumber: secondaryIDNumber,
            }
          : {},
        images: {
          front: idFrontURL,
          back: idBackURL,
          selfie: selfiePhotoURL,
        },
      } as Partial<AfriexKYCInfo>;

      kycService
        .uploadKYC(user.id, payload)
        .then(() => {
          showSuccessMessage("KYC Upload successful");
          window.location.reload();
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            delay: 20,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
      if (e instanceof Error) {
        _handleError(e?.message);
      }
      setIsLoading(false);
    }
  };

  const _setLoading = (idView: AfriexImageView, state: boolean) => {
    if (idView === AfriexImageView.Front) {
      setIsIDFrontLoading(state);
    }

    if (idView === AfriexImageView.Back) {
      setIsIDBackLoading(state);
    }

    if (idView === AfriexImageView.Selfie) {
      setIsSelfieLoading(state);
    }
  };

  const _uploadKYCToS3 = async (
    imageData: Blob,
    name: string,
    idView: AfriexImageView
  ) => {
    try {
      _setLoading(idView, true);
      const { putUrl: preSignedPutUrl } = await fileService.uploadFile(
        name,
        kycBucketKey,
        user?.id
      );
      if (!preSignedPutUrl) {
        throw new Error("Failed to fetch upload link. Retry");
      }
      const response = await fileService.uploadToS3(preSignedPutUrl, imageData);
      if (response.status !== 200) {
        throw new Error(`Upload of ${name} to s3 failed`);
      }
      const previewUrl = await fileService.fetchUploadedFile(
        name,
        kycBucketKey,
        user.id
      );
      if (!previewUrl) {
        throw new Error("Failed to fetch preview link for the image");
      }

      if (idView === AfriexImageView.Front) {
        setIdFront(previewUrl);
        setIsFrontChanged(true);
      }
      if (idView === AfriexImageView.Back) {
        setIdBack(previewUrl);
        setIsBackChanged(true);
      }
      if (idView === AfriexImageView.Selfie) {
        setSelfiePhoto(previewUrl);
        setIsSelfieChanged(true);
      }

      _setLoading(idView, false);
    } catch (e) {
      if (e instanceof Error) {
        _handleError(e?.message);
      }
      _setLoading(idView, false);
    }
  };

  const handleFile = async (
    { target }: ChangeEvent<HTMLInputElement>,
    idView: AfriexImageView
  ) => {
    if (target.files && target.files.length > 0) {
      const file = target.files?.[0];
      const name = `${user.id}-${idView}.jpg`;
      _uploadKYCToS3(file, name, idView);
    } else {
      console.warn("File input is empty");
    }
  };

  return {
    handleSubmit,
    handleFile,
    handleDateOfBirth,
    primaryIDType,
    setPrimaryIDType,
    primaryIDNumber,
    setPrimaryIDNumber,
    secondaryIDType,
    setSecondaryIDType,
    secondaryIDNumber,
    setSecondaryIDNumber,
    dateOfBirth,
    selfiePhoto,
    setSelfiePhoto,
    idFront,
    setIdFront,
    idBack,
    setIdBack,
    isIDFrontLoading,
    isIDBackLoading,
    isSelfieLoading,
    isLoading,
  };
};

export default useKYCUpdate;
