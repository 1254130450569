import { useParams } from "react-router-dom";
import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import Summary from "./Summary";
import useCountryUpdate from "./useCountryUpdate";
import { useEffect } from "react";
import ReferralSettings from "./ReferralSettings";

const Details = () => {
  const params = useParams();
  const countryCode = params?.countryCode ?? "";
  const { isLoading, item, handleUpdate, fetchItem } = useCountryUpdate();

  useEffect(() => {
    if (countryCode) {
      fetchItem(countryCode);
    }
  }, [countryCode, fetchItem]);

  const tabs: TabItem[] = [
    {
      id: "details",
      title: "General",
      content: <Summary item={item} />,
    },

    {
      id: "users",
      title: `Referral Settings`,
      content: <ReferralSettings handleSave={handleUpdate} item={item} />,
    },
  ];
  return (
    <div>
      <Header title={item?.name ?? ""} />
      <Tabs key={isLoading?.toString()} tabs={tabs} queryKey={"tab"} />
    </div>
  );
};
export default Details;
