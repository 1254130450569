import { useState } from "react";
import { UserGroupIcon } from "@heroicons/react/solid";
import countries from "../../constants/countries";
import Badge from "../common/Badge";
import { CustomInput, CustomTextArea } from "../common/Input";
import { toast } from "react-toastify";
import {
  sendBulkPushNotification,
  sendSinglePushNotification,
} from "../../api/notification";
import React from "react";
import { SupportedCountryCodes } from "../../types";
import Button from "../common/Button";
import ConfirmationModal from "../CustomModal/ConfirmationModal";

const countryOptions = [{ key: "All Users", value: "" }];
Object.entries(countries).forEach((entry) =>
  countryOptions.push({
    key: `${entry[0]} ${entry[1]}`,
    value: entry[0],
  })
);

export default function PushNotificationForm() {
  const [country, setCountry] = useState<SupportedCountryCodes>();
  const [action, setAction] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notificationDetails, setNotificationDetails] = useState({
    email: "",
    title: "",
    message: "",
  });

  const handleActionChange = ({ target }: { target: HTMLInputElement }) => {
    setAction(target.value);
  };

  const onSelectCountry = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.target as HTMLButtonElement;
    setCountry(button.name as SupportedCountryCodes);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNotificationDetails({
      ...notificationDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const sendNotification = async () => {
    try {
      setLoading(true);
      if (action === "masspush") {
        const response = await sendBulkPushNotification({
          message: notificationDetails?.message,
          title: notificationDetails?.title,
          countries: country,
        });
        if (response) {
          setLoading(false);
          setOpenModal(false);
          toast.success("Notification Sent");
        }
      } else {
        const response = await sendSinglePushNotification({
          email: notificationDetails?.email,
          message: notificationDetails?.message,
          title: notificationDetails?.title,
        });
        if (response) {
          setLoading(false);
          setOpenModal(false);
          toast.success("Notification Sent");
        }
      }
    } catch (err) {
      let errorMessage = "Unknown Error. Contact Admin!";
      if (typeof err === "object" && err !== null) {
        const error = err as {
          response?: { data?: { message?: string } };
          message?: string;
        };
        errorMessage =
          error.response?.data?.message || error.message || errorMessage;
      }
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-center mt-10 px-6">
        <div
          className={`flex flex-col ${action === "masspush" ? "w-9/12" : "w-7/12"}`}
        >
          <div className="mb-4">
            <span>Send To:</span>
            <span className="mx-4">
              <input
                onChange={handleActionChange}
                type="radio"
                id="masspush"
                value="masspush"
                name="action"
                checked={action === "masspush"}
              />
              <label className="ml-1" htmlFor="masspush">
                Mass Push
              </label>
            </span>

            <span className="mx-4">
              <input
                onChange={handleActionChange}
                type="radio"
                id="individual"
                value=""
                name="action"
                checked={action === ""}
              />

              <label className="ml-1" htmlFor="individual">
                Individual
              </label>
            </span>
          </div>

          <form
            onSubmit={handleSubmit}
            className="bg-white border-2 border-gray-200 rounded-md p-6"
          >
            <div className="flex flex-col gap-6">
              {/* Email */}
              {action !== "masspush" && (
                <CustomInput
                  label="Email"
                  type="email"
                  required
                  name="email"
                  value={notificationDetails.email}
                  onChange={handleInputChange}
                  className="w-full"
                />
              )}

              {/* Country */}
              {action && (
                <div>
                  <div>Select Country</div>
                  <div>
                    {countryOptions.map(({ key, value }) => (
                      <Badge
                        key={value}
                        className="mx-1 my-2 px-2"
                        colorScheme={country === value ? "indigo" : ""}
                      >
                        <button
                          className="hover:bg-indigo-200 inline-flex"
                          name={value}
                          type="button"
                          onClick={onSelectCountry}
                        >
                          {key}
                          {value === "" && (
                            <UserGroupIcon className="w-4 h-4 text-indigo-700 ml-1" />
                          )}
                        </button>
                      </Badge>
                    ))}
                  </div>
                </div>
              )}

              {/* Title */}
              <CustomInput
                label="Title"
                type="text"
                required
                name="title"
                value={notificationDetails.title}
                onChange={handleInputChange}
                className="w-full"
              />

              {/* Message */}
              <CustomTextArea
                label="Message"
                name="message"
                required
                onChange={handleInputChange}
                value={notificationDetails.message}
                aria-rowspan={4}
                type="text"
                className="w-full isFull"
                placeholder="Enter Message..."
              />

              <Button
                type="submit"
                colorScheme="cyan"
                variant="solid"
                className="h-10"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>

      <ConfirmationModal
        isOpen={openModal}
        label={
          <>
            <h3 className="font-bold text-center text-gray-900">
              Are you sure you want to send this Push Notification?
            </h3>
          </>
        }
        cancelAction={() => setOpenModal(false)}
        cancelActionLabel="No"
        confirmAction={sendNotification}
        confirmActionLabel="Yes"
        loadingLabel="Sending..."
        loading={loading}
        title=""
        width="w-2/6"
      >
        <div className="flex flex-col mx-5">
          <div className="pt-5 text-sm">
            <span className="font-semibold text-cyan-600">Title: </span>
            {notificationDetails.title}
          </div>
          <div className="pt-5 text-sm">
            <span className="font-semibold text-cyan-600">Message: </span>
            {notificationDetails.message}
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
}
