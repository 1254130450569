import { ReactElement } from "react";
import { AfriexVirtualAccount } from "../../../types";
import * as formatDate from "../../../utils/dateFormatter";
import countries from "../../../constants/countries";
import { capitalizeWords } from "../../../helpers/dashboard";

interface VirtualAccountInfoProps {
  data: AfriexVirtualAccount;
}

const getFieldsToDisplay = (data: VirtualAccountInfoProps["data"]) => {
  const flag = countries[data.info.country];
  return [
    { "Bank Name": data.info.bankName },
    { "Account Name": data.info.accountName },
    { "Account Number": data.info.accountNumber },
    { "Account Phone": data.info.accountPhone },
    { "Routing Number": data.info.routingNumber },
    { "Sort Code": data.info.sortCode },
    { "Swift Code": data.info.swiftCode },
    { Processor: data.processor },
    { Status: capitalizeWords(data.status) },
    { Country: `${data.info.country} ${flag}` },
    { Currency: data.info.currency },
    { "Allowed Actions": data.capabilities.join(", ") },
    {
      "Account Created": formatDate.DDMonYYYY(data.createdAt?.toString() ?? ""),
    },
  ];
};

const VirtualAccountInfo = ({
  data,
}: VirtualAccountInfoProps): ReactElement => {
  return (
    <div>
      {getFieldsToDisplay(data)?.map((field, idx) => {
        const key = Object.keys(field)[0];
        const value = Object.values(field)[0];

        if (!value || value?.length === 0) {
          return null;
        }

        return (
          <div
            key={idx}
            className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between"
          >
            <p className="text-slate-500 text-sm">{key}</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">{value}</p>
          </div>
        );
      })}
    </div>
  );
};

export default VirtualAccountInfo;
