import React, { useMemo, useState } from "react";
import CustomModal from ".";
import { Button, CustomSelect } from "..";
import { isEmpty } from "lodash";
import ConfirmationModal from "./ConfirmationModal";
import { AfriexRoles } from "../../types";
import { getAdminRoleOptions } from "../../constants/formatters";
import { ExclamationIcon } from "@heroicons/react/outline";
import { userService } from "../../services/userService";
import { toast } from "react-toastify";
import { UserModalProps } from "../DeactivateAccount/types";

const UpdateRoleModal: React.FC<React.PropsWithChildren<UserModalProps>> = ({
  isOpen,
  onClose,
  user,
  defaultRole,
}) => {
  const afriexUser = !isEmpty(user) ? user : null;
  const userName = afriexUser?.name.fullName;
  const [afriexRole, setAfriexRole] = useState<AfriexRoles>(
    defaultRole || AfriexRoles.USER
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const adminRoleOptions = useMemo(
    () =>
      getAdminRoleOptions([AfriexRoles.USER, AfriexRoles.ENGINEERING_MANAGER]),
    []
  );

  const onDismiss = () => {
    onClose?.();
    setIsConfirmModalOpen(false);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const updateRole = async () => {
    setLoading(!loading);
    try {
      await userService.updateUserRole(user?.id, afriexRole as AfriexRoles);
      toast.success(`Successfully updated role for ${userName}`);
      onDismiss();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(`Failed to update role for ${userName}`);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width="w-2/6"
        closeOnOutsideClick
        title={`Update role for ${userName}`}
      >
        <p className="flex gap-2 justify-center">
          <ExclamationIcon className="w-6 h-6 text-failed-500" /> You are about
          to update role for {userName}.
        </p>

        <div className="mt-6">
          <CustomSelect
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setAfriexRole(e.target.value as AfriexRoles)
            }
            value={afriexRole}
            name="role"
            options={adminRoleOptions}
            placeholder="Select Role"
          />
        </div>

        <div className="flex justify-end mt-6">
          <Button
            colorScheme="red"
            onClick={openConfirmModal}
            className="h-10"
            disabled={afriexRole.length === 0}
          >
            Continue
          </Button>
        </div>
      </CustomModal>

      {!isEmpty(user) && (
        <ConfirmationModal
          isOpen={isConfirmModalOpen}
          label={`Are you sure you want to update role for ${userName}`}
          cancelAction={onDismiss}
          cancelActionLabel="No"
          confirmAction={updateRole}
          confirmActionLabel="Yes"
          loadingLabel="Updating..."
          loading={loading}
          title=""
        />
      )}
    </React.Fragment>
  );
};

export default UpdateRoleModal;
