import { FeePaymentMethod } from "./type";
import * as Yup from "yup";
import { isEmpty, isNumber } from "lodash";
import { TransactionTypes } from "../../types";

export const baseFeeSchema = Yup.object()
  .shape({
    baseValue: Yup.number()
      .when("percentValue", {
        is: (percentValue: string) => isNumber(percentValue),
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("Fixed value required"),
      })
      .optional(),
    percentValue: Yup.number().optional(),
    type: Yup.string().required().equals(["default"]).required(),
  })
  .noUnknown(true);

export const overrideFeeSchema = Yup.object().shape({
  baseValue: Yup.number()
    .when("percentValue", {
      is: (percentValue: string) => isNumber(percentValue),
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.required("Fixed or Percentage value required"),
    })
    .optional(),
  percentValue: Yup.number().optional(),

  limit: Yup.number().when("limitType", {
    is: (limitType: string | undefined) =>
      ["above", "below"].includes(limitType?.toLowerCase() ?? ""),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  limitType: Yup.string().oneOf(["above", "below"]).optional(),
  paymentMethod: Yup.string().when(["currencyPair", "limitType"], {
    is: (currencyPair: string, limitType: string) =>
      isEmpty(currencyPair) && isEmpty(limitType),
    then: (schema) =>
      schema.required(
        "Either currency pair or LimitType, or payment method must be set."
      ),
    otherwise: (schema) =>
      schema.oneOf(Object.values(FeePaymentMethod)).optional(),
  }),
  currencyPair: Yup.lazy(() => {
    return Yup.string().when(["paymentMethod", "limitType"], {
      is: (method: string, limitType: string) =>
        isEmpty(method) && isEmpty(limitType),
      then: (schema) =>
        schema
          .notOneOf(["*:*", ""])
          .required(
            "Either currency pair or LimitType or payment method must be set."
          ),
      otherwise: (schema) => schema.optional(),
    });
  }),
  type: Yup.string().equals(["override"]).required(),
  transactionType: Yup.string()
    .oneOf(
      Object.values([
        TransactionTypes.DEPOSIT,
        TransactionTypes.WITHDRAW,
        TransactionTypes.SWAP,
        TransactionTypes.E2E,
        TransactionTypes.VIRTUAL_CARD_LOAD,
        TransactionTypes.VIRTUAL_CARD_SPEND,
        TransactionTypes.VIRTUAL_CARD_UNLOAD,
        TransactionTypes.ACH,
      ])
    )
    .required(),
});
