export const transformCycleToAmounts = (
  cycle: number[],
  ratesMatrix: number[][]
) => {
  const amounts: number[] = [];
  for (let i = 1; i < cycle.length; i += 1) {
    const rate = ratesMatrix[cycle[i - 1]][cycle[i]];
    amounts.push(rate);
  }
  return amounts;
};
