import { AfriexPerson } from "../../../types";
import { camelToTitle } from "../../../utils/camelToTitle";

type KYCIDSecondaryProps = {
  user: AfriexPerson;
};
const KYCIDSecondary = ({ user }: KYCIDSecondaryProps) => {
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Secondary ID".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex flex-col bg-white rounded-lg w-full px-5 py-5 gap-5 border border-gray-200">
        <div className="flex gap-2">
          <div className="flex items-start gap-2 mr-3">{/* Front Image */}</div>
          <div className="flex items-start gap-2">{/* Back Image */}</div>
        </div>

        <div className="bg-white rounded-lg w-full">
          <div>
            {/* ID Type */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">ID Type</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.secondary?.idType?.toUpperCase() ?? "-"}
              </p>
            </div>

            {/* ID Number */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">ID Number</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.secondary?.idNumber ?? "-"}
              </p>
            </div>

            {/* Verification Status */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Verification</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {camelToTitle(user?.kyc?.secondary?.status ?? "-")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCIDSecondary;
