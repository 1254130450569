import { ReactElement } from "react";
import { SupportedCurrencies } from "../../types";
import { SelectProps } from "../common/Input";
import { CustomSelect } from "..";
interface CurrencyPickersProps extends Omit<SelectProps, "options"> {
  options?: { key: string; value: string }[];
}

export const mapOptionsToList = (options: SupportedCurrencies[]) =>
  options.map((currency: SupportedCurrencies) => ({
    value: currency,
    key: currency,
  }));

const CurrencyPicker = (props: CurrencyPickersProps): ReactElement => {
  const options = mapOptionsToList(Object.values(SupportedCurrencies));
  return <CustomSelect {...props} options={options} />;
};
export default CurrencyPicker;
