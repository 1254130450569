import { Button } from "../../../components";
import { UserActiveCard } from "./types";

const UserCardListTable = ({
  activeCardList,
  userId,
  updateCardBankRegulatedStatus,
}: {
  activeCardList?: UserActiveCard[];
  userId: string;
  updateCardBankRegulatedStatus: any;
}) => (
  <div className="flex gap-4 flex-wrap">
    {(activeCardList ?? []).map((item: UserActiveCard) => (
      <div
        key={item?.id}
        className="w-80 h-48 bg-white shadow-md rounded-lg overflow-hidden flex flex-col p-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white"
      >
        {/* Brand */}
        <div className="flex gap-2 justify-between items-center mb-2">
          <p className="text-sm">Brand</p>
          <p className="font-semibold">{item?.info?.brand ?? "N/A"}</p>
        </div>

        <div className="flex gap-2 justify-between items-center mb-2">
          <p className="text-sm">Bank</p>
          <p
            className="font-semibold truncate"
            title={item?.info?.issuer ?? "N/A"}
          >
            {item?.info?.issuer ?? "N/A"}
          </p>
        </div>

        {/* Last 4 Digits */}
        <div className="flex gap-2 justify-between items-center mb-2">
          <p className="text-sm">Last 4 Digits</p>
          <p className="font-semibold">****{item?.info?.last4 ?? ""}</p>
        </div>

        {/* Is Bank Regulated */}
        <div className="flex gap-2 justify-between items-center mb-2">
          <p className="text-sm">Is Bank Regulated</p>
          <div className="flex items-center gap-2 font-semibold">
            {Boolean(item?.info?.isRegulated)?.toString()?.toUpperCase()}
            <Button
              variant="unStyled"
              className="bg-white text-blue-600 hover:bg-blue-600 hover:text-white px-2 py-1"
              onClick={() =>
                updateCardBankRegulatedStatus(
                  userId,
                  item?.id,
                  !item?.info?.isRegulated
                )
              }
            >
              Toggle
            </Button>
          </div>
        </div>

        {/* Country */}
        <div className="flex gap-2 justify-between items-center">
          <p className="text-sm">Country</p>
          <p className="font-semibold">{item?.info?.country ?? "N/A"}</p>
        </div>
      </div>
    ))}
  </div>
);

export default UserCardListTable;
