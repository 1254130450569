import React from "react";
import Spinner from "../../assets/svg/Spinner";

type ButtonProps = {
  children: JSX.Element | JSX.Element[] | string | React.ReactNode;
  variant?: "solid" | "outline" | "unStyled" | "ghost";
  onClick?: () => void;
  colorScheme?: string;
  isLoading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  className?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  [rest: string]: any;
};

function Button({
  isLoading = false,
  disabled = false,
  loadingText,
  colorScheme,
  children,
  onClick,
  className = "",
  leftIcon,
  rightIcon,
  variant = "solid",
  ...rest
}: ButtonProps): JSX.Element {
  const classNames = {
    unStyled:
      "bg-none color-inherit border-none padding-0 font-inherit outline-none text-base disabled:text-gray-300",
    outline: `bg-none py-2 px-4 text-${
      colorScheme || "indigo"
    }-700 border border-slate-300 hover:bg-${
      colorScheme || "indigo"
    }-600 active:text-white active:bg-${
      colorScheme || "indigo"
    }-1000 hover:text-white disabled:opacity-50 disabled:text-slate-200 transition-all ease-in-out duration-300 text-base disabled:bg-gray-100 disabled:text-gray-400`,

    solid: `bg-${colorScheme}-${
      colorScheme === "cyan" ? "700" : "600"
    } hover:bg-${colorScheme}-600 active:bg-${colorScheme}-${
      colorScheme === "cyan" ? "700" : "600"
    } text-white py-2 px-4 font-medium transition-all ease-in-out duration-300 text-base disabled:bg-gray-100 disabled:text-gray-400`,
    ghost:
      "bg-none color-inherit text-cyan-700 disabled:opacity-50 py-2 px-4 active:bg-indigo-200 transition-all ease-in-out duration-300 text-base disabled:bg-gray-100 disabled:text-gray-400",
  };
  return (
    <button
      className={`rounded-lg font-inherit ${classNames[variant]} ${className} ${disabled ? "cursor-not-allowed" : ""}`}
      disabled={isLoading || disabled}
      onClick={onClick}
      {...rest}
    >
      {
        <div className="flex justify-center items-center">
          {isLoading ? (
            <>
              <Spinner size={5} /> {loadingText}
            </>
          ) : (
            <>
              {leftIcon && <span className="mr-2">{leftIcon}</span>}
              {children}
              {rightIcon && <span className="ml-2">{rightIcon}</span>}
            </>
          )}
        </div>
      }
    </button>
  );
}

export default Button;
