import { Pagination } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexRateInfo,
} from "../../../types";
import { RateInfoProps } from "../types";
import PromoTiersTable from "./PromoTiersTable";
import TierUpdateButton from "./TierUpdateButton";
import useTierList from "./useTierList";

const Tierlist = ({ rateInfo }: Pick<RateInfoProps, "rateInfo">) => {
  const { isLoading, fetchList, total, list } = useTierList();

  return (
    <div className="my-5 mx-4 md:mx-10">
      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER}
      >
        <div className="w-full flex justify-end mb-3">
          <TierUpdateButton rateInfo={rateInfo as AfriexRateInfo} />
        </div>
      </PermissionsProvider>

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <PromoTiersTable items={list} rateInfo={rateInfo as AfriexRateInfo} />
        )}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={false}
        />
      ) : null}
    </div>
  );
};

export default Tierlist;
