import {
  Button,
  CashIcon,
  CurrencyInput,
  CustomSelect,
  CustomToast,
  LedgerModal,
  MinusCircleIcon,
} from "../../../components";
import { ICurrency } from "../../../types/Currency";
import { UserDetailsReturnProps } from "./types";
import useFundUserWallet from "./useFundUserWallet";
import { getLedgerReasonOptions } from "../../../constants/formatters";

const initialResponse = {
  message: "",
  status: "",
};

const CreditsUserWallet = ({ user }: Pick<UserDetailsReturnProps, "user">) => {
  const {
    amount,
    currency,
    loading,
    showModal,
    response,
    action,
    handleAmountChange,
    handleCurrencyChange,
    handleLedgerButtonClick,
    isValidTransaction,
    handleLedger,
    setShowModal,
    setResponse,
    handleSelectReason,
    fundDebitReason,
  } = useFundUserWallet(user, initialResponse);
  return (
    <div className="flex flex-col px-3 pt-5 gap-3 w-4/12">
      <h3 className="text-sm font-semibold text-gray-600">
        Credit / Debit User
      </h3>

      <div className="flex flex-col gap-6">
        <CurrencyInput
          onAmountChange={handleAmountChange}
          onCurrencyChange={handleCurrencyChange}
          selectedCurrency={currency}
          amount={amount}
          name="amount"
        />

        <CustomSelect
          onChange={handleSelectReason}
          value={fundDebitReason}
          name="fundDebitReason"
          options={getLedgerReasonOptions()}
          placeholder="Select a Reason"
          label="Attach Reason"
        />

        {/* Credit - Debit Button */}
        <div className="flex justify-between space-x-4">
          <Button
            variant="outline"
            className="border-indigo-300"
            name="credit"
            disabled={isValidTransaction()}
            onClick={() => handleLedgerButtonClick("credit")}
            colorScheme="green"
            leftIcon={<CashIcon className="h-5 w-5" />}
          >
            Credit
          </Button>
          <Button
            variant="outline"
            name="debit"
            className="border-indigo-300"
            disabled={isValidTransaction()}
            onClick={() => handleLedgerButtonClick("debit")}
            colorScheme="red"
            leftIcon={<MinusCircleIcon className="h-5 w-5" />}
          >
            Debit
          </Button>
        </div>
      </div>

      {response.status && (
        <CustomToast
          variant={response.status}
          message={response.message}
          onClose={() => setResponse(initialResponse)}
        />
      )}

      {showModal && (
        <LedgerModal
          loading={loading}
          currency={currency as ICurrency}
          amount={amount}
          action={action}
          confirmFunction={handleLedger}
          onClose={() => setShowModal(false)}
          user={user}
          reason={fundDebitReason}
        />
      )}
    </div>
  );
};

export default CreditsUserWallet;
