export enum SupportedCountryCodes {
  NG = "NG",
  GB = "GB",
  US = "US",
  CA = "CA",
  KE = "KE",
  GH = "GH",
  UG = "UG",
  CM = "CM",
  ET = "ET",
  HT = "HT",
  AT = "AT",
  BE = "BE",
  HR = "HR",
  CY = "CY",
  EE = "EE",
  FI = "FI",
  FR = "FR",
  DE = "DE",
  GR = "GR",
  IE = "IE",
  IT = "IT",
  LV = "LV",
  LT = "LT",
  LU = "LU",
  MT = "MT",
  NL = "NL",
  PT = "PT",
  SK = "SK",
  SI = "SI",
  ES = "ES",
}
