import { DashCardConfig } from "../../config/menuConfig";
import { formatMonetaryValue } from "../../helpers/dashboard";

interface DashCardType extends DashCardConfig {
  value: string | number;
}

const DashboardCard: React.FC<DashCardType> = ({ label, value, isMoney }) => {
  const formattedValue =
    typeof value === "number" ? formatMonetaryValue(value, isMoney) : "-";
  return (
    <div className="flex flex-col bg-white p-3 w-48 h-20 gap-2 border border-gray-300 rounded-md cursor-pointer">
      <h3 className="text-sm text-gray-500">{label}</h3>
      <p className="text-xl text-gray-700 font-semibold">{formattedValue}</p>
    </div>
  );
};

export default DashboardCard;
