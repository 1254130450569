import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./hooks/useAuth";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import "./assets/style/global.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          hideProgressBar
          theme="colored"
        />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
