import CSVimage from "../../assets/images/sample-bulk-payment-csv.png";
import { Header } from "../../components";
import Button from "../../components/common/Button";
import { useEffect, useState } from "react";
import { BulkUploadPaymentModal } from "../../components/BulkPayment/BulkPaymentModal";
import { getBulkPayments } from "../../api/bulkUpload";
import { IBulkUploadPayment } from "../../types/BulkPayment";
import BulkUploadPaymentTable from "../../components/BulkPayment/Table";
import { toast } from "react-toastify";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import React from "react";
import { AfriexActions, AfriexPermissions } from "../../types";

export default function BulkPaymentPage(): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [BulkUploadPayments, setBulkUploadPayments] = useState<
    IBulkUploadPayment[]
  >([]);

  const loadBulkPayments = async () => {
    try {
      setLoading(true);
      const response = await getBulkPayments();
      response && setLoading(false);
      setBulkUploadPayments(response.data);
    } catch (err: any) {
      const errorMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message;
      toast.error(errorMessage || "Unknown Error. Contact Admin!");
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBulkPayments();
  }, []);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
      <Header title="Bulk Payment" />
      <div className="w-full flex flex-col mt-10 pr-5 md:pr-10">
        <React.Fragment>
          <PermissionsProvider
            permission={AfriexPermissions.BULK_PAYMENTS_PAGE}
            action={AfriexActions.CLICK_BULK_PAYMENT_UPLOAD}
          >
            <p className="mb-3">
              Upload a CSV file in the format provided in the sample image below
            </p>
            <Button
              onClick={toggleModal}
              variant="solid"
              colorScheme="indigo"
              className="text-white px-4 py-2 rounded"
              style={{ width: "auto" }}
            >
              Upload CSV file
            </Button>
          </PermissionsProvider>
        </React.Fragment>
        <img src={CSVimage} className="max-w-full mx-auto" alt="csv" />
      </div>

      <div className=" px-5 py-10 overflow-x-auto">
        <React.Fragment>
          <PermissionsProvider
            permission={AfriexPermissions.BULK_PAYMENTS_PAGE}
            action={AfriexActions.VIEW_RECENT_BULK_PAYMENTS}
          >
            <h2 className="font-bold mb-5">Recent Bulk Payments</h2>
            <BulkUploadPaymentTable
              loading={loading}
              data={BulkUploadPayments}
            />
          </PermissionsProvider>
        </React.Fragment>
      </div>

      {openModal && (
        <BulkUploadPaymentModal
          onClose={toggleModal}
          reloadFunc={loadBulkPayments}
        />
      )}
    </>
  );
}
