import { useCallback, useEffect, useState } from "react";
import { referralService } from "../../../services/referralService";
import { AfriexReferralTier } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";

type TierDetailsReturnProps = {
  isLoading: boolean;
  tier: AfriexReferralTier;
};

const useTierDetails = (tierId: string): TierDetailsReturnProps => {
  const [isLoading, setIsLoading] = useState(true);
  const [tier, setTier] = useState<AfriexReferralTier>(
    {} as AfriexReferralTier
  );
  const fetchTierDetails = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const tierItem = await referralService.getTier(id);
      setTier(tierItem);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showErrorMessage("Tier details could not be fetched:" + err);
    }
  }, []);
  useEffect(() => {
    fetchTierDetails(tierId);
  }, [fetchTierDetails, tierId]);

  return { isLoading, tier };
};
export default useTierDetails;
