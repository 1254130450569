import { useEffect, useRef, RefObject, useCallback } from "react";

/**
 * Custom hook to detect clicks outside a specified element and trigger a close function.
 *
 * @param close - Function to be called when an outside click is detected.
 * @returns A ref to be attached to the target element.
 */
const useCloseOnOutsideClick = <T extends HTMLElement>(
  close: () => void
): RefObject<T> => {
  const node = useRef<T>(null);

  const closeHandler = useCallback(
    (evt: MouseEvent) => {
      if (node.current && !node.current.contains(evt.target as Node)) {
        close?.();
      }
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeHandler);
    return () => {
      document.removeEventListener("mousedown", closeHandler);
    };
  }, [close, closeHandler]);

  return node;
};

export default useCloseOnOutsideClick;
