import axios from "./axios";

// description: upload csv file for payment
export async function makeBulkUploadPayment(formData: any) {
  const response = await axios.post(`bulk-upload-payment`, formData, {
    headers: { "content-type": "multipart/form-data" },
  });
  return response.data;
}

export async function getBulkPayments() {
  const response = await axios.post(`/bulk-upload-payment`, {
    action: "retrieveBulkPayments",
  });
  return response.data;
}
