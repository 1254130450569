import { useCallback, useEffect, useState } from "react";
import { paymentMethodService } from "../../../services/paymentMethodService";
import { userService } from "../../../services/userService";
import { AfriexPerson, AfriexUserBalances } from "../../../types";
import {
  AfriexSecurityNote,
  AfriexSecurityNotesQueryParams,
} from "../../../types/Notes";
import { logger } from "../../../utils/logger";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { UserActiveCard } from "./types";

const useUserDetails = (userId: string) => {
  const [userDetails, setUserDetails] = useState<AfriexPerson>(
    {} as AfriexPerson
  );
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false);
  const [isLoadingWalletBalances, setIsLoadingWalletBalances] =
    useState<boolean>(false);
  const [walletBalances, setWalletBalances] = useState<AfriexUserBalances>({});
  const [securityNotes, setSecurityNotes] = useState<AfriexSecurityNote[]>([]);
  const [isLoadingSecurityNotes, setIsLoadingSecurityNotes] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit] = useState<number>(6); // Set your limit here
  const [hasMoreNotes, setHasMoreNotes] = useState(true);
  const [activeCards, setActiveCards] = useState<UserActiveCard[]>([]);

  const [isAddingSecurityNote, setIsAddingSecurityNote] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showComplianceModal, setShowComplianceModal] =
    useState<boolean>(false);
  const toggleModal = () => setShowModal(!showModal);
  const toggleComplianceModal = () =>
    setShowComplianceModal(!showComplianceModal);

  const fetchWalletBalances = useCallback(async () => {
    try {
      if (!userId) {
        return;
      }
      setIsLoadingWalletBalances(true);
      const balances = await userService.getUserWalletBalance(userId);
      setWalletBalances(balances);
      balances && setIsLoadingWalletBalances(false);
    } catch (err: any) {
      logger.log("fetching user wallet balances error:", err);
      const errorMessage = err.message
        ? err.message
        : err.response?.data?.message;
      showErrorMessage("Failed to fetch user balances:" + errorMessage);
      setIsLoadingWalletBalances(false);
    }
  }, [userId]);

  const fetchSecurityNotes = useCallback(
    async (params?: AfriexSecurityNotesQueryParams) => {
      try {
        if (!userId) return;

        if (params?.page === 0) {
          setIsLoadingSecurityNotes(true);
        } else {
          setIsFetchingMore(true);
        }
        const response = await userService.getUserSecurityNotes(userId, {
          ...params,
        });

        setSecurityNotes((prevNotes) => [...prevNotes, ...response.data]);

        if (response.data.length < (params?.limit ?? limit)) {
          setHasMoreNotes(false);
        }
      } catch (err: any) {
        logger.error("fetching user security notes error:", err);
        const errorMessage = err.response?.data.message
          ? err.response?.data.message
          : err.message;
        showErrorMessage("Failed to fetch user security notes:" + errorMessage);
        setIsLoadingSecurityNotes(false);
      } finally {
        setIsLoadingSecurityNotes(false);
        setIsFetchingMore(false);
      }
    },
    [userId, limit]
  );

  const addSecurityNote = useCallback(
    async (securityNoteInput: string): Promise<void> => {
      try {
        if (!userId) {
          return;
        }
        setIsAddingSecurityNote(true);
        const response = await userService.createSecurityNote({
          note: securityNoteInput,
          user: userId,
        });
        if (response?.adminInfo) {
          setSecurityNotes((prevNotes) => [response, ...prevNotes]);
          setTimeout(() => {
            setIsAddingSecurityNote(false);
          }, 2000);
        }
      } catch (err: any) {
        const errorMessage = err.response?.data.message
          ? err.response?.data.message
          : err.message;
        showErrorMessage("Failed to add user security notes:" + errorMessage);
        setIsAddingSecurityNote(false);
      }
    },
    [userId]
  );

  const fetchUserDetails = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        return;
      }
      setIsLoadingUserDetails(true);
      const response = await userService.getUser(userId);
      setUserDetails(response);
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage("Failed to fetch user details:" + errorMessage);
    } finally {
      setIsLoadingUserDetails(false);
    }
  }, [userId]);

  const fetchUserActiveCardList = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        return;
      }
      setIsLoadingUserDetails(true);
      const response = await paymentMethodService.getUserActiveCards(userId);
      setActiveCards(response);
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      logger.log("Failed to fetch user details:" + errorMessage);
    } finally {
      setIsLoadingUserDetails(false);
    }
  }, [userId]);

  const updateCardBankRegulatedStatus = async (
    userId: string,
    paymentMethodId: string,
    isRegulated: boolean
  ): Promise<void> => {
    try {
      setIsLoadingUserDetails(true);
      await paymentMethodService.updateCardBankRegulatedStatus(
        userId,
        paymentMethodId,
        isRegulated
      );
      window.location.reload();
      setIsLoadingUserDetails(false);
    } catch (err: any) {
      setIsLoadingUserDetails(true);
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage(
        "Failed to update card regulated status:" + errorMessage
      );
      logger.log("Failed to uodate card regulated status:" + errorMessage);
    }
  };

  const loadMoreSecurityNotes = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    fetchSecurityNotes({ page, limit });
  }, [page, fetchSecurityNotes, limit]);

  useEffect(() => {
    fetchUserDetails();
    fetchWalletBalances();
    fetchUserActiveCardList();
  }, [fetchUserDetails, fetchWalletBalances, fetchUserActiveCardList]);

  return {
    addSecurityNote,
    isAddingSecurityNote,
    isLoadingWalletBalances,
    isLoadingSecurityNotes,
    isLoadingUserDetails,
    securityNotes,
    loadMoreSecurityNotes,
    hasMoreNotes,
    isFetchingMore,
    showModal,
    toggleModal,
    fetchUserDetails,
    user: userDetails,
    walletBalances,
    activeCards,
    updateCardBankRegulatedStatus,
    toggleComplianceModal,
    showComplianceModal,
  };
};

export default useUserDetails;
