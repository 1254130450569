import { IRates } from "../types/Rate";

export function convertCurrency({
  from,
  to,
  value,
  rates,
}: {
  from: string;
  to: string;
  value: number;
  rates: any;
}) {
  const exchangeRate = getExchangeRate(from, to, rates);
  return value * Number(exchangeRate);
}

function getExchangeRate(from: string, to: string, rates: IRates) {
  return rates[from][to];
}
