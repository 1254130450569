import { useState } from "react";
import {
  Button,
  ChevronRightIcon,
  MinusIcon,
  PlusIcon,
  Td,
  Tr,
} from "../../../components";
import { mapCountryCodeToFlag } from "../../../constants/countries";
import { ProcessorResponse } from "../../../services/processorService";
import { AfriexCountry, SupportedCurrencies } from "../../../types";
import Summary from "./Summary";
import { extractFirstProcessor } from "./utils";

type ListItemProps = {
  item: AfriexCountry;
  corridors: AfriexCountry[];
  processors: ProcessorResponse;
  showProcessorUpdateForm: (currency: SupportedCurrencies) => void;
  index?: number;
};
const ListItem = ({
  corridors,
  item,
  showProcessorUpdateForm,
  processors,
}: ListItemProps) => {
  const className = "text-sm text-black pl-3";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const currencyCode = item?.currency?.toUpperCase() as SupportedCurrencies;
  return (
    <>
      <Tr className="hover:bg-indigo-200 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <Td className="pl-5">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border border-gray-300 w-5 h-5 flex justify-center rounded-lg hover:bg-sky-50 ${
                isExpanded && "bg-sky-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-indigo-500" />
              ) : (
                <PlusIcon className="text-gray-300 w-4 h-4 hover:text-indigo-500" />
              )}
            </div>
          </Button>
        </Td>

        <Td>
          <div className={className}>
            {currencyCode} {mapCountryCodeToFlag(item?.iso2)}
          </div>
        </Td>
        <Td>
          <div className={className}>
            {extractFirstProcessor(
              processors?.DEPOSIT?.[currencyCode] ?? ""
            )?.toUpperCase() ?? "N/A"}
          </div>
        </Td>
        <Td>
          <div className={className}>
            {extractFirstProcessor(
              processors?.WITHDRAW?.[currencyCode] ?? ""
            )?.toUpperCase() ?? "N/A"}
          </div>
        </Td>

        <Td>
          <div className="w-5 h-5 flex justify-center  text-gray-400 hover:border rounded-lg hover:border-gray-300 hover:bg-sky-50 hover:text-indigo-500">
            <ChevronRightIcon
              className=" w-5 h-5"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        </Td>
      </Tr>
      {isExpanded && (
        <Tr className="pr-5">
          <Td className="p-0 m-0" colspan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-100 w-full">
              <Summary
                item={item}
                processors={processors}
                currencyCode={item.currency as SupportedCurrencies}
                showProcessorUpdateForm={showProcessorUpdateForm}
                corridors={corridors}
              />
            </div>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default ListItem;
