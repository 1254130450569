import { Header } from "../../components";
import PushNotificationForm from "../../components/PushNotifications/PushNotificationForm";

export default function NotificationPage(): JSX.Element {
  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Push Notifications" />
      <PushNotificationForm />
    </div>
  );
}
