const nigerianBankSortCodesAndNipCodes = [
  {
    BANK: "ACCESS BANK",
    SORT_CODE: "044",
    NIP_CODE: "000014",
  },
  {
    BANK: "ACCESS BANK(DIAMOND)",
    SORT_CODE: "063",
    NIP_CODE: "000005",
  },
  {
    BANK: "CELLULANT",
    SORT_CODE: "317",
    NIP_CODE: "100005",
  },
  {
    BANK: "CITIBANK",
    SORT_CODE: "023",
    NIP_CODE: "000009",
  },
  {
    BANK: "ECOBANK",
    SORT_CODE: "050",
    NIP_CODE: "000010",
  },
  {
    BANK: "ENTERPRISE BANK",
    SORT_CODE: "084",
    NIP_CODE: "000019",
  },
  {
    BANK: "FIRST CITY MONUMENT BANK",
    SORT_CODE: "214",
    NIP_CODE: "000003",
  },
  {
    BANK: "FIDELITY BANK",
    SORT_CODE: "070",
    NIP_CODE: "000007",
  },
  {
    BANK: "FIRST BANK",
    SORT_CODE: "011",
    NIP_CODE: "000016",
  },
  {
    BANK: "GUARANTY TRUST BANK",
    SORT_CODE: "058",
    NIP_CODE: "000013",
  },
  {
    BANK: "HERITAGE BANK",
    SORT_CODE: "030",
    NIP_CODE: "000020",
  },
  {
    BANK: "JAIZ BANK",
    SORT_CODE: "301",
    NIP_CODE: "000006",
  },
  {
    BANK: "KEYSTONE BANK",
    SORT_CODE: "082",
    NIP_CODE: "000002",
  },
  {
    BANK: "KUDA MICROFINANCE BANK",
    SORT_CODE: "611",
    NIP_CODE: "090267",
  },
  {
    BANK: "NEW PRUDENTIAL BANK",
    SORT_CODE: "561",
    NIP_CODE: "090108",
  },
  {
    BANK: "OPAY",
    SORT_CODE: "",
    NIP_CODE: "100004",
  },
  {
    BANK: "PAGA",
    SORT_CODE: "327",
    NIP_CODE: "100002",
  },
  {
    BANK: "POLARIS BANK",
    SORT_CODE: "076",
    NIP_CODE: "000008",
  },
  {
    BANK: "PROVIDUS BANK",
    SORT_CODE: "101",
    NIP_CODE: "000023",
  },
  {
    BANK: "RUBIES MICROFINANCE BANK",
    SORT_CODE: "",
    NIP_CODE: "090175",
  },
  {
    BANK: "SPECTRUM MICROFINANCE BANK",
    SORT_CODE: "",
    NIP_CODE: "090436",
  },
  {
    BANK: "STANBIC IBTC BANK",
    SORT_CODE: "221",
    NIP_CODE: "000012",
  },
  {
    BANK: "STANDARD CHARTERED BANK",
    SORT_CODE: "068",
    NIP_CODE: "000021",
  },
  {
    BANK: "STERLING BANK",
    SORT_CODE: "232",
    NIP_CODE: "000001",
  },
  {
    BANK: "UNITED BANK OF AFRICA",
    SORT_CODE: "033",
    NIP_CODE: "000004",
  },
  {
    BANK: "UNION BANK",
    SORT_CODE: "032",
    NIP_CODE: "000018",
  },
  {
    BANK: "UNITY BANK",
    SORT_CODE: "215",
    NIP_CODE: "000011",
  },
  {
    BANK: "VFD MICROFINANCE BANK",
    SORT_CODE: "566",
    NIP_CODE: "090110",
  },
  {
    BANK: "WEMA BANK",
    SORT_CODE: "035",
    NIP_CODE: "000017",
  },
  {
    BANK: "ZENITH BANK",
    SORT_CODE: "057",
    NIP_CODE: "000015",
  },
];

export enum NigerianBankCodeType {
  SORT_CODE = "SORT_CODE",
  NIP_CODE = "NIP_CODE",
}

export const getNigeriaBankName = (code: string): string => {
  const bank = nigerianBankSortCodesAndNipCodes.find(
    (item) => item.SORT_CODE === code || item.NIP_CODE === code
  );
  return bank ? bank.BANK : "";
};
