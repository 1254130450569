import { isEmpty } from "lodash";
import { Button, InfoBox } from "../../../components";
import AppRatesTable from "../App/RatesTable";
import ArbitrageDisplay from "./ArbitrageDisplay";
import RatesUploadForm from "./RatesUploadForm";
import useRatesUpdate from "./useRatesUpdate";
import { AfriexPermissions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexActions } from "../../../types";
import ConfirmationModal from "../../../components/CustomModal/ConfirmationModal";
const RatesUpload = () => {
  const {
    arbitrageResult,
    handleTemplateDownload,
    handleAppRatesUpload,
    appRates,
    tolerance,
    handleToleranceChange,
    isArbitrageChecked,
    isArbitrageDetected,
    handleRatesCancellation,
    handleRatesUpdate,
    isLoading,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    loadingText,
    handleOTCRatesUpload,
    otcRates,
  } = useRatesUpdate();
  const isDisabled =
    !isArbitrageChecked || isArbitrageDetected || isEmpty(appRates);
  return (
    <div>
      <div className="flex justify-center">
        <PermissionsProvider
          permission={AfriexPermissions.RATES_PAGE}
          action={AfriexActions.CLICK_RATES_UPLOAD}
        >
          <RatesUploadForm
            handleAppRatesUpload={handleAppRatesUpload}
            handleTemplateDownload={handleTemplateDownload}
            handleOTCRatesUpload={handleOTCRatesUpload}
          />
        </PermissionsProvider>
      </div>
      <br />

      {loadingText && (
        <InfoBox>
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          {loadingText}
        </InfoBox>
      )}

      {arbitrageResult && isArbitrageChecked && (
        <ArbitrageDisplay
          isOpen={arbitrageResult && isArbitrageChecked}
          arbitrageResult={arbitrageResult}
          rates={appRates}
          tolerance={tolerance}
          onToleranceChange={handleToleranceChange}
        />
      )}

      {!isEmpty(appRates) && !isArbitrageDetected && (
        <section className="flex justify-end mx-5">
          <Button
            onClick={handleRatesCancellation}
            variant="solid"
            colorScheme="gray"
          >
            Clear
          </Button>
        </section>
      )}

      {/* Rates Table */}
      {!isEmpty(appRates) && (
        <div className="table-con overflow-x-auto">
          <h3>App Rates</h3>
          <AppRatesTable rates={appRates} />
        </div>
      )}

      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_UPLOAD_PRODUCTION}
      >
        {!isEmpty(appRates) && (
          <section className="action-wrap mt-5 flex justify-start w-full">
            <Button
              disabled={isDisabled}
              data-tip
              data-for="productionPush"
              onClick={() => setIsConfirmModalOpen(true)}
              variant="solid"
              colorScheme="cyan"
            >
              Push to production
            </Button>
          </section>
        )}
      </PermissionsProvider>

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        label="Are you sure to save these rates?"
        cancelAction={() => setIsConfirmModalOpen(false)}
        cancelActionLabel="No"
        confirmAction={() => handleRatesUpdate(appRates, otcRates)}
        confirmActionLabel="Yes"
        loadingLabel={loadingText}
        loading={isLoading}
        title="Confirm to submit"
      />
    </div>
  );
};

export default RatesUpload;
