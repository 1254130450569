import { useCallback, useState } from "react";
import Papa from "papaparse";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { transformRatesMapToMatrix } from "../../../utils/rates/transformRatesMapToMatrix";
import { isEmpty, isNumber } from "lodash";
import { detectArbitrage } from "../../../utils/rates/detectArbitrage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { ratesService } from "../../../services/ratesService";
import {
  AfriexOTCRate,
  AfriexRates,
  ArbitrageCheckInfo,
  SupportedCurrencies,
} from "../../../types";
import { fileService } from "../../../services/fileService";
import { removeUndefinedKeys } from "../../../utils/rates/removeUndefinedKeys";
import { calculateArbitrageProfit } from "../../../utils/rates/calculateArbitrageProfit";
import { removeEmptyRows } from "../../../constants/formatters";
import { cleanNumber } from "../../../utils/rates/cleanNumber";

const isHeaderRow = (arr: any[]) =>
  Array.isArray(arr) && arr.every((item) => !isNumber(item));
const extractQuotes = (obj: any, currencySymbols: string[]) =>
  Object.entries(obj).reduce((acc: any, [key, value], currentIndex: number) => {
    if (key !== "" && value !== null) {
      const currencySymbol = currencySymbols[currentIndex - 1];
      acc[currencySymbol] = value;
    }
    return acc;
  }, {});

const extractSymbols = (obj: any) =>
  Object.entries(obj).reduce((acc: any, [key, value]) => {
    const symbol = Object.values((value as any[]) ?? [])?.[0];
    if (symbol) {
      acc = [...acc, symbol];
    }
    return acc;
  }, []);

const getMaxProfit = (arbitrageResult: ArbitrageCheckInfo) => {
  if (!arbitrageResult) return 0;
  const maxCycleProfit = Math.max(
    ...(arbitrageResult?.cycles?.map((cycle) =>
      calculateArbitrageProfit(cycle)
    ) ?? [])
  );
  return Math.max(
    arbitrageResult?.pairWiseDiscrepancy?.maxProfit ?? 0,
    maxCycleProfit
  );
};

const useRatesUpdate = () => {
  const toleranceMax = 0.3;
  const [appRates, setAppRates] = useState<any>({});
  const [loadingText, setLoadingText] = useState<string>("");
  const [isArbitrageChecked, setIsArbitrageChecked] = useState<boolean>(false);
  const [arbitrageResult, setArbitrageResult] = useState<ArbitrageCheckInfo>();
  const [tolerance, setTolerance] = useState<number>(0.001);
  const [ratesFile, setRatesFile] = useState<Blob>();
  const [isArbitrageDetected, setIsArbitrageDetected] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ----- OTC Rates States ---
  const [otcRates, setOTCRates] = useState<AfriexOTCRate[]>([]);

  const handleRatesUpdate = useCallback(
    async (
      appRatesForUpload: AfriexRates[],
      otcRatesForUpload: AfriexOTCRate[]
    ) => {
      setIsLoading(true);
      try {
        if (Object.values(appRatesForUpload).length === 0) {
          return showErrorMessage(
            "Rate is empty, make sure the template matches"
          );
        }

        if (Object.values(otcRatesForUpload).length === 0) {
          return showErrorMessage(
            "OTC Rate is empty, make sure the template matches"
          );
        }

        if (!isArbitrageChecked) {
          return showErrorMessage("Check for arbitrage first");
        }

        if (tolerance >= toleranceMax) {
          return showErrorMessage(
            `Tolerance must be less than ${toleranceMax}`
          );
        }

        setLoadingText("Saving rates...");
        const fileName = `rates-${new Date()?.toISOString()}.csv`;
        const bucketKey = "aws.s3Bucket.rate";
        await fileService.uploadFile(fileName, bucketKey, null, ratesFile);

        const payload = {
          appRates: removeUndefinedKeys(appRatesForUpload),
          tolerance,
          arbitrageInfo: arbitrageResult,
          ratesLink: fileName,
          otcRates: removeUndefinedKeys(otcRatesForUpload),
        };
        await ratesService.updateRates(payload);

        setLoadingText("");
        setTolerance(0.001);
        setArbitrageResult(undefined);
        setIsArbitrageChecked(false);
        setIsArbitrageDetected(false);
        showSuccessMessage("Rates saved successfully");
      } catch (e: any) {
        setLoadingText("");
        showErrorMessage(`Save failed:${e.message}`);
      } finally {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
      }
    },
    [isArbitrageChecked, tolerance, ratesFile, arbitrageResult]
  );

  const handleRatesCancellation = useCallback(async () => {
    setAppRates({});
  }, []);

  const handleArbitrageCheck = useCallback(
    async (rates: any, currencySymbols: SupportedCurrencies[]) => {
      if (isEmpty(rates)) {
        showErrorMessage("No rates uploaded yet");
      }
      setLoadingText("Checking for arbitrage...");
      setIsArbitrageChecked(false);
      setArbitrageResult({});
      const ratesMatrix = transformRatesMapToMatrix(rates);
      const checkResults = await detectArbitrage(ratesMatrix, currencySymbols);
      setIsArbitrageChecked(true);
      setLoadingText("");
      const hasNoArbitrage =
        (!checkResults?.cycles || checkResults?.cycles?.length === 0) &&
        (!checkResults?.pairWiseDiscrepancy?.remarks ||
          checkResults?.pairWiseDiscrepancy?.remarks?.length === 0);
      if (hasNoArbitrage) {
        setIsArbitrageDetected(false);
        showSuccessMessage(`No arbitrage detected`);
        setLoadingText("");
        return;
      }
      const maxProfit = getMaxProfit(checkResults);
      setArbitrageResult({ ...checkResults, hasArbitrage: true, maxProfit });
      setIsArbitrageDetected(true);
      showErrorMessage(`Arbitrage detected`);
    },
    []
  );

  const handleToleranceChange = useCallback(
    (event: any) => {
      const toleranceValue = parseFloat(event.target.value);
      if (!toleranceValue || !isNumber(toleranceValue)) return;
      setTolerance(toleranceValue);
      const profitPercentage = arbitrageResult?.maxProfit ?? 0;
      if (profitPercentage > toleranceValue) {
        setIsArbitrageDetected(true);
        setArbitrageResult({ ...(arbitrageResult ?? {}), hasArbitrage: true });
      } else {
        setIsArbitrageDetected(false);
        setArbitrageResult({ ...(arbitrageResult ?? {}), hasArbitrage: false });
      }
    },
    [arbitrageResult]
  );

  const formatCSVToAppRate = useCallback(
    async (csvRows: any) => {
      if (!Array.isArray(csvRows) || csvRows.length === 0) {
        return showErrorMessage("Upload a valid rates csv file to view rates");
      }
      const currencySymbols = extractSymbols(Object.values(csvRows));
      const currencyRates = csvRows.reduce(
        (accumuledResult: any, currentRow: any) => {
          if (isHeaderRow(Object.values(currentRow))) {
            return accumuledResult;
          } else {
            const baseCurrencySymbol = Object.values(currentRow)[0];
            if (!baseCurrencySymbol) {
              return accumuledResult;
            }
            accumuledResult[baseCurrencySymbol as string] = extractQuotes(
              currentRow,
              currencySymbols
            );
            return accumuledResult;
          }
        },
        {}
      );

      if (!isEmpty(currencyRates)) {
        setAppRates(currencyRates);
        handleArbitrageCheck(currencyRates, currencySymbols);
      }
    },
    [handleArbitrageCheck]
  );

  const handleTemplateDownload = useCallback(async () => {
    const signedUrl = await fileService.fetchUploadedFile("default-rates.csv");
    window.open(signedUrl, "_parent");
  }, []);

  const handleAppRatesUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      try {
        if (event.target.files) {
          setLoadingText("Processing rates...");
          const file = event.target.files[0];
          setRatesFile(file);

          Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
              if (results.data) {
                formatCSVToAppRate(results.data);
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage("Upload a valid rates csv file to view rates");
      } finally {
        setLoadingText("");
      }
    },
    [formatCSVToAppRate]
  );

  // ----- OTC Rates Functionality ------
  const formatCSVToOTCRate = useCallback(async (csvRows: any) => {
    if (!Array.isArray(csvRows) || csvRows.length === 0) {
      return showErrorMessage(
        "Upload a valid OTC rates csv file to view rates"
      );
    }

    const rates = csvRows
      .filter((row) => row?.["From"] !== row?.["TO"])
      .map((row: any) => ({
        fromSymbol: row?.["From"]?.trim().toUpperCase(),
        toSymbol: row?.["TO"]?.trim().toUpperCase(),
        value: cleanNumber(row?.["RATE"]),
        inverse: cleanNumber(row?.["Rate(inverse)"]),
        source: row?.["source"]?.trim()?.toLowerCase() ?? "afriex",
      }));
    setOTCRates(removeEmptyRows(rates as AfriexOTCRate[]));
  }, []);

  const handleOTCRatesUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      try {
        if (event.target.files) {
          const file = event.target.files[0];

          Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
              if (results.data) {
                formatCSVToOTCRate(results.data);
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage("Upload a valid OTC rates csv file to view rates");
      }
    },
    [formatCSVToOTCRate]
  );

  return {
    handleTemplateDownload,
    handleAppRatesUpload,
    handleRatesCancellation,
    handleRatesUpdate,
    handleArbitrageCheck,
    appRates,
    loadingText,
    arbitrageResult,
    isArbitrageDetected,
    isArbitrageChecked,
    handleToleranceChange,
    tolerance,
    isLoading,
    isConfirmModalOpen,
    setIsConfirmModalOpen,

    // --- OTC ---
    handleOTCRatesUpload,
    otcRates,
  };
};

export default useRatesUpdate;
