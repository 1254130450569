import { useParams } from "react-router-dom";
import { Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import useUserDetails from "../../../hooks/useUserDetails";
import useUserLogList from "./useUserLogList";
import UserLogTable from "./UserLogTable";
import { PaginationProps } from "../../../components/common/Pagination";

const UserList = () => {
  const { isLoading, isSearch, fetchUserLogList, total, userLogList } =
    useUserLogList();
  const params = useParams();
  const userId = params?.userId ?? "";
  const { userDetails: user } = useUserDetails(userId);

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`${user?.name?.fullName} Activity Logs`} />

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <UserLogTable items={userLogList} />}
      </section>

      {userLogList && userLogList.length ? (
        <Pagination
          onPageChange={fetchUserLogList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={isSearch}
        />
      ) : null}
    </main>
  );
};

export default UserList;
