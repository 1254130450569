import React, { ReactElement, ReactNode, useState } from "react";
import { CustomInput, SpinningLoader } from "..";
import { UploadIcon } from "@heroicons/react/outline";

type FilePickerProps = {
  name: string;
  label: ReactNode;
  isLoading: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string; // Allow specific file types
  className?: string;
};

const FilePicker = (props: FilePickerProps): ReactElement => {
  const { isLoading, className, name, label, accept, onChange } = props;
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFileName(file ? file.name : null); // Update the file name state
    onChange(event); // Call the onChange prop
  };

  return (
    <div className={`file-picker ${className || "mt-5"} flex flex-col gap-2`}>
      {/* Label */}
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        {label}{" "}
        <span className="text-xs text-gray-500 dark:text-gray-400">
          {accept || "All files"}
        </span>
      </label>

      {/* File Input */}
      <div className="relative">
        {isLoading ? (
          <div className="flex justify-center items-center border border-gray-300 rounded-lg py-2 bg-gray-50 dark:bg-gray-700">
            <SpinningLoader size={24} />
          </div>
        ) : (
          <label
            htmlFor={name}
            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
          >
            <CustomInput
              id={name}
              name={name}
              type="file"
              accept={accept || "*"}
              onChange={handleFileChange} // Use the custom file change handler
              className="hidden"
            />
            <div className="flex gap-4">
              <UploadIcon className="w-5 h-5" />
              Select File
            </div>
          </label>
        )}
      </div>

      {/* Selected File Name */}
      {fileName && (
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
          <span className="font-medium">{fileName}</span>
        </p>
      )}
    </div>
  );
};

export default FilePicker;
