import { FilterBar, Pagination, Table, TBody, Tabs } from "../../../components";
import {
  FilterAttribute,
  FilterAttributeType,
} from "../../../components/FilterBar/types";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import { AfriexActions, AfriexPermissions, TabItem } from "../../../types";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import { PaginationProps } from "../../../components/common/Pagination";
import useCountryUpdate from "./useCountryUpdate";
import { useEffect } from "react";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const CountryList = ({
  setCountryTotal,
}: {
  setCountryTotal: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { list, isSearch, isLoading, fetchList, handleFilters, pageInfo } =
    useCountryUpdate();

  useEffect(() => {
    const tots = pageInfo.total ? ` (${pageInfo.total.toLocaleString()})` : "";
    setCountryTotal(tots);
  }, [pageInfo, setCountryTotal]);

  /**
   * These are the filter attributes that will be passed to the filter bar
   */
  const attributes: FilterAttribute[] = [
    {
      title: "",
      label: "status",
      type: FilterAttributeType.Select,
      options: {
        active: "Active",
        inactive: "Not Active",
      },
    },
  ];

  const searchTabs: TabItem[] = [
    {
      id: "filter",
      title: "Filter",
      content: (
        <FilterBar attributes={attributes} onSubmit={handleFilters as any} />
      ),
    },
  ];

  return (
    <>
      <PermissionsProvider
        permission={AfriexPermissions.SETTINGS_PAGE}
        action={AfriexActions.CLICK_SETTINGS_SEARCH_FILTER}
      >
        <Tabs tabs={searchTabs} />
      </PermissionsProvider>

      <main className="py-5 overflow-x-auto">
        {isLoading ? (
          <div className="min-w-full">
            <TableSkeleton />
          </div>
        ) : (
          <Table>
            <ListHeader />
            <TBody>
              {list?.map((item) => <ListItem item={item} key={item.id} />)}
            </TBody>
          </Table>
        )}
      </main>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={pageInfo?.total}
          isSearch={isSearch}
        />
      ) : null}
    </>
  );
};

export default CountryList;
