function isNumeric(value: any) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}
export const cleanNumber = (str: string): string | number => {
  if (!str) {
    return 0;
  }
  if (isNumeric(str)) {
    return str;
  }
  const cleanedString = str.replace(/[\s,]+/g, "");
  // Convert to a number
  const number = parseInt(cleanedString, 10);
  return number;
};
