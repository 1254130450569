import { ReactNode, useCallback, useEffect, useState } from "react";
import { hasPermission } from "../../config/afriexRolesConfig";
import { AfriexActions, AfriexPermissions } from "../../types";

type PermissionsProviderProps = {
  permission: AfriexPermissions;
  action: AfriexActions;
  children: ReactNode;
};

export function PermissionsProvider({
  permission,
  action,
  children,
}: PermissionsProviderProps) {
  const [isAllowed, setIsAllowed] = useState<boolean>(false);

  const _checkPermission = useCallback(() => {
    const allowed = hasPermission(permission, action);
    setIsAllowed(allowed);
  }, [permission, action]);

  useEffect(() => {
    _checkPermission();
  }, [_checkPermission]);

  return <>{isAllowed ? children : null}</>;
}
