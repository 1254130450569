import { ReactNode, useCallback } from "react";
import OtpInput from "react-otp-input";
import OtpVerifyImg from "../../../assets/svg/otp-verify.svg";
import { SpinningLoader } from "../../../components";

const OTPForm = ({
  isLoading,
  otp,
  handleOTPChange,
  handleResendOTP,
  countdown,
}: any) => {
  const email = localStorage.getItem("logging-in");
  const renderButton = useCallback((): ReactNode => {
    let button: React.ReactNode;
    if (countdown === null) {
      button = (
        <button className="text-sm font-semibold" onClick={handleResendOTP}>
          Send a new code
        </button>
      );
    } else if (countdown > 0) {
      const minutes = Math.floor(countdown / 60);
      const seconds = countdown % 60;
      const timeStr = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
      button = (
        <button className="text-sm font-semibold" disabled>
          {timeStr}
        </button>
      );
    }
    if (countdown === 0) {
      button = (
        <button className="text-sm font-semibold" onClick={handleResendOTP}>
          Resend code
        </button>
      );
    }
    return button;
  }, [countdown, handleResendOTP]);

  return (
    <div className="flex justify-center items-center h-screen">
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader size={14} />
        </div>
      ) : (
        <div className="flex flex-col w-5/12 items-center justify-center border rounded-md pt-10 px-7 pb-7 gap-2">
          <h2 className="font-medium text-xl">Verification required</h2>

          <h3 className="text-center text-md">
            Enter the code sent to{" "}
            <span className="text-cyan-600">{email}</span>
          </h3>

          <img src={OtpVerifyImg} alt="loading..." className="w-20 h-24 mt-5" />

          <OtpInput
            containerStyle="mt-10"
            value={otp}
            onChange={handleOTPChange}
            numInputs={6}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid",
              borderColor: "gray",
              borderRadius: "8px",
              width: "48px",
              height: "48px",
              fontSize: "16px",
              color: "#475467",
              fontWeight: "400",
              caretColor: "blue",
            }}
            renderInput={(props) => <input {...props} />}
          />

          <h3 className="font-sans mt-5 font-bold text-l text-purple-500">
            {renderButton()}
          </h3>

          <div className="flex flex-col items-center justify-center w-4/5 mt-5">
            <div className="border-b border-slate-200 w-full" />
            <div className="w-80 m-5 bg-purpleBg-500 rounded-xl text-center w-4/5 p-2">
              <p className="text-gray-700 text-xs leading-5">
                {
                  "If you're unable to locate your verification code, please ensure to look through your spam folder"
                }
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OTPForm;
