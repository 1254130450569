import React from "react";
import { MetricsData } from "./types";
import { formatTime } from "./utils";

const RawMetrics = ({ data }: { data: MetricsData[] }) => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold my-4">Raw Metrics</h1>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-4 py-2">Processor</th>
            <th className="border border-gray-300 px-4 py-2">Corridor Pair</th>
            <th className="border border-gray-300 px-4 py-2">
              Payment Instrument
            </th>
            <th className="border border-gray-300 px-4 py-2">Status</th>
            <th className="border border-gray-300 px-4 py-2">
              Total Transactions
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Successful Transactions
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Success Rate (%)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Average Duration
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Previous Average Duration
            </th>
            <th className="border border-gray-300 px-4 py-2">Delta Duration</th>
          </tr>
        </thead>
        <tbody>
          {data
            .sort((a, b) => {
              return a.percent_st_per_p_cp_pi - b.percent_st_per_p_cp_pi;
            })
            .map((item, index) => {
              const {
                processor,
                corridor_pair,
                payment_instrument,
                status,
                total_no_txn_per_p_cp_pi,
                total_no_txn_per_p_cp_pi_st,
                percent_st_per_p_cp_pi,
                avg_duration_per_p_cp_pi_st,
                avg_duration_per_p_cp_pi_st_prev,
                delta_avg_duration_per_p_cp_pi_st,
              } = item;

              const successRate = percent_st_per_p_cp_pi;
              const avgDuration = avg_duration_per_p_cp_pi_st;
              const prevAvgDuration = avg_duration_per_p_cp_pi_st_prev;
              const deltaDuration = delta_avg_duration_per_p_cp_pi_st;

              return (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                >
                  <td className="border border-gray-300 px-4 py-2">
                    {processor}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {corridor_pair.replace("-->", "::")}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {payment_instrument}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{status}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {total_no_txn_per_p_cp_pi}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {total_no_txn_per_p_cp_pi_st}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {successRate.toFixed(2)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {formatTime(avgDuration)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {formatTime(prevAvgDuration ?? 0)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {formatTime(deltaDuration ?? 0)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default RawMetrics;
