import { useState } from "react";
import ReferralSettingsForm from "./ReferralSettingsForm";
import { UpdateFormProps } from "./types";
import { Button } from "../../../components";
import { AfriexActions } from "../../../types";
import { AfriexPermissions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const ReferralSettings = ({ item, handleSave }: UpdateFormProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-3 gap-3 my-3">
          <div>Referral Transaction Threshold (in days)</div>
          <div className="col-span-2">
            {item?.params?.referralParams?.cumulativeThreshold ?? "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3">
          <div>Bonus Amount Per Referrer</div>
          <div className="col-span-2">
            {item?.params?.referralParams?.bonusAmountPerReferrer ?? "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-3">
          <div>Bonus Amount Per Referree</div>
          <div className="col-span-2">
            {item?.params?.referralParams?.bonusAmountPerReferee ?? "Not Set"}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div className="col-span-6">
            <div className="flex justify-between">
              <span className="text-red-500"></span>
              {showFullScreen ? (
                <ReferralSettingsForm
                  item={item}
                  onClose={toggleFullScreen}
                  handleSave={handleSave}
                />
              ) : (
                <PermissionsProvider
                  permission={AfriexPermissions.SETTINGS_PAGE}
                  action={AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL}
                >
                  <Button
                    variant={"solid"}
                    className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
                    onClick={toggleFullScreen}
                  >
                    Update Referral Settings
                  </Button>
                </PermissionsProvider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralSettings;
