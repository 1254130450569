const DisputeCountBox: React.FC<{
  label?: string;
  count: number;
  color: string;
}> = ({ label, count, color }) => (
  <div
    className={`flex flex-col bg-white p-3 w-48 h-20 gap-2 border border-gray-300 rounded-md ${color}`}
  >
    <h3 className="text-sm text-gray-500">{label}</h3>
    <p className="text-xl text-gray-700 font-semibold">{count}</p>
  </div>
);

export default DisputeCountBox;
