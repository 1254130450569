import { Header } from "../components";

export default function HomePage(): JSX.Element {
  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Home" />
      <div>Welcome to the Admin Portal</div>
    </div>
  );
}
