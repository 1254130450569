import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import TransactionDetail from "../../../components/TransactionDetail/TransactionDetail";
import TransactionHistory from "../../../components/TransactionDetail/TransactionHistory";
import { transactionService } from "../../../services/transactionService";
import { AfriexTransaction } from "../../../types";
import { Header } from "../../../components";
import { attachAdminUser } from "../../../helpers/dashboard";
import Spinner from "../../../assets/svg/Spinner";

export default function TransactionDetailsPage(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const [transactionHistory, setTransactionHistory] = useState<
    AfriexTransaction[]
  >([]);
  const [transactionDetail, setTransactionDetail] = useState<AfriexTransaction>(
    {} as AfriexTransaction
  );

  const [loadingTnxDetail, setLoadingTnxDetail] = useState<boolean>(false);
  const [loadingTnxHistory, setLoadingTnxHistory] = useState<boolean>(false);
  const [fullTransactionsHistory, setFullTransactionsHistory] = useState<
    AfriexTransaction[]
  >([]);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  async function onExport(event: any, done: any): Promise<void> {
    try {
      if (totalTransactions <= 0) done(false);
      setLoadingExport(true);
      const response = await transactionService.getTransactionList({
        limit: totalTransactions,
        page: 0,
      });
      setFullTransactionsHistory(response.data);
      setTotalTransactions(response.total);
      setLoadingExport(false);
      if (response?.data?.length) done(true);
    } catch (err) {
      setLoadingExport(false);
      done(false);
    }
  }

  const fetchTransactionDetails = useCallback(async () => {
    try {
      setLoadingTnxDetail(true);
      const currentTransaction = await transactionService.getTransaction({
        id: params.transactionId as string,
      });
      const filtered = await attachAdminUser(currentTransaction);
      setTransactionDetail({ ...currentTransaction, ...filtered });
      // Get user for different transaction types
      currentTransaction &&
        fetchTransactionHistory(currentTransaction.sourceUserId as string);
      setLoadingTnxDetail(false);
    } catch (err) {
      setLoadingTnxDetail(false);
    }
  }, [params.transactionId]);

  async function fetchTransactionHistory(userId: string): Promise<void> {
    try {
      setLoadingTnxHistory(true);
      const response = await transactionService.getTransactionList({ userId });
      setTransactionHistory(response.data);
      setTotalTransactions(response.total);
      setLoadingTnxHistory(false);
    } catch (err) {
      setLoadingTnxHistory(false);
    }
  }

  useEffect(() => {
    fetchTransactionDetails();
  }, [fetchTransactionDetails]);

  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header
        title="Transaction Details"
        icon={
          <ArrowNarrowLeftIcon
            className="font-light w-5 h-5 text-black hover:text-indigo-500 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
      />

      <div className="flex justify-center items-center flex-col">
        {loadingTnxDetail ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <div className="mt-10 w-10/12 rounded-md bg-white border border-gray-300 p-7 flex flex-col">
            {transactionDetail && (
              <TransactionDetail data={transactionDetail} />
            )}
          </div>
        )}

        {loadingTnxHistory ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <div className="mt-5 w-10/12 rounded-md bg-white border border-gray-300 p-7 flex flex-col">
            <TransactionHistory
              data={transactionHistory}
              fullTransactionsHistory={fullTransactionsHistory}
              handleExport={onExport}
              loadingExport={loadingExport}
              totalTransactions={totalTransactions}
            />
          </div>
        )}
      </div>
    </div>
  );
}
