import { useState, useCallback, useEffect } from "react";
import { userService } from "../services/userService";
import { AfriexPerson } from "../types";
import { showErrorMessage } from "../utils/showErrorMessage";

const useUserDetails = (userId: string) => {
  const [userDetails, setUserDetails] = useState<AfriexPerson>(
    {} as AfriexPerson
  );
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false);

  const fetchUserDetails = useCallback(async (): Promise<void> => {
    try {
      if (!userId) {
        throw new Error("User Id is required");
      }
      setIsLoadingUserDetails(true);
      const response = await userService.getUser(userId);
      setUserDetails(response);
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data.message;
      showErrorMessage("Failed to fetch user details:" + errorMessage);
    } finally {
      setIsLoadingUserDetails(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return {
    userDetails,
    isLoadingUserDetails,
  };
};

export default useUserDetails;
