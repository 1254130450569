import { Pagination, TableSkeleton } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexActions } from "../../../types";
import { AfriexPermissions } from "../../../types";
import ReferralTierTable from "./ReferralTierTable";
import TierUpdateButton from "./TierUpdateButton";
import useTierList from "./useTierList";

export const ReferralTierListTab = () => {
  const { isLoading, fetchList, total, list } = useTierList();
  return (
    <main className="my-5">
      <PermissionsProvider
        permission={AfriexPermissions.REFERRAL_PAGE}
        action={AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER}
      >
        <div className="flex justify-end">
          <TierUpdateButton />
        </div>
      </PermissionsProvider>

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <ReferralTierTable items={list} />}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};
