import { ReactElement } from "react";
import { SupportedAssets } from "../../../types";
import { UserDetailsReturnProps } from "./types";
import { isEmpty } from "lodash";
import { formatMonetaryValue } from "../../../helpers/dashboard";

type UserWalletBalanceProps = Pick<
  UserDetailsReturnProps,
  "walletBalances" | "isLoadingWalletBalances"
>;

const UserWalletBalance = ({
  walletBalances,
  isLoadingWalletBalances,
}: UserWalletBalanceProps): ReactElement => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-sm font-semibold text-gray-600">Wallet Balance</h3>

      <div>
        {isLoadingWalletBalances ? (
          <p className="text-sm">Loading...</p>
        ) : !isEmpty(walletBalances) ? (
          <ul>
            {Object.keys(walletBalances)?.map((key: string) => {
              return (
                <li key={key}>
                  {key}
                  {" -> "}
                  {formatMonetaryValue(
                    walletBalances?.[key as SupportedAssets] as number
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          "0"
        )}
      </div>
    </div>
  );
};

export default UserWalletBalance;
