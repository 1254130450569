import { useCallback, useEffect, useState } from "react";
import { AfriexQueryParams } from "../../types";
import { userService } from "../../services/userService";
import { AfriexDispute, AfriexDisputeCount } from "../../types/AfriexDispute";

const useDisputeList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [disputes, setDisputes] = useState<AfriexDispute[]>([]);
  const [disputeTotal, setDisputeTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [disputeCounts, setDisputeCounts] = useState<AfriexDisputeCount | null>(
    null
  );

  const fetchAllDisputes = useCallback(async (params?: AfriexQueryParams) => {
    try {
      setIsLoading(true);
      const response = await userService.getDisputes({ ...params });

      setDisputes(response.data);
      setDisputeTotal(response.total);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchDisputeCounts = useCallback(async () => {
    try {
      const counts = await userService.countDisputes();
      setDisputeCounts(counts);
    } catch (error) {
      console.error("Error fetching dispute counts:", error);
    }
  }, []);

  useEffect(() => {
    fetchAllDisputes({});
    fetchDisputeCounts();
  }, [fetchAllDisputes, fetchDisputeCounts]);

  return {
    fetchDisputes: fetchAllDisputes,
    fetchDisputeCounts,
    pageInfo: {
      total: disputeTotal,
      page,
    },
    disputes,
    disputeCounts,
    isLoading,
    setPage,
  };
};

export default useDisputeList;
