import { useCallback, useEffect, useState } from "react";
import { referralService } from "../../../services/referralService";
import { AfriexPerson } from "../../../types";
import { AfriexQueryParams } from "../../../types/AfriexQueryParams";
import { TierListProps } from "./types";

const useTierUserList = (
  tierId: string
): TierListProps<Partial<AfriexPerson>> => {
  const [tierUserList, setTierUserList] = useState<Partial<AfriexPerson>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);

  const fetchTierUserList = useCallback(
    async (params: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data, total } = await referralService.getTierUserList(
          tierId,
          params
        );
        setTierUserList(data);
        setItemTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [tierId]
  );

  useEffect(() => {
    fetchTierUserList({});
  }, [fetchTierUserList]);

  return {
    error,
    fetchList: fetchTierUserList,
    isLoading,
    page,
    setPage,
    total: itemTotal,
    list: tierUserList,
  };
};

export default useTierUserList;
