import { FilePicker } from "../../../components";

const RatesUploadForm = ({
  handleAppRatesUpload,
  handleTemplateDownload,
  handleOTCRatesUpload,
}: any) => {
  return (
    <section className="rates-upload p-8 border-2 rounded-md mt-10 w-600">
      <aside className="rates-file-top">File Upload</aside>
      <p
        className="download-rate-template justify-right"
        onClick={handleTemplateDownload}
      >
        **Download app rate file template**
      </p>

      <FilePicker
        isLoading={false}
        label={
          <>
            App Rates file upload <span className="text-red-500">*</span>
          </>
        }
        name="appRatesUpload"
        accept="text/csv"
        onChange={handleAppRatesUpload}
        className="mt-10"
      />

      <FilePicker
        isLoading={false}
        label={
          <>
            OTC Rates file upload <span className="text-red-500">*</span>
          </>
        }
        name="otcRatesUpload"
        accept="text/csv"
        onChange={handleOTCRatesUpload}
        className="mt-6"
      />
      <p className="my-12" />
    </section>
  );
};

export default RatesUploadForm;
