import { Button, CheckBox, CustomInput } from "../../../components";
import CustomModal from "../../../components/CustomModal";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import useUserUpdate from "./useUserUpdate";

const UpdateUserInfoModal: React.FC<
  React.PropsWithChildren<UserModalProps>
> = ({ isOpen, onClose, user }) => {
  const {
    handlePhoneVerifiedChange,
    isButtonDisabled,
    isPhoneVerified,
    isSubmitting,
    handleSubmit,
    setIsPhoneVerified,
    handleUserUpdate,
    user: userToUpdate,
  } = useUserUpdate(user, onClose);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-3/12"
      closeOnOutsideClick
      title="Update User Information"
    >
      <form onSubmit={handleSubmit}>
        <CustomInput
          name="fullName"
          label="Full Name"
          type="text"
          value={userToUpdate.fullName}
          onChange={handleUserUpdate}
          placeholder="Enter Full Name"
          className="w-full"
        />

        <div className="mt-5">
          <CustomInput
            name="currentEmail"
            label="Email"
            type="text"
            value={userToUpdate.currentEmail}
            onChange={handleUserUpdate}
            placeholder="Enter email"
            className="w-full"
          />
        </div>

        <div className="mt-5">
          <CustomInput
            name="userName"
            label="Username"
            type="text"
            value={userToUpdate.userName}
            onChange={handleUserUpdate}
            placeholder="Enter username"
            className="w-full"
          />
        </div>

        <div className="mt-5">
          <CustomInput
            name="currentPhone"
            label="Phone"
            type="text"
            value={userToUpdate.currentPhone}
            onChange={handleUserUpdate}
            placeholder="Enter Phone"
            className="w-full"
          />
        </div>

        <div className="mt-5">
          <CheckBox
            label="Verify Phone Number"
            type="checkbox"
            checked={isPhoneVerified}
            onChange={() => {
              handlePhoneVerifiedChange(!isPhoneVerified);
              setIsPhoneVerified(!isPhoneVerified);
            }}
          />
        </div>

        <div className="flex w-full justify-end space-x-2 py-5">
          <Button variant="outline" colorScheme="cyan" onClick={onClose}>
            Cancel
          </Button>

          <Button
            disabled={isSubmitting || isButtonDisabled}
            type="submit"
            variant="solid"
            colorScheme="cyan"
          >
            Update User
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

export default UpdateUserInfoModal;
