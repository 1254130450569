import axios from "../api/axios";
import {
  AfriexAdminQueryParams,
  AfriexAdmin,
  AfriexPaginatedResponse,
} from "../types";

export const adminService = {
  getAdmins: async (
    filters: AfriexAdminQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexAdmin[]>> => {
    const queryParams = {
      page: 0,
      limit: 10,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/users/afriex-admins?${urlParams}`;
    const response = await axios.get(url);
    return response.data;
  },
};
