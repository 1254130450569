import React, { useState } from "react";
import { isEmpty } from "lodash";
import CustomModal from ".";
import { CustomTextArea } from "../common/Input";
import { Button, CustomSelect } from "..";
import { ExclamationIcon } from "@heroicons/react/outline";
import { DeactivateModalProps } from "../DeactivateAccount/types";
import ConfirmationModal from "./ConfirmationModal";
import { AfriexRoles } from "../../types";
import { getAdminRoleOptions } from "../../constants/formatters";
import { toast } from "react-toastify";
import { userService } from "../../services/userService";

const ActivationModal: React.FC<
  React.PropsWithChildren<DeactivateModalProps>
> = ({ isOpen, onClose, user, type }) => {
  const afriexUser = !isEmpty(user) ? user : null;
  const userName = afriexUser?.name.fullName;
  const [inputReason, setInputReason] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [afriexRole, setAfriexRole] = useState<AfriexRoles | string>("");
  const [loading, setLoading] = useState(false);
  const isAdminDeactivated = type === "admin" && user.isDeactivated;
  const isFilled =
    (isAdminDeactivated && inputReason.length > 3 && afriexRole.length) ||
    inputReason.length > 3;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputReason(event.target.value);
  };

  const onDismiss = () => {
    onClose?.();
    setIsConfirmModalOpen(false);
  };
  const openSecondModal = () => {
    if (isFilled) return setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const activateAction = async () => {
    setLoading(!loading);
    try {
      await userService.deactivateUser(user?.id, {
        isActive: user.isDeactivated ? true : false,
        reason: inputReason,
      });

      if (type === "admin") {
        await userService.updateUserRole(
          user?.id,
          (isAdminDeactivated ? afriexRole : AfriexRoles.USER) as AfriexRoles
        );
      }
      toast.success(
        `Successfully ${user.isDeactivated ? "activated" : "deactivated"} user!`
      );
      onDismiss();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(
        `Failed to ${user.isDeactivated ? "activate" : "deactivate"} user`
      );
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width="w-2/6"
        closeOnOutsideClick
        title={`${afriexUser?.isDeactivated ? "Activate" : "Deactivate"} ${userName}`}
      >
        <p className="flex gap-2 justify-center">
          <ExclamationIcon className="w-6 h-6 text-failed-500" /> You are about
          to {afriexUser?.isDeactivated ? "activate" : "deactivate"} this user.
        </p>

        <div className="mt-6">
          <CustomTextArea
            label="Reason"
            name="reason"
            required
            onChange={handleInputChange}
            value={inputReason}
            aria-rowspan={3}
            className="w-full"
            placeholder={`Enter reason for ${afriexUser?.isDeactivated ? "activation" : "deactivation"}`}
          />

          {isAdminDeactivated && (
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setAfriexRole(e.target.value)
              }
              value={afriexRole}
              name="role"
              options={getAdminRoleOptions()}
              placeholder="Filter by Role"
            />
          )}

          <div className="flex justify-end mt-6">
            <Button
              colorScheme="red"
              onClick={openSecondModal}
              className="h-10"
              disabled={!isFilled}
            >
              Continue
            </Button>
          </div>
        </div>
      </CustomModal>

      {!isEmpty(user) && (
        <ConfirmationModal
          isOpen={isConfirmModalOpen}
          label={
            <>
              Are you sure you want to{" "}
              <span
                className={
                  user.isDeactivated ? "text-successful-500" : "text-failed-500"
                }
              >
                {user.isDeactivated ? "activate" : "deactivate"}
              </span>
              <br />
              <span className="font-semibold">{user.name.fullName}</span>
            </>
          }
          cancelAction={onDismiss}
          cancelActionLabel="No"
          confirmAction={activateAction}
          confirmActionLabel="Yes"
          loadingLabel={
            user.isDeactivated ? "Activating..." : "Deactivating..."
          }
          loading={loading}
          title=""
        />
      )}
    </React.Fragment>
  );
};

export default ActivationModal;
