export type ReceiptConfig = {
  title: string;
  userAcceptancePolicy: string;
  refundPolicyTitle: string;
  refundPolicyContent: string;
  userRefundPolicy: string;
};

const receiptConfig: ReceiptConfig = {
  title: "Remittance Transaction Receipt",
  userAcceptancePolicy: "User acceptance policy duly agreed",
  refundPolicyTitle: "Refund Policy",
  refundPolicyContent: `If your transaction is on the way, you can cancel it, for a full refund, up to 30 minutes after you've paid - Unless the money has already been paid out. If you scheduled your transaction in advance, you can cancel it up to one day before it's supposed to be sent. If there was an error with your transaction, please reach out to customer service at <span className="underline">+1 888 903 3833</span> within 180 days. After that, we won't be able to help. You can contact us for a written explanation of your rights. You, the customer, are entitled to a refund of the money you sent if Afriex Inc. does not forward the money to our designated recipient, then the funds will be returned to the original account from where you sent the funds, unless you provide written instructions otherwise. If your instructions as to when the money shall be forwarded or transmitted are not executed and the money has not yet been forwarded or transmitted, you have a right to a refund of your money. If you want a refund, you must deliver your written request to Afriex Inc via email or through our Help Center. If you do not receive your refund, you may be entitled to get your money back plus a penalty of up to $1,000 and attorney's fees pursuant to Section 2102 of the California Financial Code. Consumer Protection: For questions or complaints about Afriex Inc, you may contact: Department of Financial Protection and Innovation Ph: 1-866-275-2677 (toll-free) <a href="https://www.dfpi.ca.gov" class="text-blue-500 text underline ml-1" target="_blank" rel="noopener noreferrer">https://www.dfpi.ca.gov</a> Consumer Financial Protection Bureau`,
  userRefundPolicy: "User agreed with the refund policy",
};

export default receiptConfig;
