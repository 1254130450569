import { useCallback, useEffect, useState } from "react";
import useCountryList from "../../../hooks/useCountryList";
import {
  BlockedPaymentMethod,
  BlockedPaymentMethodFilters,
  blockedPaymentMethodService,
} from "../../../services/blockedPaymentMethodService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { identity, pickBy } from "lodash";

const useBlockedPaymentMethod = () => {
  const [list, setList] = useState<BlockedPaymentMethod[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [shouldShowForm, setShouldShowForm] = useState<boolean>(false);
  const [filters, setFilters] = useState<BlockedPaymentMethodFilters>(
    {} as BlockedPaymentMethodFilters
  );
  const { countryList } = useCountryList();
  countryList?.sort((a, b) => a?.name?.localeCompare(b.name));
  const fetchList = useCallback(
    async (params: BlockedPaymentMethodFilters) => {
      try {
        setIsLoading(true);
        const { data: items, total: itemsTotal } =
          await blockedPaymentMethodService.getList({ ...params, ...filters });
        setList(items);
        setTotal(itemsTotal ?? 0);
      } catch (e) {
        showErrorMessage(`Fetching blocked payment method list failed:${e}`);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const handleFilters = useCallback(
    async (filters: BlockedPaymentMethodFilters) => {
      setIsSearch(false);
      // remove undefined values
      const cleanedFilters = pickBy(filters, identity);

      setFilters({ page: 0, ...cleanedFilters } as BlockedPaymentMethodFilters);
    },
    []
  );

  useEffect(() => {
    setIsSearch(false);
    fetchList({} as BlockedPaymentMethodFilters);
  }, [fetchList]);

  const handleAddItem = useCallback(async (body: BlockedPaymentMethod) => {
    try {
      if (!body.country || !body.accountIdentifier || !body.type) {
        throw new Error("Please fill all required fields");
      }

      setError(null);
      setIsLoading(true);
      const blockedPaymentMethod =
        await blockedPaymentMethodService.saveOne(body);
      if (blockedPaymentMethod) {
        showSuccessMessage("Payment method blocked successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Adding blocked payment method failed:${e}`);
    }
    setIsLoading(false);
  }, []);

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm) {
        setIsSearch(false);
        setFilters({} as BlockedPaymentMethodFilters);
        fetchList({} as BlockedPaymentMethodFilters);
        return;
      }
      setIsSearch(true);
      setFilters({
        page: 0,
        accountIdentifier: searchTerm?.trim()?.toString(),
      } as BlockedPaymentMethodFilters);
    },
    [fetchList]
  );

  const handleRemoveItem = useCallback(async (id: string) => {
    try {
      if (!id) {
        throw new Error("No id provided");
      }
      setError(null);
      setIsLoading(true);
      await blockedPaymentMethodService.removeOne(id);

      showSuccessMessage("Payment method unblocked successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      showErrorMessage(`Unblocking payment method failed:${e}`);
    }
    setIsLoading(false);
  }, []);

  const toggleForm = useCallback(() => {
    setShouldShowForm(!shouldShowForm);
  }, [shouldShowForm]);

  return {
    error,
    isLoading,
    isSearch,
    handleAddItem,
    handleSearch,
    handleRemoveItem,
    handleFilters,
    fetchList,
    page,
    setPage,
    total,
    countryList: countryList?.filter((c) => c.status === "active"),
    list,
    shouldShowForm,
    toggleForm,
  };
};

export default useBlockedPaymentMethod;
