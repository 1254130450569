import { useCallback, useEffect, useState } from "react";
import { ratesService } from "../services/ratesService";
import { AfriexRate, AfriexRateInfo } from "../types";
import { showErrorMessage } from "../utils/showErrorMessage";

type RatesListReturnProps = {
  isLoading: boolean;
  rateInfo: Partial<AfriexRateInfo>;
};

const useRates = (): RatesListReturnProps => {
  const [isLoading, setIsLoading] = useState(false);
  const [appRates, setAppRates] = useState<AfriexRate[]>([]);

  const fetchRates = useCallback(async () => {
    try {
      setIsLoading(true);
      const latestRates = await ratesService.getAppRatesList();
      setAppRates(latestRates);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showErrorMessage("Rates could not be fetched:" + err);
    }
  }, []);
  useEffect(() => {
    fetchRates();
  }, [fetchRates]);

  return {
    isLoading,
    rateInfo: {
      appRates,
    },
  };
};
export default useRates;
