import { useCallback, useEffect, useState } from "react";
import { tierService } from "../../../services/tierService";
import { AfriexTier } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";

type TierDetailsReturnProps = {
  isLoading: boolean;
  tier: AfriexTier;
};

const useTierDetails = (tierId: string): TierDetailsReturnProps => {
  const [isLoading, setIsLoading] = useState(true);
  const [tier, setTier] = useState<AfriexTier>({} as AfriexTier);
  const fetchTierDetails = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const tierItem = await tierService.getTier(id);
      setTier(tierItem);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showErrorMessage("Tier details could not be fetched:" + err);
    }
  }, []);
  useEffect(() => {
    fetchTierDetails(tierId);
  }, [fetchTierDetails, tierId]);

  return { isLoading, tier };
};
export default useTierDetails;
