import BigNumber from "bignumber.js";

const DEFAULT_PRECISION = 10;
const DEFAULT_ROUNDING_MODE = BigNumber.ROUND_UP;

export const formatDecimalPlaces = (number: number, index = 2) => {
  // return number with desired decimal places after the dot
  const indexOfDot = number?.toString().indexOf(".");
  if (indexOfDot > 0) {
    return number?.toString().substring(0, indexOfDot + (index + 1));
  } else {
    return number?.toString();
  }
};
/**
 * Returns the formatted rate value
 */
export const formatRate = (amount: number) =>
  formatDecimalPlaces(
    new BigNumber(amount?.toString())
      .precision(DEFAULT_PRECISION, DEFAULT_ROUNDING_MODE)
      .toNumber()
  );
