import {
  CheckCircleIcon,
  InformationCircleIcon,
  BellIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { makeBulkUploadPayment } from "../../api/bulkUpload";
import Button from "../common/Button";
import Modal from "../common/Modal";
import SpinningLoader from "../common/SpinningLoader";

type RateModalProps = {
  onClose: () => void;
  reloadFunc: () => void;
};

export function BulkUploadPaymentModal({
  reloadFunc,
  onClose,
}: RateModalProps) {
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [response, setResponse] = useState<any>();
  const [isCredit, setIsCredit] = useState(true);
  const [comment, setComment] = useState("");
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!isAcknowledged) {
      return setShowAlertModal(true);
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("comment", comment);
      isCredit
        ? formData.append("bulkActionType", "credit")
        : formData.append("bulkActionType", "debit");
      const response = await makeBulkUploadPayment(formData);
      response && setLoading(false);
      response && reloadFunc();

      setResponse(response);
    } catch (err: any) {
      const errorMessage = err.response?.data.message
        ? err.response?.data.message
        : err.message;
      setErrorMessage(errorMessage || "Unknown Error. Contact Admin!");
      setError(err?.response?.data);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage("");
    setError(null);
    setResponse(null);
    setFile(null);
    onClose();
  };

  const showAlert = () => {
    return (
      <div>
        <div className="flex items-center my-5">
          <BellIcon className="text-blue-500 w-10 h-10" />
          {`Are you sure you want to ${isCredit ? "credit" : "debit"} users`}
        </div>

        <div className="flex w-full justify-end space-x-2 py-5">
          <Button className="bg-red-300" onClick={handleCloseModal}>
            No Cancel
          </Button>
          <Button
            className="bg-green-300"
            onClick={() => {
              setShowAlertModal(false);
              setIsAcknowledged(true);
              handleSubmit();
            }}
          >
            Yes Proceed
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg text font-bold">Bulk Payment Upload</h3>
        {loading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : error ? (
          <div>
            <div className="flex items-center my-5">
              <InformationCircleIcon className="text-red-500 w-10 h-10" /> Bulk
              Payment Error
            </div>
            <p className="truncate text-red-800">{errorMessage}</p>
            {error?.data && (
              <p className="truncate text-red-800">
                {JSON.stringify(error.data)}
              </p>
            )}
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={handleCloseModal} variant="outline">
                close
              </Button>
            </div>
          </div>
        ) : response ? (
          <div>
            <div className="flex items-center my-5">
              <CheckCircleIcon className="text-green-500 w-16 h-16" />
              Bulk Payment Completed
            </div>
            <ul>
              <li>
                Success Count:{" "}
                <span className="text-green-500">{response.successCount}</span>
              </li>
              <li>
                Failed Count:{" "}
                <span className="text-red-500">{response.failedCount}</span>
              </li>
            </ul>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={handleCloseModal} variant="outline">
                close
              </Button>
            </div>
          </div>
        ) : showAlertModal ? (
          showAlert()
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mt-5">
              <input
                required
                type="file"
                accept=".csv"
                onChange={handleChange}
              />
            </div>

            <div className="mt-5">
              <label>Comment : </label>
            </div>
            <div className="mt-5">
              <textarea
                value={comment}
                name="comment"
                onChange={(e) => setComment(e.target.value)}
                rows={3}
                cols={40}
                placeholder={"comment..."}
              />
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={handleCloseModal} variant="outline">
                cancel
              </Button>
              <Button
                // type="submit"
                // variant="outline"
                className="bg-green-300"
                // onClick={()=> setIsCredit(true)}
                onClick={() => {
                  setIsCredit(true);
                  showAlert();
                }}
              >
                Credit
              </Button>
              <Button
                // type="submit"
                // variant="outline"
                className="bg-red-300 "
                onClick={() => {
                  setIsCredit(false);
                  showAlert();
                }}
              >
                Debit
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}
