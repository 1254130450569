import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import OTCVolumeModal from "./OTCVolumeModal";
import { AfriexOTCVolume } from "../../../types";
import { isEmpty } from "lodash";

type OTCUpdateButtonProps = {
  initialData?: AfriexOTCVolume;
};

const OTCUpdateButton = ({
  initialData,
}: OTCUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Upload OTC Volume +"
    : "Update OTC Volume";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <OTCVolumeModal
      isOpen={showFullScreen}
      onClose={toggleFullScreen}
      initialData={initialData}
    />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {updateLabel}
    </Button>
  );
};

export default OTCUpdateButton;
