import { AfriexTransactionUpdate, LedgerReasons } from "../types/Transaction";
import axios from "./axios";

export async function updateTransaction(
  transactionID: string,
  update: AfriexTransactionUpdate
) {
  const response = await axios.patch(
    `/v2/admin/transactions/${transactionID}`,
    update
  );
  return response.data;
}

export async function swap(swapParams: {
  fromCurrency: string;
  toCurrency: string;
  amount: number;
  owner: string;
}) {
  const response = await axios.post(`/v2/admin/transactions/swap`, {
    amount: swapParams.amount,
    fromCurrency: swapParams.fromCurrency,
    toCurrency: swapParams.toCurrency,
    userId: swapParams.owner,
  });
  return response.data;
}

export async function ledger(params: {
  currency: string;
  amount: number;
  userId: string;
  action: "debit" | "credit";
  reason: LedgerReasons;
}) {
  const response = await axios.post(`/v2/admin/transactions/ledger`, params);
  return response.data;
}
