/* eslint-disable no-console */
/**
 * Custom logger to log only in development mode.
 * This is just a wrapper around console methods.
 */
// window.process = process || {};
type Logger = {
  log: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
  trace: (...args: unknown[]) => void;
};

const onlyDev =
  (method: (...args: unknown[]) => void) =>
  (...args: unknown[]) => {
    if (process.env.NODE_ENV === "development") {
      method(...args);
    }
  };
export const logger: Logger = {
  log: onlyDev((...args: unknown[]) => {
    console.log(...args);
  }),
  warn: onlyDev((...args: unknown[]) => {
    console.warn(...args);
  }),
  error: onlyDev((...args: unknown[]) => {
    console.error(...args);
  }),
  info: onlyDev((...args: unknown[]) => {
    console.info(...args);
  }),
  debug: onlyDev((...args: unknown[]) => {
    console.debug(...args);
  }),
  trace: onlyDev((...args: unknown[]) => {
    console.trace(...args);
  }),
};
