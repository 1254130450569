import { useCallback, useEffect, useState } from "react";
import { kycService } from "../services/kycService";
import { AfriexCountry } from "../types";
import { showErrorMessage } from "../utils/showErrorMessage";

const useCountryList = () => {
  const [countryList, setCountryList] = useState<AfriexCountry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCountryList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: countries } = await kycService.getCountryList({});
      setCountryList(countries ?? []);
    } catch (e) {
      showErrorMessage(`Fetching country list failed:${e as any}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCountryList();
  }, [fetchCountryList]);

  return {
    countryList: countryList as AfriexCountry[],
    fetchCountryList,
    isLoading,
  };
};

export default useCountryList;
