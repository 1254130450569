import React, { useState } from "react";
import { AfriexOTCVolume } from "../../../types";
import { OTCVolumeListProps } from "./types";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";
import { MinusIcon } from "@heroicons/react/solid";
import * as formatDate from "../../../utils/dateFormatter";
import { Button } from "../../../components";
import OTCSummary from "./OTCSummary";
import { formatMonetaryValue } from "../../../helpers/dashboard";

type ExtraRowArg = AfriexOTCVolume;

const OTCVolumeTable = ({
  items,
  extraRow,
  handleApprove,
}: {
  items: AfriexOTCVolume[];
  extraRow?: (user?: ExtraRowArg) => React.ReactNode;
  handleApprove: OTCVolumeListProps["handleApprove"];
}) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Sent
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Received
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Rate
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return (
            <OTCVolumeTRow
              item={item}
              key={item.id}
              extraRow={extraRow}
              handleApprove={handleApprove}
              index={index}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const OTCVolumeTRow = ({
  item,
  extraRow,
  handleApprove,
  index,
}: {
  item: AfriexOTCVolume;
  extraRow?: (item?: ExtraRowArg) => React.ReactNode;
  handleApprove: OTCVolumeListProps["handleApprove"];
  index: number;
}) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const zebraRow = (index + 1) % 2 === 0 ? "" : "bg-gray-50";

  return (
    <React.Fragment>
      <tr
        className={`hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500 ${zebraRow}`}
      >
        <td className="pr-6 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item?.fromAmount?.toLocaleString()} {item?.fromSymbol}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item?.toAmount?.toLocaleString()} {item?.toSymbol}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatMonetaryValue(Number(item.rate), false, 6)}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(item.timestamp?.toString() ?? "")}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <ChevronRightIcon className=" w-5 h-5" />
        </td>
      </tr>

      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <OTCSummary
                handleApprove={handleApprove}
                item={item}
                extraRow={extraRow ? extraRow(item) : null}
              />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default OTCVolumeTable;
