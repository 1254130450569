import moment from "moment";

export const formatDate = (date: string): string =>
  moment.utc(date).format("D MMM, YYYY");

export const formatTime = (date: string): string =>
  moment(date).format("hh:mm A");
export const formatTimeDifference = (
  timestamp1: number,
  timestamp2: number
) => {
  // Calculate difference in milliseconds directly
  const difference = Math.abs(timestamp1 - timestamp2);

  // Calculate days, hours, minutes, seconds, and milliseconds
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  const milliseconds = difference % 1000;

  // Build the human-readable string
  let result = "";
  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += minutes + "m ";
  if (seconds > 0) result += seconds + "s ";
  if (milliseconds > 0) result += milliseconds + "ms";

  return result.trim();
};
