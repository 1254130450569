import { applyDiscountToRates } from "../../../utils/rates/applyDiscountToRates";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import RatesTable from "../App/RatesTable";
import { RateInfoProps } from "../types";

type TierRateTableProps = RateInfoProps & {
  discount?: number;
};

const TierRateTable = ({
  isLoading,
  rateInfo,
  discount,
}: TierRateTableProps) => {
  const discountRates = applyDiscountToRates(
    rateInfo?.appRates ?? [],
    discount ?? 0
  );
  const appRates = convertRatesListToMap(discountRates ?? []);
  return (
    <div className="container mx-auto py-6">
      {isLoading ? <p>Loading...</p> : <RatesTable rates={appRates} />}
    </div>
  );
};

export default TierRateTable;
