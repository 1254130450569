import { useParams } from "react-router-dom";
import { Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import useTierUserList from "./useTierUserList";
import { AfriexActions, AfriexPermissions, AfriexPerson } from "../../../types";
import TierUserUpdateButton from "./TierUserUpdateButton";
import UserTable from "../../User/List/UserTable";
import { PaginationProps } from "../../../components/common/Pagination";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const TierUserList = () => {
  const params = useParams();
  const tierId = params?.tierId ?? "";
  const { isLoading, fetchList, total, list } = useTierUserList(tierId);

  return (
    <main className="my-5">
      <PermissionsProvider
        permission={AfriexPermissions.REFERRAL_PAGE}
        action={AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER}
      >
        <div className="flex justify-end">
          <TierUserUpdateButton tierId={tierId} />
        </div>
      </PermissionsProvider>

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <UserTable
            items={list as AfriexPerson[]}
            extraRow={(user) => (
              <PermissionsProvider
                permission={AfriexPermissions.REFERRAL_PAGE}
                action={AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER}
              >
                <div className="w-full flex justify-end mb-3">
                  <TierUserUpdateButton tierId={tierId} userId={user?.id} />
                </div>
              </PermissionsProvider>
            )}
          />
        )}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};

export default TierUserList;
