import { ReactComponent as ComingSoonSvg } from "../../assets/svg/comingSoon.svg";
export default function ComingSoon(): JSX.Element {
  return (
    <div className="w-full mt-28">
      <ComingSoonSvg className="h-1/3 w-1/3 mx-auto my-10" />
      <h1 className="text-indigo-500 font-bold text-center text-6xl m-0">
        Coming Soon!
      </h1>
    </div>
  );
}
