import { useReducer, useState } from "react";
import { referralService } from "../../../services/referralService";
import { AfriexReferralTier } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import updateTierReducer from "./updateTierReducer";
import { pick } from "lodash";
const defaultInfo: AfriexReferralTier = {
  name: "",
  bonusAmountPerReferree: 0,
  referralCode: "",
  isDeactivated: false,
  shouldAutomatePayout: true,
  shouldSkipReferrerPayout: true,
  cumulativeThreshold: 10,
  transactionWindow: 30,
  type: "signup",
} as any;
const useSignupBonusUpdate = (
  onClose: VoidFunction,
  initialData: Partial<AfriexReferralTier> = defaultInfo
) => {
  const [tier, dispatch] = useReducer(
    updateTierReducer,
    initialData as AfriexReferralTier
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (rawTier: AfriexReferralTier) => {
    setIsLoading(true);
    try {
      if (!rawTier.name) {
        showErrorMessage(`Please provide the name of the referral tier`);
        setIsLoading(false);
        return;
      }
      const tier: AfriexReferralTier = {
        ...rawTier,
      };
      if (rawTier.id) {
        const fieldsToInclude = [
          "name",
          "bonusAmountPerReferree",
          "referralCode",
          "isDeactivated",
          "type",
        ];
        await referralService.updateTier(
          rawTier.id,
          pick(tier, fieldsToInclude) as AfriexReferralTier
        );
        showSuccessMessage(`Signup bonus tier updated successfully`);
      } else {
        await referralService.createTier(tier);
        showSuccessMessage(`Signup bonus tier saved successfully`);
      }

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      showErrorMessage(`Error saving signup bonus tier ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    dispatch,
    handleSubmit,
    isLoading,
    tier,
  };
};

export default useSignupBonusUpdate;
