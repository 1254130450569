import { ChangeEvent, ReactElement, useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { UserDetailsReturnProps } from "../../screens/User/Details/types";
import BlockUser from "../../screens/User/Details/BlockUser";
import Button from "../common/Button";
import { AfriexActions } from "../../types/AfriexRoles";
import { PermissionsProvider } from "../common/PermissionsProvider";
import { AfriexPermissions } from "../../types/AfriexRoles";

type UserSecurityNoteFormProps = Pick<
  UserDetailsReturnProps,
  "addSecurityNote" | "isAddingSecurityNote" | "user"
>;

const UserSecurityNoteForm = ({
  addSecurityNote,
  isAddingSecurityNote,
  user,
}: UserSecurityNoteFormProps): ReactElement => {
  const [securityNoteInput, setSecurityNoteInput] = useState<string>("");

  const handleSecurityNoteInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSecurityNoteInput(event.target.value);
  };

  function onEnterKey(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      sendSecurityNote();
    }
  }

  const sendSecurityNote = () => {
    addSecurityNote(securityNoteInput);
    setTimeout(() => {
      setSecurityNoteInput("");
    }, 1500);
  };

  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-sm font-semibold text-gray-600">Security Notes</h3>

      <div className="flex justify-between gap-5">
        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_ADD_USER_SECURITY_NOTES}
        >
          <div className="flex w-1/2">
            <input
              onChange={handleSecurityNoteInputChange}
              onKeyUp={onEnterKey}
              name="securityNote"
              type="text"
              value={securityNoteInput}
              className="rounded border border-gray-300 border-r-0  text-gray-900 focus:ring-indigo-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
              placeholder="Add Note"
            />
            <span className="flex items-center rounded-r-md border border-r-md  border-l-0 border-gray-300  text-gray-900 sm:text-sm pr-1">
              <Button
                onClick={sendSecurityNote}
                colorScheme="cyan"
                variant="ghost"
                className="px-0 inline-flex items-center"
                disabled={!securityNoteInput || isAddingSecurityNote}
              >
                {isAddingSecurityNote ? (
                  "Adding..."
                ) : (
                  <>
                    <PlusIcon className="h-4 w-4 mr-1" /> Add
                  </>
                )}
              </Button>
            </span>
          </div>
        </PermissionsProvider>

        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_USER_BLOCK_UNBLOCK}
        >
          <BlockUser user={user} />
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default UserSecurityNoteForm;
