import { useState } from "react";
import { Button, Modal } from "../../components";
import FeeEntry from "./FeeEntry";
import FeeEntryModalContent from "./FeeEntryModalContent";
import { TransactionFee } from "./type";
import { AfriexActions } from "../../types";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../types";
import ConfirmationModal from "../../components/CustomModal/ConfirmationModal";

interface Props {
  overrides: TransactionFee[];
  onSave: (fee: TransactionFee) => void;
  onDelete: (fee: TransactionFee, index: number) => void;
}

interface OverrideToDelete extends TransactionFee {
  index: number;
}

const FeeOverrideSection = ({ overrides, onSave, onDelete }: Props) => {
  const handleAdd = () => {
    setEditingFee({ type: "override" });
  };
  const [editingFee, setEditingFee] = useState<TransactionFee>();
  const [selectedOverride, setSelectedOverride] = useState<OverrideToDelete>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openOverrideDelete = (override: TransactionFee, index: number) => {
    setSelectedOverride({ ...override, index });
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="section">
      <div className="flex flex-row justify-between">
        <h2 className="text-2xl font-semibold">Fee Overrides</h2>
        <PermissionsProvider
          permission={AfriexPermissions.FEES_PAGE}
          action={AfriexActions.CLICK_FEES_ADD_OVERRIDE}
        >
          <Button className="bg-blue-500 text-white" onClick={handleAdd}>
            Add
          </Button>
        </PermissionsProvider>
      </div>
      <div className="mb-8 p-4 rounded-md border-solid border-4 border-gray-100 w-3/4">
        <p>
          Create fees that will override the base fee. Overrides take the
          following priority:
        </p>
        <ul className="flex">
          <li>Exact Currency &gt; </li>
          <li>Destination Currency &gt; </li>
          <li>Payment Method on transaction &gt; </li>
          <li>Base Fee</li>
        </ul>
      </div>
      {overrides.map((override, index) => (
        <FeeEntry
          key={JSON.stringify(Object.values(override))}
          fee={override}
          onEdit={(newOverride) => {
            setEditingFee(newOverride);
          }}
          onDelete={() => openOverrideDelete(override, index)}
        />
      ))}
      <Modal modalOpen={!!editingFee}>
        <FeeEntryModalContent
          fee={editingFee}
          onSave={(newOverride) => {
            setEditingFee(undefined);
            if (!newOverride) {
              // don't save to list
              return;
            }
            onSave?.(newOverride);
          }}
        />
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        label="Are you sure you want to delete this fee?"
        cancelAction={() => setIsConfirmModalOpen(false)}
        cancelActionLabel="No"
        confirmAction={() => {
          setIsLoading(true);
          onDelete(
            selectedOverride as TransactionFee,
            selectedOverride?.index as number
          );
          setTimeout(() => {
            setIsLoading(false);
            setIsConfirmModalOpen(false);
          }, 1000);
        }}
        confirmActionLabel="Yes"
        loadingLabel="Deleting..."
        loading={isLoading}
        title="Confirm to delete"
      />
    </div>
  );
};
export default FeeOverrideSection;
