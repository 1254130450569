import { Link } from "react-router-dom";
import * as formatDate from "../../../utils/dateFormatter";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import TransactionSummary from "../../../components/transactions/TransactionSummary";
import { Badge, Button } from "../../../components";
import { useState } from "react";
import { AfriexTransaction } from "../../../types";
import { capitalizeWords } from "../../../helpers/dashboard";
import { statusColors } from "../../../types/Transaction";
import { getTransactionAmountandAsset } from "../../../constants/formatters";

const TransactionTable = ({ items }: { items: AfriexTransaction[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Amount
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Asset
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Type
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Channel
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date Initiated
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <TRow item={item} key={index} />;
        })}
      </tbody>
    </table>
  );
};

const TRow = ({ item }: { item: AfriexTransaction }) => {
  const textClassName = "text-sm text-gray-800";
  const transactionStatus = item.status;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { amount, asset } = getTransactionAmountandAsset(item);
  return (
    <>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td className="pr-6 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {amount}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {asset}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Badge
            label={transactionStatus}
            colorScheme={statusColors[transactionStatus]}
          />
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left capitalize ${textClassName}`}
        >
          {capitalizeWords(item.type?.replace(/_/g, " "))}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left capitalize ${textClassName}`}
        >
          {capitalizeWords(item.channel?.replace(/_/g, " "))}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(item.createdAt?.toString() ?? "")}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Link
            to={`/transactions/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500">
              <ChevronRightIcon className=" w-5 h-5" />
            </div>
          </Link>
        </td>
      </tr>
      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <TransactionSummary data={item} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TransactionTable;
