import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CustomInput } from "./Input";

type PaginationInputProp = {
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => any;
  onSubmit: () => void;
  isDisabled: boolean;
};
export default function PaginationInput({
  value,
  onChange,
  onSubmit,
  isDisabled,
}: PaginationInputProp) {
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState("w-10");
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  const onInputFocus = () => setShowSubmitButton(true);
  const handleSubmit = () => {
    onSubmit();
    setShowSubmitButton(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow control keys such as Backspace, Tab, etc.
    if (
      ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"].includes(
        event.key
      )
    ) {
      return;
    }
    // Prevent non-numeric key presses
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    // Get pasted data via clipboard API
    const paste = event.clipboardData.getData("text");
    // Prevent the paste if it contains non-numeric characters
    if (!/^\d+$/.test(paste)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (hiddenSpanRef.current) {
      setInputWidth(`${hiddenSpanRef.current.offsetWidth + 20}px`);
    }
  }, [value]);

  return (
    <div className="relative text-gray-700 bg-gray-50 flex items-center border border-cyan-600 rounded-md focus:outline-none">
      <CustomInput
        ref={inputRef}
        className={`max-w-max h-10 text-base font-medium text-cyan-600 placeholder-gray-600 rounded-md focus:border-none focus:outline-none bg-gray-50 border-0 px-0`}
        onChange={onChange}
        value={value}
        onFocus={onInputFocus}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        style={{ width: inputWidth }}
      />
      <span
        ref={hiddenSpanRef}
        className="absolute top-0 left-0 invisible h-0 w-auto whitespace-nowrap overflow-hidden"
      >
        {value}
      </span>
      {showSubmitButton && (
        <button
          className="flex items-center pr-1 rounded-r-lg text-cyan-600 bg-gray-50 hover:text-cyan-700 hover:font-semibold disabled:opacity-75 focus:border-none mr-2 pl-2"
          disabled={isDisabled}
          onClick={handleSubmit}
        >
          <span>
            <ArrowNarrowRightIcon className="w-6 h-6 mt-1 pr-2" />
          </span>
          go
        </button>
      )}
    </div>
  );
}
