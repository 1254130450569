import { getAllRoles, getRolesWithPermission } from "./afriexRolesConfig";
import { AfriexPermissions } from "../types";

/**
 * Returns an object of all page roles.
 *
 * Refer to the README.md file in the `src/config` folder on how to add new roles and permissions
 */
export const PageRoles = {
  ALL_ROLES: getAllRoles(), // HOME page will use this until we set up team specific dashboards
  TRANSACTIONS_PAGE: getRolesWithPermission(
    AfriexPermissions.TRANSACTIONS_PAGE
  ),
  USERS_PAGE: getRolesWithPermission(AfriexPermissions.USERS_PAGE),
  KYC_PAGE: getRolesWithPermission(AfriexPermissions.KYC_PAGE),
  REFERRAL_PAGE: getRolesWithPermission(AfriexPermissions.REFERRAL_PAGE),
  NOTIFICATIONS_PAGE: getRolesWithPermission(
    AfriexPermissions.PUSH_NOTIFICATIONS_PAGE
  ),
  DISPUTES_PAGE: getRolesWithPermission(AfriexPermissions.DISPUTES_PAGE),
  RATES_PAGE: getRolesWithPermission(AfriexPermissions.RATES_PAGE),
  FEES_PAGE: getRolesWithPermission(AfriexPermissions.FEES_PAGE),
  BULK_PAYMENTS_PAGE: getRolesWithPermission(
    AfriexPermissions.BULK_PAYMENTS_PAGE
  ),
  PROCESSORS_PAGE: getRolesWithPermission(AfriexPermissions.PROCESSORS_PAGE),
  BLOCKED_PAGE: getRolesWithPermission(AfriexPermissions.BLOCKED_PAGE),
  REWARDS_PAGE: getRolesWithPermission(AfriexPermissions.REWARDS_PAGE),
  SETTINGS_PAGE: getRolesWithPermission(AfriexPermissions.SETTINGS_PAGE),
};
