import {
  Button,
  SpinningLoader,
  CheckBox,
  AmountInput,
  CustomInput,
} from "../../../components";
import {
  AfriexAmount,
  AfriexReferralTier,
  SupportedCurrencies,
} from "../../../types";
import useTierUpdate from "./useTierUpdate";
import { TierActionType } from "./types";
import { isEmpty } from "lodash";
import { Fragment } from "react";
import CustomModal from "../../../components/CustomModal";
import { UserModalProps } from "../../../components/DeactivateAccount/types";

interface TierFormModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  data?: AfriexReferralTier;
}

const TierFormModal = ({ isOpen, onClose, data }: TierFormModalProps) => {
  const { dispatch, isLoading, handleSubmit, tier } = useTierUpdate(
    onClose,
    data
  );

  const {
    name,
    isDeactivated,
    shouldAutomatePayout,
    shouldSkipReferrerPayout,
    shouldSkipReferreePayout,
    referralCode,
    cumulativeThreshold,
    bonusAmountPerReferree,
    bonusAmountPerReferrer,
    transactionWindow,
  } = tier as AfriexReferralTier;

  const updateAction = {
    type: TierActionType.UPDATE_TIER,
    payload: {},
  };
  const updateLabel = isEmpty(data)
    ? "Create New Referral Tier +"
    : "Update Referral Tier";
  const submitLabel = isEmpty(data) ? "Submit" : "Update";
  const isDisabled = isEmpty(tier);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      height={
        shouldSkipReferrerPayout && shouldSkipReferreePayout
          ? "h-auto"
          : "h-4/5"
      }
      closeOnOutsideClick
      title={updateLabel}
    >
      <div className="px-4">
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(tier as AfriexReferralTier);
            }}
            className="flex flex-col"
          >
            <CheckBox
              label="Mark to skip referrer payout"
              type="checkbox"
              checked={Boolean(shouldSkipReferrerPayout)}
              onChange={(e: any) =>
                dispatch({
                  ...updateAction,
                  payload: {
                    shouldSkipReferrerPayout:
                      Boolean(e.target?.checked) ?? shouldSkipReferrerPayout,
                  },
                })
              }
            />

            <CheckBox
              label="Mark to skip referee payout"
              type="checkbox"
              checked={Boolean(shouldSkipReferreePayout)}
              onChange={(e: any) =>
                dispatch({
                  ...updateAction,
                  payload: {
                    shouldSkipReferreePayout:
                      Boolean(e.target?.checked) ?? shouldSkipReferreePayout,
                  },
                })
              }
            />

            <div className="mt-5">
              <CustomInput
                name="name"
                label="Name"
                value={name ?? ""}
                className="w-full"
                onChange={(e) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      name: e.target?.value ?? name,
                    },
                  })
                }
              />
            </div>

            {!shouldSkipReferrerPayout && (
              <div className="mt-5">
                <AmountInput
                  label="Bonus Amount For Referrer (USD)"
                  fixedCurrency={SupportedCurrencies.USD}
                  value={
                    {
                      amount: bonusAmountPerReferrer as any,
                      currency: SupportedCurrencies.USD,
                    } as any
                  }
                  onChange={(value: AfriexAmount) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        bonusAmountPerReferrer: value?.amount
                          ? Number(value?.amount)
                          : bonusAmountPerReferrer,
                      },
                    })
                  }
                />
              </div>
            )}

            {!shouldSkipReferreePayout && (
              <div className="mt-5">
                <AmountInput
                  label="Bonus Amount Per Referee (USD)"
                  fixedCurrency={SupportedCurrencies.USD}
                  value={
                    {
                      amount: bonusAmountPerReferree as any,
                      currency: SupportedCurrencies.USD,
                    } as any
                  }
                  onChange={(value: AfriexAmount) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        bonusAmountPerReferree: value?.amount
                          ? Number(value?.amount)
                          : bonusAmountPerReferree,
                      },
                    })
                  }
                />
              </div>
            )}

            {(!shouldSkipReferrerPayout || !shouldSkipReferreePayout) && (
              <Fragment>
                <div className="mt-5">
                  <AmountInput
                    fixedCurrency={SupportedCurrencies.USD}
                    value={
                      {
                        amount: cumulativeThreshold as any,
                        currency: SupportedCurrencies.USD,
                      } as any
                    }
                    label="Cumulative Transaction Threshold (USD)"
                    onChange={(value: AfriexAmount) =>
                      dispatch({
                        ...updateAction,
                        payload: {
                          cumulativeThreshold: value?.amount
                            ? Number(value?.amount)
                            : cumulativeThreshold,
                        },
                      })
                    }
                  />
                </div>
                <div className="mt-5">
                  <CustomInput
                    name="transactionWindow"
                    label="Transaction Window (days)"
                    type="number"
                    value={transactionWindow}
                    className="w-full"
                    onChange={(e) =>
                      dispatch({
                        ...updateAction,
                        payload: {
                          transactionWindow: Number(e.target?.value),
                        },
                      })
                    }
                  />
                </div>
                <div className="mt-5">
                  <CheckBox
                    label="Mark to automate payout"
                    type="checkbox"
                    checked={Boolean(shouldAutomatePayout)}
                    onChange={(e: any) =>
                      dispatch({
                        ...updateAction,
                        payload: {
                          shouldAutomatePayout:
                            Boolean(e.target?.checked) ?? shouldAutomatePayout,
                        },
                      })
                    }
                  />
                </div>
              </Fragment>
            )}

            <div className="mt-5">
              <CustomInput
                name="referralCode"
                label="Referral Code (optional)"
                value={referralCode ?? ""}
                className="w-full"
                onChange={(e) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      referralCode: e.target?.value?.toLowerCase() ?? "",
                    },
                  })
                }
              />
            </div>

            <div className="mt-5">
              <CheckBox
                label="Mark as active"
                type="checkbox"
                checked={!isDeactivated}
                onChange={(e: any) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      isDeactivated: !e.target?.checked ?? isDeactivated,
                    },
                  })
                }
              />
            </div>

            <div className="flex w-full justify-end space-x-2 py-5">
              <Button
                type="button"
                onClick={onClose}
                variant="outline"
                colorScheme="cyan"
              >
                Cancel
              </Button>

              <Button
                disabled={isDisabled}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                {submitLabel}
              </Button>
            </div>
          </form>
        )}
      </div>
    </CustomModal>
  );
};

export default TierFormModal;
