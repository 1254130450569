const Visibility = ({
  visible,
  children,
}: {
  visible: boolean;
  children: any;
}) => {
  return visible ? children : null;
};
export default Visibility;
