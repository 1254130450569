import { SearchIcon, XIcon } from "@heroicons/react/solid";
import { ReactElement } from "react";
import useSearchBar from "./useSearchBar";
import { CustomInput } from "../common/Input";

type SearchBarProps = {
  handleSearch: (searchTerm: string) => void;
  handleAutoComplete?: (searchTerm: string) => void;
  placeholder?: string;
  clearFilters?: () => void;
  inputClassName?: string;
};
const SearchBar = ({
  handleSearch,
  handleAutoComplete,
  placeholder,
  clearFilters,
  inputClassName,
}: SearchBarProps): ReactElement => {
  const { searchTerm, handleSearchTermChange } = useSearchBar({
    handleSearch,
    handleAutoComplete,
  });

  const clearSearch = () => {
    handleSearchTermChange({ target: { value: "" } });
    handleSearch("");
    clearFilters?.();
  };

  return (
    <div
      className={`flex items-center h-14 ${inputClassName} border border-gray-300 rounded-md focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md bg-white`}
    >
      <div className="flex w-full items-center">
        <CustomInput
          onChange={handleSearchTermChange}
          aria-label={placeholder}
          value={searchTerm ?? ""}
          name="search"
          type="search"
          placeholder={placeholder ?? "Search Users"}
          leftIcon={<SearchIcon className="h-5 w-5 text-gray-400" />}
          className="border-none focus:shadow-none h-12 w-full isFull"
        />

        <div className="flex gap-2 items-center ml-2">
          <button
            onClick={clearSearch}
            title="Clear search"
            className="h-8 w-8 hidden md:flex rounded-md border border-gray-300 items-center justify-center hover:border-cyan-600 hover:text-cyan-600 group"
          >
            <XIcon className="h-5 w-5 text-gray-400 group-hover:text-cyan-600" />
          </button>
          <p
            onClick={() => handleSearch(searchTerm)}
            className="flex items-center text-sm font-semibold text-white cursor-pointer border-l border-l-gray-300 px-3 bg-cyan-600 h-14 rounded-tr-md rounded-br-md"
          >
            Search
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
