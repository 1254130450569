import {
  Modal,
  Button,
  SpinningLoader,
  Input,
  AmountInput,
} from "../../../components";
import { useCallback, useEffect, useState } from "react";
import { UpdateFormProps } from "./types";
import {
  AfriexAmount,
  AfriexCurrency,
  CurrencyParams,
  SupportedCurrencies,
} from "../../../types";

const SettingsForm: React.FC<UpdateFormProps> = ({
  onClose,
  handleSave,
  item,
}) => {
  const [formValues, setFormValues] = useState<Partial<CurrencyParams>>(
    {} as CurrencyParams
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (item?.params) {
      setFormValues(item?.params);
    }
  }, [item?.params]);

  const handleSubmit = useCallback(async () => {
    const updateData = {
      params: { ...item.params, ...formValues },
    } as Partial<AfriexCurrency>;
    setIsLoading(true);
    await handleSave(item?.id as string, updateData);
    setIsLoading(false);
  }, [formValues, handleSave, item?.id, item.params]);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom"> Settings</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <AmountInput
                  value={{
                    amount: formValues?.referralPayoutRate ?? ("" as any),
                    currency: item?.code as SupportedCurrencies,
                  }}
                  fixedCurrency={item?.code as SupportedCurrencies}
                  label=" Referral Payout Rate"
                  onChange={(value: AfriexAmount) =>
                    setFormValues((x) => ({
                      ...x,
                      referralPayoutRate: value?.amount ?? "",
                    }))
                  }
                />
                <Input
                  value={formValues?.withdrawalFeeBasisPoints ?? ""}
                  type="number"
                  label=" Withdrawal Fee Basis Points"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      withdrawalFeeBasisPoints: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SettingsForm;
