import LoginForm from "./LoginForm";
import useLogin from "./useLogin";

const LoginAuth = () => {
  const { isLoading, handleLogin, handleChange } = useLogin();
  return (
    <LoginForm
      isLoading={isLoading}
      handleLogin={handleLogin}
      handleChange={handleChange}
    />
  );
};

export default LoginAuth;
