import { AfriexOTCVolume, AfriexQueryParams } from "../../../types";

export interface OTCVolumeListProps {
  error: typeof Error | null;
  isLoading: boolean;
  isSearch: boolean;
  isPageReset: boolean;
  pageInfo: {
    total: number;
    page: number;
  };
  showPagination: boolean;
  otcList: AfriexOTCVolume[];
  fetchOTCList: (params: AfriexQueryParams) => void;
  setPage: (page: number) => void;
  filterCount: number;
  currentFilters: AfriexQueryParams;
  filtersToApply: AfriexQueryParams;
  clearFilters: () => void;
  selectAppliedFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  selectCurrentFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  handleApplyFilters: () => void;
  handleAutoComplete?: (search: string) => void;
  handleApprove: (id: string) => Promise<void>;
}

export enum OTCVolumeActionType {
  UPDATE_OTC_RATE = "UPDATE_OTC_RATE",
}

interface UpdateOTCVolumeAction {
  type: OTCVolumeActionType.UPDATE_OTC_RATE;
  payload: Partial<AfriexOTCVolume>;
}

export type OTCVolumeAction = UpdateOTCVolumeAction;

export type OTCFiltersProps = {
  filterCount: number;
  currentFilters: AfriexQueryParams;
  filtersToApply: AfriexQueryParams;
  clearFilters: () => void;
  selectAppliedFilters: (name: string, date: string | Date | undefined) => void;
  handleApplyFilters: () => void;
  selectCurrentFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  handleAutoComplete?: (search: string) => void;
};
