export enum AfriexVerificationStatus {
  NotStarted = "notStarted",
  Submitted = "submitted",
  Pending = "pending",
  Failed = "failure",
  Rejected = "rejected",
  Success = "success",
  ReuploadRequested = "reuploadRequested",
  Reuploaded = "reuploaded",
}

export const kycDisplayStatuses = [
  { key: "Verified", value: AfriexVerificationStatus.Success },
  { key: "Pending", value: AfriexVerificationStatus.Pending },
  { key: "Failed", value: AfriexVerificationStatus.Failed },
  { key: "Not Started", value: AfriexVerificationStatus.NotStarted },
  {
    key: "Reupload Requested",
    value: AfriexVerificationStatus.ReuploadRequested,
  },
];
