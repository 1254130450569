import React from "react";
import useTabs from "./useTabs";

interface TabItem {
  id: string;
  title: string;
  content: React.ReactNode;
  disabled?: boolean;
}

interface TabsProps {
  tabs: TabItem[];
  queryKey?: string; // Sync with URL query parameter
  contentContainerStyle?: string; // For custom height and width
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  queryKey,
  contentContainerStyle,
}) => {
  const { currentTab, handleTabChange } = useTabs(tabs, queryKey);

  return (
    <div className="tabs-component mt-5 hide-scrollbar">
      {/* Tab Headers */}
      <div className="tabs-header flex border-b border-gray-300">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabChange(tab)}
            disabled={tab.disabled}
            className={`tab-item px-4 py-2 text-sm font-medium ${
              tab.disabled
                ? "text-gray-400 cursor-not-allowed"
                : currentTab?.id === tab.id
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div
        className={`tabs-content hide-scrollbar my-5 ${
          contentContainerStyle || "overflow-x-auto"
        }`}
      >
        {currentTab?.content}
      </div>
    </div>
  );
};

export default Tabs;
