import {
  FilePicker,
  ImageViewer,
  Button,
  SpinningLoader,
  DatePicker,
  CustomSelect,
  CustomInput,
} from "../../../components";
import { fetchIDOptions } from "./data";
import {
  AfriexIDType,
  AfriexImageView,
  IDOptionsType,
  SupportedCountryCodes,
} from "../../../types";
import useKYCUpdate from "./useKYCUpdate";
import { ChangeEvent, useEffect, useState } from "react";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";

const UploadKYCModal: React.FC<React.PropsWithChildren<UserModalProps>> = ({
  isOpen,
  onClose,
  user,
}) => {
  const [secondaryIDTypes, setSecondaryIDTypes] = useState<IDOptionsType[]>([]);
  const [primaryIDTypes, setPrimaryIDTypes] = useState<IDOptionsType[]>([]);
  const [hasSecondaryID, setHasSecondaryID] = useState(false);
  const [_, setIsIDOptionsLoading] = useState(true);

  useEffect(() => {
    const fetchAllIDOptions = async () => {
      setIsIDOptionsLoading(true);
      try {
        if (user?.currentCountry) {
          const { primaryIDs, secondaryIDs } = await fetchIDOptions(
            user.currentCountry.toUpperCase() as SupportedCountryCodes
          );
          setSecondaryIDTypes(secondaryIDs);
          setHasSecondaryID(secondaryIDs.length > 0);
          setPrimaryIDTypes(primaryIDs);
        } else {
          setSecondaryIDTypes([]);
          setHasSecondaryID(false);
        }
      } catch (error) {
        console.error("Error fetching ID options:", error);
        setSecondaryIDTypes([]);
        setHasSecondaryID(false);
      } finally {
        setIsIDOptionsLoading(false);
      }
    };

    fetchAllIDOptions();
  }, [user]);

  const {
    handleSubmit,
    handleFile,
    handleDateOfBirth,
    setPrimaryIDType,
    primaryIDType,
    setPrimaryIDNumber,
    primaryIDNumber,
    setSecondaryIDType,
    secondaryIDType,
    setSecondaryIDNumber,
    secondaryIDNumber,
    dateOfBirth,
    selfiePhoto,
    setSelfiePhoto,
    idFront,
    setIdFront,
    idBack,
    setIdBack,
    isIDFrontLoading,
    isIDBackLoading,
    isSelfieLoading,
    isLoading,
  } = useKYCUpdate(user, hasSecondaryID);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title="Upload KYC Details"
    >
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            {selfiePhoto ? (
              <ImageViewer
                onClick={() => setSelfiePhoto("")}
                src={selfiePhoto}
                alt="Selfie Photo"
              />
            ) : (
              <FilePicker
                isLoading={isSelfieLoading}
                label="Selfie Photo"
                name="selfiePhoto"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleFile(e, AfriexImageView.Selfie)
                }
                className="mt-0"
              />
            )}
            {idFront ? (
              <ImageViewer
                onClick={() => setIdFront("")}
                src={idFront}
                alt="ID Front"
              />
            ) : (
              <FilePicker
                isLoading={isIDFrontLoading}
                label="ID Front"
                name="imageUrlFront"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleFile(e, AfriexImageView.Front)
                }
              />
            )}
            {idBack ? (
              <ImageViewer
                onClick={() => setIdBack("")}
                src={idBack}
                alt="ID Back"
              />
            ) : (
              <FilePicker
                isLoading={isIDBackLoading}
                label="ID Back"
                name="imageUrlBack"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleFile(e, AfriexImageView.Back)
                }
              />
            )}
            <div className="mt-5">
              <DatePicker
                label="Date of Birth"
                selectedDate={
                  dateOfBirth &&
                  dateOfBirth.length > 0 &&
                  !isNaN(new Date(dateOfBirth).getTime())
                    ? new Date(dateOfBirth)
                    : new Date()
                }
                setSelectedDate={(date) => handleDateOfBirth(date)}
              />
            </div>
            <div className="mt-3">
              <CustomSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setPrimaryIDType(e.target.value as AfriexIDType)
                }
                value={primaryIDType?.toLocaleLowerCase()}
                name="primaryID"
                options={primaryIDTypes}
                placeholder="Select Primary ID Type"
                label="Primary ID Type"
              />
            </div>
            <div className="mt-3">
              <CustomInput
                onChange={(e) => setPrimaryIDNumber(e.target.value)}
                name="idNumber"
                label="Primary ID Number"
                type="number"
                value={primaryIDNumber ?? ""}
                className="w-full"
                placeholder="Enter Primary ID Number"
              />
            </div>
            {secondaryIDTypes?.length > 0 && (
              <>
                <div className="mt-3">
                  <CustomSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setSecondaryIDType(e.target.value as AfriexIDType)
                    }
                    value={secondaryIDType}
                    name="secondaryID"
                    options={secondaryIDTypes}
                    placeholder="Select Secondary ID Type"
                    label="Secondary ID Type"
                  />
                </div>
                <div className="mt-3">
                  <CustomInput
                    onChange={(e) => setSecondaryIDNumber(e.target.value)}
                    name="idNumber"
                    label="Secondary ID Number"
                    type="number"
                    value={secondaryIDNumber ?? ""}
                    className="w-full"
                    placeholder="Enter Secondary ID Number"
                  />
                </div>
              </>
            )}
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button colorScheme="cyan" onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                Upload Details
              </Button>
            </div>
          </form>
        </>
      )}
    </CustomModal>
  );
};

export default UploadKYCModal;
