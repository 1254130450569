import { AfriexTier, preSaveTier } from "../pages/Promotions";
import { logger } from "../utils/logger";
import axiosApiInstance from "./axios";

export async function getTiers() {
  const response = await axiosApiInstance.get("/v2/admin/tiers");
  return response.data;
}

export async function getTier(id: string) {
  const response = await axiosApiInstance.get(`/v2/admin/tiers/${id}`);
  return response.data;
}

export async function createTier(tier: preSaveTier) {
  try {
    const response = await axiosApiInstance.post("/v2/admin/tiers", tier);
    return response.data;
  } catch (error) {
    logger.error("Error creating tier:", error);
    throw error;
  }
}

export async function updateTier(id: string, tier: Partial<AfriexTier>) {
  try {
    const response = await axiosApiInstance.patch(
      `/v2/admin/tiers/${id}`,
      tier
    );
    return response.data;
  } catch (error) {
    logger.error("Error updating tier:", error);
    throw error;
  }
}

export async function updateTierForUser(userId: string, tierId?: string) {
  try {
    const response = await axiosApiInstance.patch(
      `/v2/admin/users/${userId}/tiers/${tierId ?? ""}`,
      { tierId }
    );
    return response.data;
  } catch (error) {
    logger.error("Error updating tier:", error);
    throw error;
  }
}

export const deleteTierForUser = async (id: string) => {
  try {
    const response = await axiosApiInstance.delete(
      `/v2/admin/users/${id}/tiers`
    );
    return response.data;
  } catch (error) {
    logger.error("Error deleting tier:", error);
    throw error;
  }
};
