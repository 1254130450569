import React, { useState } from "react";
import { AfriexDisputeStatus } from "../../types/AfriexDispute";
import { userService } from "../../services/userService";
import { CustomTextArea } from "../../components/common/Input";
import { Button, CustomSelect } from "../../components";
import { getDisputeStatusOptions } from "../../constants/formatters";
import { AfriexQueryParams } from "../../types";
import CustomModal from "../../components/CustomModal";

interface UpdateDisputeModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDisputeId: string | null;
  setSelectedDisputeId: React.Dispatch<
    React.SetStateAction<UpdateDisputeModalProps["selectedDisputeId"]>
  >;
  fetchDisputes: (params?: AfriexQueryParams) => Promise<void>;
  fetchDisputeCounts: () => Promise<void>;
}

const UpdateDisputeModal = ({
  isOpen,
  onClose,
  selectedDisputeId,
  setSelectedDisputeId,
  fetchDisputes,
  fetchDisputeCounts,
}: UpdateDisputeModalProps) => {
  const [noteText, setNoteText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<AfriexDisputeStatus>(
    AfriexDisputeStatus.PENDING
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const submitDisputeUpdate = async () => {
    if (!selectedDisputeId) {
      return;
    }
    setIsUpdating(true);
    try {
      await userService.updateDispute(selectedDisputeId, {
        status: selectedStatus,
        notes: [
          {
            note: noteText,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        ],
      });
      await fetchDisputes({});
      await fetchDisputeCounts();
      setNoteText("");
      setSelectedStatus(AfriexDisputeStatus.PENDING);
      setSelectedDisputeId(null);
      onClose();
    } catch (error) {
      console.error("Error updating dispute:", error);
    } finally {
      setIsUpdating(false);
    }
  };
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title="Update Dispute"
    >
      <div className="mt-3">
        <CustomSelect
          name="status"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSelectedStatus(e.target.value as AfriexDisputeStatus)
          }
          value={selectedStatus}
          options={getDisputeStatusOptions()}
          placeholder="Select Status"
          label="Status"
        />
      </div>

      <div className="mt-3">
        <CustomTextArea
          label="Message"
          name="message"
          required
          onChange={(e) => setNoteText(e.target.value)}
          value={noteText}
          aria-rowspan={4}
          type="text"
          className="w-full isFull"
          placeholder="Enter your note here..."
        />
      </div>

      <div className="flex justify-end space-x-4 mt-6 mb-3">
        <Button
          onClick={onClose}
          variant="outline"
          colorScheme="cyan"
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          onClick={submitDisputeUpdate}
          variant="solid"
          colorScheme="cyan"
          disabled={noteText.length < 5 || isUpdating}
        >
          {isUpdating ? "Updating..." : "Update"}
        </Button>
      </div>
    </CustomModal>
  );
};

export default UpdateDisputeModal;
