import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import UpdateForm from "./UpdateForm";
import { BlockedPaymentMethod } from "../../../services/blockedPaymentMethodService";
import { AfriexCountry } from "../../../types";

type UpdateButtonProps = {
  handleSave: (body: BlockedPaymentMethod) => Promise<void>;
  countryList: AfriexCountry[];
};

const UpdateButton = ({
  countryList,
  handleSave,
}: UpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = "Add Blocked Payment Destination";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <UpdateForm
      countryList={countryList}
      onClose={toggleFullScreen}
      handleSave={handleSave}
    />
  ) : (
    <Button
      variant={"solid"}
      className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default UpdateButton;
