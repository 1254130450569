import axios from "../api/axios";
import {
  AfriexPaginatedResponse,
  AfriexQueryParams,
  AfriexTransaction,
} from "../types";

export const transactionService = {
  getTransactionList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexTransaction[]>> => {
    const queryParams = {
      page: 0,
      limit: 10,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/transactions?${urlParams}`;
    const response = await axios.get(url);
    return response.data;
  },
  getUserTransactionList: async (
    userId: string,
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexTransaction[]>> => {
    const queryParams = {
      page: 0,
      limit: 10,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/transactions/user/${userId}?${urlParams}`;
    const response = await axios.get(url);
    return response.data;
  },
  getTransaction: async ({ id }: { id: string }) => {
    const url = `/v2/admin/transactions/${id}`;
    const response = await axios.get(url);
    return response.data;
  },
  getRewardTransactions: async (
    userId: string,
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexTransaction[]>> => {
    const queryParams = {
      page: 0,
      limit: 20,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/transactions/rewarded/${userId}?${urlParams}`;
    const response = await axios.get(url);
    return response.data;
  },
};
