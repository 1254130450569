import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { showErrorMessage } from "../utils/showErrorMessage";

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  axiosRequestInterceptor,
  axiosRequestErrorInterceptor
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  axiosResponseInterceptor,
  axiosResponseErrorInterceptor
);

export default axiosApiInstance;

export async function axiosRequestInterceptor(config: AxiosRequestConfig) {
  const accessToken = localStorage.getItem("afriex-token");
  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    "afriex-client": "admin",
    "x-require-token-audience": "true",
  };
  return config;
}

export function axiosRequestErrorInterceptor(error: AxiosError) {
  Promise.reject(error);
}

export function axiosResponseInterceptor(response: AxiosResponse) {
  return response;
}

export async function axiosResponseErrorInterceptor(error: AxiosError) {
  if (error.response?.status === 403) {
    showErrorMessage("You are not authorized to perform this action");
  }
  if (error.response?.status === 401) {
    showErrorMessage("Your login session has expired. Please login again");
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }
  return Promise.reject(error);
}
