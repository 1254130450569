import { ReactComponent as AfriexLogoSvg } from "../../assets/svg/afriex-logo.svg";

export default function AfriexLogo({
  margin,
  label,
}: {
  margin?: string;
  label: string;
}): JSX.Element {
  return (
    <div className={`flex gap-1 ${margin || ""}`}>
      <AfriexLogoSvg className="w-7 h-7" />
      <div className="font-semibold text-gray-900">{label}</div>
    </div>
  );
}
