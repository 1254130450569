import { AfriexVirtualApplication } from "../../../types/AfriexVirtualAccount";
import VirtualAccountApplication from "./VirtualAccountApplication";

const VirtualAccountApplicationView = ({
  data,
}: {
  data: AfriexVirtualApplication;
}) => {
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Global Bank Account Application".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <VirtualAccountApplication data={data} />
    </div>
  );
};

export default VirtualAccountApplicationView;
