import { userService } from "../services/userService";
import { AfriexTransaction, TransactionTypes } from "../types";

export const formatMonetaryValue = (
  value: number,
  isMoney?: boolean,
  fraction?: number
) => {
  const formattedValue =
    typeof value === "number"
      ? isMoney
        ? new Intl.NumberFormat("en-US", {
            maximumFractionDigits: fraction || 2,
            currencyDisplay: "symbol",
            style: "currency",
            currency: "USD",
          }).format(value)
        : new Intl.NumberFormat(
            "en-US",
            fraction ? { maximumFractionDigits: fraction } : undefined
          ).format(value)
      : "0";

  return formattedValue;
};

export function capitalizeWords(str: string) {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const attachAdminUser = async (data: AfriexTransaction) => {
  let { type } = data;
  let adminUser: { name: string; email: string } | null = null;

  if (
    data.initiatedBy &&
    [
      TransactionTypes.DEPOSIT,
      TransactionTypes.WITHDRAW,
      TransactionTypes.WELCOME_BONUS,
    ].includes(data.type)
  ) {
    type = `ADMIN_${data.type}` as TransactionTypes;
    try {
      const getAdminUser = await userService.getUser(data.initiatedBy);
      adminUser = {
        name: getAdminUser.name.fullName,
        email: getAdminUser.currentEmail,
      };
    } catch (error) {
      console.error("Error fetching admin user:", error);
    }
  }

  return {
    type,
    adminUser: adminUser || null,
  };
};
