import { AfriexOTCVolume } from "../../../types";
import { OTCVolumeAction, OTCVolumeActionType } from "./types";

const updateOTCReducer = (state: AfriexOTCVolume, action: OTCVolumeAction) => {
  switch (action.type) {
    case OTCVolumeActionType.UPDATE_OTC_RATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default updateOTCReducer;
