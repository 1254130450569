import { uniq } from "lodash";
import { useState } from "react";
import { Modal } from "../../../components";
import NotifyForm, { NotifyFormData, NotifyFormProps } from "./NotifyForm";
import { Metrics, MetricsData } from "./types";
import { formatTime } from "./utils";
import { processorService } from "../../../services/processorService";
import { toast } from "react-toastify";
import { AfriexActions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../../types";

const MetricsDashboard = ({
  data,
  metrics,
}: {
  metrics?: Metrics;
  data?: MetricsData[];
}) => {
  const [showNofiyForm, setShowNofiyForm] = useState(false);
  const [notifyProps, setNotifyProps] = useState<
    Pick<
      NotifyFormProps,
      | "message"
      | "selectedProcessor"
      | "selectedCorridorPair"
      | "selectedPaymentInstrument"
    >
  >({
    selectedProcessor: "",
    selectedCorridorPair: "",
    selectedPaymentInstrument: "all",
  });

  const createNofiyForm = async (data: NotifyFormData) => {
    try {
      await processorService.createDowntimeNotification(data);
      setShowNofiyForm(false);
      toast.success("Notification created");
    } catch (error) {
      toast.error("Error creating notification");
    }
  };

  if (!data) return null;
  return (
    <div className="container mx-auto p-4">
      <p>Metrics are based on the last 1 hour of transactions.</p>
      <br />
      {metrics && (
        <div className="">
          <div>
            <h2 className="text-xl font-bold mb-2">
              Success Rate % per Corridor
            </h2>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">Corridor</th>
                  <th className="border border-gray-300 px-4 py-2">
                    Time to Success (Avg)
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Prev Time to Success (Avg)
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    &#916; in Time to Success (Avg)
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Processor
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Success Rate (%)
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Notify</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(metrics.corridorSuccessRates)
                  .sort(
                    (a, b) =>
                      metrics.corridorSuccessRates[a] -
                      metrics.corridorSuccessRates[b]
                  )
                  .map((corridor) => {
                    const processor = data.find(
                      (d) => d.corridor_pair === corridor
                    )?.processor;
                    const avgDuration =
                      metrics.avgDurationPerCorridor[corridor];
                    const prevDuration =
                      metrics.previousAvgDurationPerCorridor[corridor];
                    const deltaDuration =
                      metrics.deltaDurationPerCorridor[corridor];
                    const successRate = metrics.corridorSuccessRates[corridor];
                    return (
                      <tr
                        key={corridor}
                        className={successRate < 85 ? "bg-red-100" : ""}
                      >
                        <td className="border border-gray-300 px-4 py-2">
                          {corridor}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {formatTime(avgDuration)}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {formatTime(prevDuration)}
                        </td>
                        <td
                          className={`border border-gray-300 px-4 py-2 ${deltaDuration > 0 ? "text-red-500" : ""}`}
                        >
                          {formatTime(deltaDuration)}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {processor}
                        </td>
                        <td
                          className={`border border-gray-300 px-4 py-2 ${successRate < 85 ? "text-red-500" : ""}`}
                        >
                          {successRate.toFixed(2)}
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          <PermissionsProvider
                            permission={AfriexPermissions.PROCESSORS_PAGE}
                            action={AfriexActions.CLICK_PROCESSORS_NOTIFY}
                          >
                            <button
                              className={`bg-blue-500 text-white px-2 py-1 rounded ${successRate < 50 ? "opacity-50" : ""}`}
                              disabled={successRate < 50}
                              onClick={() => {
                                setShowNofiyForm(true);
                                if (!processor || !corridor) return;
                                setNotifyProps({
                                  message: `We are currently expericing delays on ${corridor} with a completion time of ${formatTime(avgDuration)}.`,
                                  selectedProcessor: processor,
                                  selectedCorridorPair: corridor,
                                  selectedPaymentInstrument: "all",
                                });
                              }}
                            >
                              Notify
                            </button>
                          </PermissionsProvider>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Modal modalOpen={showNofiyForm}>
              <NotifyForm
                onClose={() => setShowNofiyForm(false)}
                processors={uniq(data.map((d) => d.processor))}
                corridorPairs={uniq(data.map((d) => d.corridor_pair))}
                paymentInstruments={uniq(data.map((d) => d.payment_instrument))}
                {...notifyProps}
                onSubmit={(data) => {
                  createNofiyForm(data);
                }}
              />
            </Modal>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-bold mb-2">
              Success Rate % per Processor
            </h2>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">
                    Processor
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Success Rate (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(metrics.processorSuccessRates)
                  .sort(
                    (a, b) =>
                      metrics.processorSuccessRates[a] -
                      metrics.processorSuccessRates[b]
                  )
                  .map((processor, index) => (
                    <tr
                      key={processor}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                    >
                      <td className="border border-gray-300 px-4 py-2">
                        {processor}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {metrics.processorSuccessRates[processor].toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-bold mb-2">
              Payment Instrument Success Rate
            </h2>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">
                    Payment Instrument
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Success Rate (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(metrics.paymentInstrumentSuccessRates)
                  .sort(
                    (a, b) =>
                      metrics.paymentInstrumentSuccessRates[a] -
                      metrics.paymentInstrumentSuccessRates[b]
                  )
                  .map((paymentInstrument, index) => (
                    <tr
                      key={paymentInstrument}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                    >
                      <td className="border border-gray-300 px-4 py-2">
                        {paymentInstrument}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {metrics.paymentInstrumentSuccessRates[
                          paymentInstrument
                        ].toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetricsDashboard;
