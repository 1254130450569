import { ReactElement } from "react";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import TransactionFilters from "../../../components/transactions/TransactionFilters";
import { TransactionFiltersProps } from "./types";
import { AfriexActions, AfriexPermissions } from "../../../types";
const TransactionFilterBar = ({
  transactions,
  filterCount,
  currentFilters,
  filtersToApply,
  clearFilters,
  selectCurrentFilters,
  selectAppliedFilters,
  handleApplyFilters,
}: TransactionFiltersProps): ReactElement => (
  <PermissionsProvider
    permission={AfriexPermissions.TRANSACTIONS_PAGE}
    action={AfriexActions.CLICK_TRANSACTION_SEARCH}
  >
    <TransactionFilters
      transactions={transactions}
      filterCount={filterCount}
      currentFilters={currentFilters}
      filtersToApply={filtersToApply}
      clearFilters={clearFilters}
      selectCurrentFilters={selectCurrentFilters}
      selectAppliedFilters={selectAppliedFilters}
      handleApplyFilters={handleApplyFilters}
    />
  </PermissionsProvider>
);
export default TransactionFilterBar;
