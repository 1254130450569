import { CurrencyAccordion, ProcessorAccordion } from "./Accordion";

const ProcessorMetricsGrouped = ({ data }: any) => {
  const { groupedByCurrency, groupedByProcessor } = data ?? {};
  if (!groupedByCurrency || !groupedByProcessor) return null;
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold my-4">Metrics Dashboard</h1>
      <div>
        <div>
          <h2 className="text-xl font-bold mb-2">Grouped by Processor</h2>
          <ProcessorAccordion data={data.groupedByProcessor} />
        </div>
        <div className="mt-8" />
        <div>
          <h2 className="text-xl font-bold mb-2">Grouped by Currency</h2>
          <CurrencyAccordion data={data.groupedByCurrency} />
        </div>
      </div>
    </div>
  );
};

export default ProcessorMetricsGrouped;
