/**
 * This helper function converts a string to title case
 * @param str The string to convert
 * @returns
 */
export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
