import { ArbitrageCycle } from "../../types";

export const calculateArbitrageProfit = (cycle: ArbitrageCycle) => {
  const amounts = cycle?.amounts ?? [];
  const totalAmount = amounts.reduce(
    (accumulatedAmount, currentAmount) => accumulatedAmount * currentAmount
  );
  const profit = totalAmount - 1;
  return profit * 100;
};
