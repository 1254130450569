import { useEffect, useState } from "react";
import {
  createTier,
  deleteTierForUser,
  getTier,
  getTiers,
  updateTier,
  updateTierForUser,
} from "../../api/tiers";
import { logger } from "../../utils/logger";
import { AfriexTier, preSaveTier } from "./index";

export function usePromotions() {
  const [promotions, setPromotions] = useState<AfriexTier[]>([]);

  useEffect(() => {
    const fetchPromotions = async () => {
      await getPromotions();
    };
    fetchPromotions();
  }, []);

  const createPromotion = async (promotionData: preSaveTier) => {
    try {
      const response = await createTier(promotionData);
      setPromotions((prevPromotions) => {
        return [...prevPromotions, response];
      });
      return response;
    } catch (error) {
      logger.error("Failed to create tier: ", error);
      throw error;
    }
  };

  const getPromotion = async (promotionId: string) => {
    return await getTier(promotionId);
  };

  const getPromotions = async () => {
    const tiers = await getTiers();
    setPromotions(tiers);
    return tiers;
  };

  const updatePromotion = async (
    promotionId: string,
    promotionData: Partial<AfriexTier>
  ) => {
    const response = await updateTier(promotionId, promotionData);
    setPromotions((prevPromotions) =>
      prevPromotions.map((promo) =>
        promo.id === promotionId ? { ...promo, ...response } : promo
      )
    );
  };

  const updatePromotionForUser = async (
    userId: string,
    promotionId?: string
  ) => {
    return await updateTierForUser(userId, promotionId);
  };

  const deletePromotionForUser = async (userId: string) => {
    return await deleteTierForUser(userId);
  };

  return {
    createPromotion,
    getPromotion,
    getPromotions,
    updatePromotion,
    updatePromotionForUser,
    deletePromotionForUser,
    promotions,
  };
}
