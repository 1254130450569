// todo! fix onchange type of any

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  [rest: string]: any;
}

// ---- Phase this out gradually for the Select in the /components/Input file ----
interface SelectProps extends InputProps {
  options: { value: string; label: string }[];
}
export function Select({
  label,
  options,
  placeholder = "Select",
  ...rest
}: SelectProps): JSX.Element {
  return (
    <label className="block">
      <span className="text-gray-700">{label}</span>
      <select
        {...rest}
        className="block w-full mt-1 text-gray-900 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="" className="text-gray-900 sm:text-sm" disabled={true}>
          {placeholder}
        </option>

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
}
