import { useState } from "react";
import { Modal, Button, SpinningLoader, TextArea } from "../../../components";
import useReward from "../List/useReward";
import { isEmpty } from "lodash";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";
import { CustomTextArea } from "../../../components/common/Input";

interface TierCardFormModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  cardId?: string;
  rewardId: string;
}

const TierCardFormModal = ({
  isOpen,
  onClose,
  cardId,
  rewardId,
}: TierCardFormModalProps) => {
  const [codes, setCodes] = useState("");
  const { isCardLoading: isLoading, handleAddCards } = useReward();

  const updateLabel = isEmpty(cardId)
    ? "Add Cards To Reward Item +"
    : "Remove Cards From Reward Item";
  const submitLabel = isEmpty(cardId) ? "Submit" : "Remove";
  const isDisabled = isEmpty(codes);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title={updateLabel}
    >
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddCards(codes, rewardId) as any;
            }}
            className="flex flex-col space-y-5"
          >
            <div className="mt-3">
              <CustomTextArea
                name="codes"
                label="Enter card codes"
                onChange={(e) => setCodes(e.target?.value ?? codes)}
                value={codes ?? ""}
                aria-rowspan={3}
                type="text"
                placeholder="Enter card codes one on each line"
                className="w-full"
                required
              />
            </div>

            <div className="flex w-full justify-end space-x-2 py-5">
              <Button
                type="button"
                onClick={onClose}
                variant="outline"
                colorScheme="cyan"
              >
                Cancel
              </Button>

              <Button
                disabled={isDisabled}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                {submitLabel}
              </Button>
            </div>
          </form>
        </>
      )}
    </CustomModal>

    // <Modal modalOpen>
    //   <div className="w-96 my-3 mx-5">
    //     <h3 className="text-lg  border-bottom">{updateLabel}</h3>

    //   </div>
    // </Modal>
  );
};

export default TierCardFormModal;
