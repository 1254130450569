import { FilterIcon } from "@heroicons/react/solid";
import useCloseOnOutsideClick from "../../hooks/useCloseOnOutsideClick";

export interface FilterProps {
  handleShowFilter: () => void;
  closeFilter: () => void;
  label: string;
  bodyClassName?: any;
}
export const Filter: React.FC<React.PropsWithChildren<FilterProps>> = ({
  handleShowFilter,
  closeFilter,
  bodyClassName,
  children,
}) => {
  const containerRef = useCloseOnOutsideClick<HTMLDivElement>(closeFilter);
  return (
    <div className="relative" ref={containerRef}>
      <div
        onClick={handleShowFilter}
        className="flex items-center border border-gray-300 rounded-md px-3 h-10 cursor-pointer bg-white"
      >
        <FilterIcon className="h-5 w-5 text-gray-400 mr-2" />
        <p className="text-base font-semibold text-gray-700">Filters</p>
      </div>
      {children && (
        <div
          className={`absolute z-10 transform mt-1 bg-white shadow-lg border border-gray-300 rounded-lg ${bodyClassName}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

// Use this to center filter body when needed
// -translate-x-1/2  left-1/2
