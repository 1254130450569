import {
  IBulkPushNotification,
  ISinglePushNotification,
} from "../types/PushNotification";
import axios from "./axios";

// Send Push Notification
export async function sendBulkPushNotification(
  notificationDetails: IBulkPushNotification
) {
  const response = await axios.post(
    "/v2/admin/notifications/all",
    notificationDetails
  );
  return response.data;
}

export async function sendSinglePushNotification(
  notificationDetails: ISinglePushNotification
) {
  const response = await axios.post(
    "/v2/admin/notifications/single",
    notificationDetails
  );
  return response.data;
}
