import jsPDF from "jspdf";
import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "..";

const GenerateReceiptButton = ({
  fileName,
  label,
}: {
  fileName: string;
  label: string;
}) => {
  const generatePDF = async () => {
    const element = document.getElementById("receipt-parent");
    const noPrintElements = document.getElementsByClassName("no-print");

    if (!element) {
      console.error("Element with id 'receipt-parent' not found");
      return;
    }

    // Convert HTMLCollection to Array for easier manipulation
    const elementsArray = Array.from(noPrintElements);

    // Store original display styles to restore them later
    const originalDisplayStyles: { element: HTMLElement; display: string }[] =
      elementsArray.map((el) => ({
        element: el as HTMLElement,
        display: (el as HTMLElement).style.display,
      }));

    // Hide all no-print elements
    elementsArray.forEach((el) => {
      (el as HTMLElement).style.display = "none";
    });

    // Use setTimeout to ensure the DOM updates before generating PDF
    setTimeout(() => {
      try {
        const contentWidth = element.offsetWidth;
        const contentHeight = element.scrollHeight + 5;

        const pdf = new jsPDF({
          unit: "px",
          format: [contentWidth, contentHeight],
        });

        pdf.html(element, {
          callback: function (pdfInstance) {
            const curTime = new Date().getTime();
            pdfInstance.save(`${curTime}_${fileName ?? ""}_afriex.pdf`);

            // After saving the PDF, use setTimeout to restore the elements
            setTimeout(() => {
              originalDisplayStyles.forEach(({ element, display }) => {
                element.style.display = display;
              });
            }, 100); // Adjust the delay as needed
          },
          x: 0,
          y: 0,
          html2canvas: {
            scale: 1,
            logging: true,
            useCORS: true,
            windowWidth: contentWidth,
            windowHeight: contentHeight,
            removeContainer: true,
          },
        });
      } catch (error) {
        console.error("Error generating PDF:", error);

        // Even if there's an error, restore the elements
        setTimeout(() => {
          originalDisplayStyles.forEach(({ element, display }) => {
            element.style.display = display;
          });
        }, 100);
      }
    }, 100); // Adjust the delay as needed
  };

  return (
    <Button
      onClick={generatePDF}
      colorScheme="cyan"
      variant="outline"
      leftIcon={<DownloadIcon className="h-5 w-5" />}
      className="bg-indigo-200"
    >
      {label}
    </Button>
  );
};

export default GenerateReceiptButton;
