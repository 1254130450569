import React, { useState } from "react";

export type NotifyFormProps = {
  message?: string;
  processors: string[];
  selectedProcessor: string;
  corridorPairs: string[];
  selectedCorridorPair: string;
  paymentInstruments: string[];
  selectedPaymentInstrument: string;
  onSubmit: (data: NotifyFormData) => void;
  onClose: () => void;
};

export type NotifyFormData = {
  title?: string;
  message: string;
  ttl: string;
  selectedProcessor: string;
  selectedCorridorPair?: string; // undefined if all
  selectedPaymentInstrument?: string; // undefined if all
};

const NotifyForm = ({
  message: initialMessage,
  processors,
  selectedProcessor: processor,
  corridorPairs,
  selectedCorridorPair: corridorPair,
  paymentInstruments,
  selectedPaymentInstrument: paymentInstrument,
  onSubmit,
  onClose,
}: NotifyFormProps) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState(initialMessage ?? "");
  const [ttl, setTtl] = useState("2hrs");
  const [selectedProcessor, setSelectedProcessor] = useState(processor);
  const [selectedCorridorPair, setSelectedCorridorPair] =
    useState(corridorPair);
  const [selectedPaymentInstrument, setSelectedPaymentInstrument] =
    useState(paymentInstrument);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // clean up
    const corridorPair =
      selectedCorridorPair === "all" ? undefined : selectedCorridorPair;
    const paymentInstrument =
      selectedPaymentInstrument === "all"
        ? undefined
        : selectedPaymentInstrument;

    onSubmit({
      title,
      message,
      ttl,
      selectedProcessor,
      selectedCorridorPair: corridorPair,
      selectedPaymentInstrument: paymentInstrument,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div>
        <h6> Displayed as callout on mobile</h6>
        <div>
          {/*Callout*/}
          <div className="items-center flex">
            <div className="flex items-center rounded-lg border-2  border-gray-200 px-4 py-3 text-sm w-96 bg-yellow-100">
              <div className="">
                <div className="text-sm font-bold ">
                  <span className="mr-2">&#9888;</span>
                  {title}
                </div>
                <div className="mt-1 text-xs">{message}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold my-4">Downtime Notification</h1>
        <button
          type="button"
          className="px-4 py-2 rounded"
          onClick={() => onClose()}
        >
          X
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Title:</label>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Corridor Pair Downtime"
          ></input>
        </div>
        <div>
          <label className="block text-gray-700">Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows={4}
            required
          ></textarea>
        </div>
        <div className="flex space-x-4">
          <div>
            <label className="block text-gray-700">Processor:</label>
            <select
              value={selectedProcessor}
              onChange={(e) => setSelectedProcessor(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select Processor</option>
              {processors.map((processor, index) => (
                <option key={index} value={processor}>
                  {processor}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Corridor Pair:</label>
            <select
              value={selectedCorridorPair}
              onChange={(e) => setSelectedCorridorPair(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select Corridor Pair</option>
              {corridorPairs.map((pair, index) => (
                <option key={index} value={pair}>
                  {pair}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Payment Instrument:</label>
            <select
              value={selectedPaymentInstrument}
              onChange={(e) => setSelectedPaymentInstrument(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select Payment Instrument</option>
              {/* all processors */}
              <option
                value="all"
                selected={selectedPaymentInstrument === "all"}
              >
                All Payment Instruments
              </option>
              {paymentInstruments.map((instrument, index) => (
                <option key={index} value={instrument}>
                  {instrument}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label className="block text-gray-700">TTL: (Expires after)</label>
          <select
            value={ttl}
            onChange={(e) => setTtl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="2hrs">2 hrs</option>
            <option value="1hr">1 hr</option>
            <option value="30mins">30 mins</option>
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Create Notification
          </button>
        </div>
      </form>
    </div>
  );
};

export default NotifyForm;
