export enum AfriexIDType {
  AlienCard = "alien_card",
  Bvn = "bvn",
  DriversLicense = "drivers_license",
  Itin = "itin",
  NationalId = "national_id",
  NationalIdNoPhoto = "national_id_no_photo",
  NewVoterId = "new_voter_id",
  Nin = "nin",
  NinSlip = "nin_slip",
  ResidencePermit = "Residence_permit",
  Passport = "passport",
  PhoneNumber = "phone_number",
  Ssn = "ssn",
  SsnLast4 = "ssn_last4",
  Ssnit = "ssnit",
  VoterId = "voter_id",
  VotersCard = "voters_card",
}

export type IDOptionsType = {
  value: AfriexIDType;
  key: string;
};
