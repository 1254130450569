import CustomModal from ".";
import { ConfirmModalProps } from "../DeactivateAccount/types";
import { Button } from "..";

const ConfirmationModal: React.FC<
  React.PropsWithChildren<ConfirmModalProps>
> = ({
  isOpen,
  label,
  cancelAction,
  cancelActionLabel,
  confirmAction,
  confirmActionLabel,
  loadingLabel,
  loading,
  title,
  width,
  children,
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={cancelAction}
      width={width || "w-3/12"}
      height="h-auto"
      title={title}
      zIndex={99}
      hideX
    >
      <p className="justify-self-center gap-2 justify-center text-center text-gray-800 w-72 text-sm">
        {label}
      </p>

      {children}

      <div className="flex justify-evenly mt-10 mb-10">
        <Button
          colorScheme="green"
          onClick={confirmAction}
          className="h-10"
          isLoading={loading}
          loadingText={loadingLabel}
        >
          {confirmActionLabel}
        </Button>

        <Button
          colorScheme="red"
          onClick={cancelAction}
          className="w-16 h-10"
          disabled={loading}
        >
          {cancelActionLabel}
        </Button>
      </div>
    </CustomModal>
  );
};

export default ConfirmationModal;
