import {
  AdminTransactionTypes,
  TransactionTypes,
} from "../../types/Transaction";
import { dateTimeInTimeZone } from "../../utils/dateFormatter";
import { getNigeriaBankName } from "../../utils/bankUtil";
import { SupportedAssets } from "../../types/AssetTypes";
import {
  AfriexTransaction,
  AfriexTransactionStatus,
  RatesMap,
} from "../../types";
import { capitalizeWords } from "../../helpers/dashboard";
import { capitalize } from "lodash";

const statusByTnxType: { [key: string]: AfriexTransactionStatus | null } = {
  "escrow-fulfill": AfriexTransactionStatus.SUCCESS,
  escrow: AfriexTransactionStatus.SUCCESS,
  "escrow-refund": AfriexTransactionStatus.SUCCESS,
  "sell-transactions": null,
  reversal: null,
  referral: AfriexTransactionStatus.SUCCESS,
  "transfer-fiat": AfriexTransactionStatus.SUCCESS,
  transactions: AfriexTransactionStatus.SUCCESS,
  "banking-deposit": AfriexTransactionStatus.SUCCESS,
  "crypto-tx": AfriexTransactionStatus.SUCCESS,
  swap: AfriexTransactionStatus.SUCCESS,
  bank_transfer: AfriexTransactionStatus.SUCCESS,
  "card-charge": AfriexTransactionStatus.SUCCESS,
  admin_credit: AfriexTransactionStatus.SUCCESS,
  admin_debit: AfriexTransactionStatus.SUCCESS,
  default: AfriexTransactionStatus.SUCCESS,
};

export function getTransactionStatus(
  transaction: AfriexTransaction
): AfriexTransactionStatus | null {
  if (Object.prototype.hasOwnProperty.call(statusByTnxType, transaction.type))
    return statusByTnxType[transaction.type] || transaction.status;
  else return statusByTnxType.default;
}

export const showCurrencyRate = (transaction: AfriexTransaction): string => {
  const ratesObject = transaction?.rates as RatesMap;
  const sourceAsset = transaction.sourceAsset as SupportedAssets;
  const destinationAsset = transaction.destinationAsset as SupportedAssets;
  const nestedRate =
    ratesObject?.[sourceAsset as SupportedAssets]?.[
      destinationAsset as SupportedAssets
    ] ?? 0;
  const flattenedRate = ratesObject?.[destinationAsset as SupportedAssets] ?? 0;
  const exchangeRate =
    flattenedRate?.constructor === Object ? nestedRate : flattenedRate;
  return `1 ${transaction.sourceAsset} = ${exchangeRate} ${
    transaction?.destinationAsset as string
  }`;
};

interface DisplayField {
  [key: string]: string | number | any;
}

export function getFieldsToDisplay(
  transaction: AfriexTransaction
): DisplayField[] {
  const deviceInfoFields = [
    { "Customer Device Id": transaction?.deviceInfo?.deviceId },
    { "Customer Device Type": transaction?.deviceInfo?.deviceType },
    {
      "Customer Device Name": transaction?.deviceInfo?.deviceName?.replace(
        /[{}]/g,
        ""
      ),
    },
  ];

  const typeCheck =
    Object.values(AdminTransactionTypes).find(
      (key) =>
        key ===
        AdminTransactionTypes[
          transaction.type as unknown as keyof typeof AdminTransactionTypes
        ]
    ) || transaction.type;

  switch (typeCheck) {
    case TransactionTypes.WITHDRAW:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { "Device IP": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { Processor: transaction.processor },
        { "Fulfillment Amount": transaction.destinationAmount },
        { "Fulfillment Asset": transaction.destinationAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { "Initiated By": transaction?.adminUser?.name },
        { Reason: capitalize(transaction.reason?.replace(/_/g, " ")) },
        { "Processor Reference": transaction.processorTransactionId },
        { "Internal Reference": transaction.internalTransactionId },
        {
          "Session ID": transaction?.processorResponseMeta?.sessionId,
        },
        {
          "Bank name":
            transaction.destinationAccountDetails?.bankName ||
            getNigeriaBankName(transaction.destinationAccountDetails?.bankCode),
        },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        {
          "Bank account name":
            transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Name": transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Tier Id": transaction?.tierId ?? "None" },
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        {
          "Status Description":
            transaction?.processorResponseMeta?.statusMessage ||
            transaction?.processorResponseMeta?.responseMessage ||
            transaction?.processorResponseMeta?.transactionStatusDescription,
        },
        ...deviceInfoFields,
      ];

    case TransactionTypes.DEPOSIT:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        {
          "Ledger Transaction IDs": transaction.ledgerTransactionIds,
        },
        { Description: transaction.type },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { Amount: transaction.destinationAmount },
        { Currency: transaction.destinationAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { "Fulfillment Amount": transaction.sourceAmount },
        { "Fulfillment Asset": transaction.sourceAsset },
        { "Customer ID": transaction.sourceUserId },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Processor: transaction.processor },

        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalize(
            transaction?.reason?.toString().replace(/_/g, " ")
          ),
        },
        { Fee: transaction.fee },
        { "Processor Reference": transaction.processorTransactionId },
        {
          "Stripe Payment Intent Id":
            transaction.processorResponseMeta?.paymentIntentId,
        },
        { "Internal Reference": transaction.internalTransactionId },
        { Status: transaction.status },
        { "Device IP": transaction.ip },
        ...deviceInfoFields,
      ];

    case TransactionTypes.REVERSAL:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Description: transaction.type },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { "Fulfillment Amount": transaction.destinationAmount },
        { "Fulfillment Asset": transaction.destinationAsset },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        { "Processor Reference": transaction.processorTransactionId },
        { "Internal Reference": transaction.internalTransactionId },
        { "Session ID": transaction?.processorResponseMeta?.sessionId },
        { "Bank name": transaction.destinationAccountDetails?.bankName },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        {
          "Bank account name":
            transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Name": transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Customer ID": transaction.destinationUserId },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Status: transaction.status },
        { "Device IP": transaction.ip },
      ];

    case TransactionTypes.REFERRAL:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Status: transaction.status },
        { "Device IP": transaction.ip },
        ...deviceInfoFields,
      ];

    case TransactionTypes.TRANSFER:
      return [
        { "Type of Transaction": `User to User` },
        {
          "Transaction ID": transaction.id,
        },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { Description: transaction.processorResponseMeta?.description },
        { "Sender Username": transaction.sourceAccountDetails?.name },
        { "Receiver Username": transaction.destinationAccountDetails?.name },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { Sender: transaction.sourceUserId },
        { Receiver: transaction.destinationUserId },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { "Recipient Amount": transaction.destinationAmount },
        { "Recipient Currency": transaction.destinationAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Status: "Successful" },
        { "Device IP": transaction.ip },
        ...deviceInfoFields,
      ];

    case TransactionTypes.SWAP:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { Description: transaction.type },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction ID": transaction.ledgerTransactionIds },
        { "Device IP": transaction.ip },
        { "Fulfillment Asset": transaction.destinationAsset },
        { "Fulfillment Amount": transaction.destinationAmount },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Fee: transaction.fee },
        { Amount: transaction.sourceAmount },
        { Currency: transaction.sourceAsset },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { "Customer Id": transaction.sourceUserId },
        { "Initiated By": transaction.initiatedBy },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];

    // this covers includes bulkpayment, agentdeposit, and others not cased above
    default:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction ID": transaction.ledgerTransactionIds },
        { "Device IP": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": dateTimeInTimeZone(transaction.updatedAt?.toString()),
        },
        { Processor: transaction.processor },

        { "Fulfillment Amount": transaction.sourceAmount },
        { "Fulfillment Asset": transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Amount: transaction.destinationAmount },
        { Currency: transaction.destinationAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalize(
            transaction?.reason?.toString().replace(/_/g, " ")
          ),
        },
        { Fee: transaction.fee },
        { "Processor Reference": transaction.processorTransactionId },
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
  }
}
