import { useState } from "react";
import { InfoBox } from "../../../components";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import CustomModal from "../../../components/CustomModal";
import {
  AfriexActions,
  AfriexPermissions,
  ArbitrageCheckInfo,
  RatesMap,
} from "../../../types";
import { ArbitragePairwiseReporter } from "./ArbitragePairwiseReporter";
import { ArbitrageReporter } from "./ArbitrageReporter";

type ArbitrageDisplayProps = {
  isOpen: boolean;
  arbitrageResult: ArbitrageCheckInfo;
  rates?: RatesMap;
  tolerance: number;
  onToleranceChange?: (e: any) => void;
  onClose?: () => void;
};

const ArbitrageDisplay = ({
  arbitrageResult,
  onToleranceChange,
  tolerance,
  isOpen,
  onClose,
}: ArbitrageDisplayProps) => {
  const [showArbitrageModal, setShowArbitrageModal] = useState(isOpen);
  const ToleranceInput = () => (
    <div className="w-full flex justify-end">
      <div>
        <label
          htmlFor="tolerance"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Percentage Tolerance
        </label>
        <input
          type="number"
          id="tolerance"
          className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Enter Tolerance"
          defaultValue={tolerance}
          onChange={onToleranceChange}
          max={100}
          min={0}
        />
      </div>
    </div>
  );

  if (!arbitrageResult.hasArbitrage) {
    return (
      <div className="p-3">
        <InfoBox>
          No arbitrage detected using the tolerance of {tolerance}%
        </InfoBox>
        <PermissionsProvider
          permission={AfriexPermissions.RATES_PAGE}
          action={AfriexActions.CLICK_RATES_TOLERANCE_INPUT}
        >
          {onToleranceChange && <ToleranceInput />}
        </PermissionsProvider>
      </div>
    );
  }

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
    setShowArbitrageModal(false);
  };

  return (
    <CustomModal
      isOpen={showArbitrageModal}
      onClose={closeModal}
      width="w-2/5"
      height="h-3/5"
      title="ℹ️ Arbitrage Detected"
      zIndex={20}
      closeOnOutsideClick
    >
      <div className="flex flex-col items-center gap-2 p-3">
        <PermissionsProvider
          permission={AfriexPermissions.RATES_PAGE}
          action={AfriexActions.CLICK_RATES_TOLERANCE_INPUT}
        >
          {onToleranceChange && <ToleranceInput />}
        </PermissionsProvider>
        <div className="flex flex-col p-4 mb-4 text-sm text-red-800 rounded-lg dark:bg-gray-800 dark:text-red-400">
          <div className="ml-2">
            {arbitrageResult.cycles?.map((cycle, index) => (
              <ArbitrageReporter key={index} {...cycle} tolerance={tolerance} />
            ))}
            <ArbitragePairwiseReporter
              {...arbitrageResult.pairWiseDiscrepancy}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ArbitrageDisplay;
