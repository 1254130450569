import axios from "../api/axios";
import {
  AfriexPaginatedResponse,
  AfriexPerson,
  AfriexTier,
  AfriexTransaction,
} from "../types";
import { AfriexQueryParams } from "../types/AfriexQueryParams";

export const tierService = {
  getTierList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexTier[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/tiers?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getTierUserList: async (
    tierId: string,
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<Partial<AfriexPerson>[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/tiers/users/${tierId}?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getTierTransactionList: async (
    tierId: string,
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<Partial<AfriexTransaction>[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `/v2/admin/tiers/transactions/${tierId}?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getTier: async (tierId: string): Promise<AfriexTier> => {
    const url = `/v2/admin/tiers/${tierId}`;
    const response = await axios.get(url);
    return response?.data;
  },
  updateTier: async (
    tierId: string,
    tier: Partial<AfriexTier>
  ): Promise<AfriexTier> => {
    const url = `/v2/admin/tiers/${tierId}`;
    const response = await axios.patch(url, tier);
    return response?.data;
  },
  createTier: async (tier: Partial<AfriexTier>): Promise<AfriexTier> => {
    const url = `/v2/admin/tiers`;
    const response = await axios.post(url, tier);
    return response?.data;
  },
};
