import {
  Modal,
  Button,
  SpinningLoader,
  AmountInput,
} from "../../../components";
import { useCallback, useEffect, useState } from "react";
import { UpdateFormProps } from "./types";
import {
  AfriexAmount,
  AfriexCountry,
  AfriexReferralConfig,
  SupportedCurrencies,
} from "../../../types";

const ReferralSettingsForm = ({
  onClose,
  handleSave,
  item,
}: UpdateFormProps) => {
  const [formValues, setFormValues] = useState<Partial<AfriexReferralConfig>>(
    {} as AfriexReferralConfig
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (item?.params?.referralParams) {
      setFormValues(item?.params?.referralParams);
    }
  }, [item?.params?.referralParams]);

  const handleSubmit = useCallback(async () => {
    const updateData = {
      params: { ...item.params, referralParams: formValues },
    } as Partial<AfriexCountry>;
    setIsLoading(true);
    await handleSave(String(item?.id), updateData);
    setIsLoading(false);
  }, [formValues, handleSave, item?.id, item.params]);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">
          Referral Settings for {item.name}
        </h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <AmountInput
                  value={{
                    amount: formValues?.cumulativeThreshold ?? 0,
                    currency: SupportedCurrencies.USD,
                  }}
                  label="Referral Transaction Threshold"
                  onChange={(e: AfriexAmount) =>
                    setFormValues((x) => ({
                      ...x,
                      cumulativeThreshold: e?.amount ?? 0,
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <AmountInput
                  value={{
                    amount: formValues?.bonusAmountPerReferrer ?? 0,
                    currency: SupportedCurrencies.USD,
                  }}
                  fixedCurrency={SupportedCurrencies.USD}
                  label="Bonus Amount Per Referrer (in USD)"
                  onChange={(e: AfriexAmount) =>
                    setFormValues((x) => ({
                      ...x,
                      bonusAmountPerReferrer: e?.amount ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <AmountInput
                  fixedCurrency={SupportedCurrencies.USD}
                  value={{
                    amount: formValues?.bonusAmountPerReferee ?? 0,
                    currency: SupportedCurrencies.USD,
                  }}
                  label="Bonus Amount Per Referree (in USD)"
                  onChange={(e: AfriexAmount) =>
                    setFormValues((x) => ({
                      ...x,
                      bonusAmountPerReferee: e?.amount ?? 0,
                    }))
                  }
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ReferralSettingsForm;
