import { capitalize } from "lodash";

export const getFileExtension = (url: string) => {
  const matches = url.match(/\.([a-zA-Z0-9]+)\?/);
  return matches ? matches[1].toLowerCase() : "";
};

export const renderFile = (url: string, docType: string, className = "") => {
  const fileExtension = getFileExtension(url);
  switch (fileExtension) {
    case "jpg":
    case "jpeg":
    case "png":
      return (
        <img
          src={url}
          alt={`Document - ${docType}`}
          className={`rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ${className}`}
        />
      );
    case "pdf":
      return (
        <div
          className={`border p-3 rounded-lg shadow-md ${className} flex flex-col gap-2`}
        >
          <p className="text-sm text-gray-600">
            {capitalize(docType.replace(/_/g, " "))}
          </p>
          <embed
            src={url}
            type="application/pdf"
            width="100%"
            height="200"
            className="rounded-lg"
          />
          <div className="text-center">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-medium text-cyan-700 hover:underline"
            >
              View PDF
            </a>
          </div>
        </div>
      );
    default:
      return <p className="text-gray-600">Unsupported file type</p>;
  }
};
