import { useState } from "react";
import { userService } from "../../../services/userService";
import { AfriexPerson } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";

const useBlockUser = (user: AfriexPerson) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [securityNoteInput, setSecurityNoteInput] = useState<string>();
  const [blockReason, setBlockReason] = useState<string>();
  const [unblockReason, setUnblockReason] = useState<string>();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSecurityNoteChange = (e: any) =>
    setSecurityNoteInput(e?.target?.value ?? "");

  const handleBlockReason = (e: any) => setBlockReason(e?.target?.value ?? "");
  const handleUnblockReason = (e: any) =>
    setUnblockReason(e?.target?.value ?? "");

  const handleBlockUser = async () => {
    try {
      setIsLoading(true);
      await userService.blockUser(
        user.id,
        !!user.isSecurityFlagged,
        securityNoteInput ?? "",
        blockReason ?? "",
        unblockReason ?? ""
      );
      const message = `Successfully ${
        user.isSecurityFlagged ? "Unblocked" : "Blocked"
      } user ${user.name.firstName} ${user.name.lastName}`;

      showSuccessMessage(message);
      toggleModal();
      setIsLoading(false);
      window.location.reload();
    } catch (err: any) {
      const errorMessage = err.message
        ? err.message
        : err.response?.data?.message;
      showErrorMessage(errorMessage);
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    showModal,
    toggleModal,
    handleSecurityNoteChange,
    securityNoteInput,
    handleBlockUser,
    handleBlockReason,
    blockReason,
    handleUnblockReason,
    unblockReason,
  };
};
export default useBlockUser;
