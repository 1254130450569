import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FilterInputProps } from "./types";

const DateFilter = ({ onChange, value, attribute }: FilterInputProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (startDate) {
      onChange((startDate as Date)?.toISOString(), attribute);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, [value]);

  const handleChange = (date: Date) => {
    setStartDate(date);
    onChange(date?.toISOString(), attribute);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleChange}
      className="rounded-l-md border-r-1 border-l-1  border-gray-300  text-gray-900 focus:ring-indigo-500 focus:border-blue-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
    />
  );
};

export default DateFilter;
