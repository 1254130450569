import { useCallback, useEffect, useState } from "react";
import { referralService } from "../../../services/referralService";
import { AfriexReferralTier } from "../../../types";
import { AfriexQueryParams } from "../../../types/AfriexQueryParams";
import { TierListProps } from "./types";

const useSignupBonusList = (): TierListProps<AfriexReferralTier> => {
  const [tierList, setTierList] = useState<AfriexReferralTier[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);

  const fetchTierList = useCallback(async (params: AfriexQueryParams) => {
    try {
      setError(null);
      setIsLoading(true);
      const { data: tiers, total } =
        await referralService.getSignupCodeList(params);
      setTierList(tiers);
      setItemTotal(total ?? 0);
    } catch (e) {
      setError(e as any);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTierList({});
  }, [fetchTierList]);

  return {
    error,
    fetchList: fetchTierList,
    isLoading,
    page,
    setPage,
    total: itemTotal,
    list: tierList,
  };
};

export default useSignupBonusList;
