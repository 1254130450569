import { UserDetailsReturnProps } from "./types";

import FundUserWallet from "./FundUserWallet";
import { Link, Swap, Button } from "../../../components";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import UserWalletBalance from "./UserWalletBalance";
import React from "react";
import { AfriexActions } from "../../../types";
import { AfriexPermissions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

type TransactionViewProps = Pick<
  UserDetailsReturnProps,
  "walletBalances" | "isLoadingWalletBalances" | "user"
>;

const TransactionsView: React.FC<TransactionViewProps> = ({
  walletBalances,
  isLoadingWalletBalances,
  user,
}) => {
  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Transactions".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex justify-between">
        <UserWalletBalance
          isLoadingWalletBalances={isLoadingWalletBalances}
          walletBalances={walletBalances}
        />

        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_CREDIT_DEBIT_USER}
        >
          <FundUserWallet user={user} />
        </PermissionsProvider>
      </div>

      <div className="flex flex-col mt-5 gap-3 w-4/12">
        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_SWAP_USER_BALANCE}
        >
          <Swap userId={user.id} key={user.id} />
        </PermissionsProvider>

        <PermissionsProvider
          permission={AfriexPermissions.TRANSACTIONS_PAGE}
          action={AfriexActions.CLICK_VIEW_USER_TRANSACTIONS}
        >
          <Link
            to={`/transactions/user/${user.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="w-max"
          >
            <Button
              colorScheme="cyan"
              variant="ghost"
              rightIcon={<ExternalLinkIcon className="w-5 h-5" />}
              className="no-padding"
            >
              View user transactions
            </Button>
          </Link>
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default TransactionsView;
