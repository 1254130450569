import { useReducer, useState } from "react";
import { referralService } from "../../../services/referralService";
import { AfriexReferralTier } from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import updateTierReducer from "./updateTierReducer";
import { pick } from "lodash";

const useTierUpdate = (
  onClose: VoidFunction,
  initialData: Partial<AfriexReferralTier> = {}
) => {
  const [tier, dispatch] = useReducer(
    updateTierReducer,
    initialData as AfriexReferralTier
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (rawTier: AfriexReferralTier) => {
    setIsLoading(true);
    try {
      if (!rawTier.name) {
        showErrorMessage(`Please provide the name of the referral tier`);
        setIsLoading(false);
        return;
      }

      const tier: AfriexReferralTier = {
        ...rawTier,
      };

      if (rawTier.id) {
        const fieldsToInclude = [
          "name",
          "bonusAmountPerReferrer",
          "cumulativeThreshold",
          "transactionWindow",
          "bonusAmountPerReferree",
          "referralCode",
          "shouldAutomatePayout",
          "shouldSkipReferrerPayout",
          "shouldSkipReferreePayout",
          "isDeactivated",
        ];
        await referralService.updateTier(
          rawTier.id,
          pick(tier, fieldsToInclude) as AfriexReferralTier
        );
        showSuccessMessage(`Referral tier updated successfully`);
      } else {
        await referralService.createTier(tier);
        showSuccessMessage(`Referral tier saved successfully`);
      }

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      showErrorMessage(`Error saving Tier ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    dispatch,
    handleSubmit,
    isLoading,
    tier,
  };
};

export default useTierUpdate;
