import SpiningLoader from "../../../components/common/SpinningLoader";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexReferralTier,
} from "../../../types";
import * as formatDate from "../../../utils/dateFormatter";
import { formatName } from "../../../utils/formatName";
import SignupBonusUpdateButton from "./SignupBonusUpdateButton";
type TierSummaryProp = {
  item: AfriexReferralTier;
  isLoading?: boolean;
  isDetail?: boolean;
};

const SignupBonusSummary = ({ item, isLoading }: TierSummaryProp) => {
  const status = item?.isDeactivated ? "Inactive" : "Active";

  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{item.name}</h2>
      </div>

      <div>
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Bonus Amount Per User</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.bonusAmountPerReferree} {item.currencyCode}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referral Code</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.referralCode}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">{status}</p>
        </div>

        {item.admin && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Approved By</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {formatName(item.admin)}
            </p>
          </div>
        )}

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date Created</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(item.createdAt?.toString() ?? "")}
          </p>
        </div>
      </div>

      <PermissionsProvider
        permission={AfriexPermissions.REFERRAL_PAGE}
        action={AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE}
      >
        <div className="flex items-center justify-end gap-6 mt-4">
          <SignupBonusUpdateButton initialData={item} />
        </div>
      </PermissionsProvider>
    </div>
  );
};

export default SignupBonusSummary;
