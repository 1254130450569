import React from "react";
import { AfriexDispute } from "../../types/AfriexDispute";
import { Link } from "react-router-dom";
import { Button } from "../../components";
import { DisputeTableProps } from "./DisputeTable";
import * as formatDate from "../../utils/dateFormatter";
import { AfriexActions } from "../../types";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../types";

const getFieldsToDisplay = (data: AfriexDispute) => {
  return [
    { "Dispute ID": data.id },
    {
      "User ID": (
        <React.Fragment>
          <span className="mr-3">{data.userId}</span>
          <Link
            to={`/users/${data.userId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="ghost" className="no-padding no-margin">
              View User Profile
            </Button>
          </Link>
        </React.Fragment>
      ),
    },
    {
      "Payment ID": (
        <React.Fragment>
          <span className="mr-3">{data.paymentId}</span>
          <Link
            to={`/transactions/${data.paymentId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="ghost" className="no-padding no-margin">
              View Transaction
            </Button>
          </Link>
        </React.Fragment>
      ),
    },
    { Reason: data.reason },
    { Comments: data.userNote },
  ];
};

const DisputeSummary = ({
  data,
  handleUpdateDisputeModal,
}: {
  data: AfriexDispute;
  handleUpdateDisputeModal: DisputeTableProps["handleUpdateDisputeModal"];
}) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-10 py-5 sm:py-10 flex flex-col gap-3">
      <div>
        {getFieldsToDisplay(data)?.map((field, idx) => {
          const key = Object.keys(field)[0];
          const value = Object.values(field)[0];

          if (!value || value?.length === 0) {
            return null;
          }
          return (
            <div
              key={idx}
              className="mb-2 py-2 px-3 flex gap-3 bg-gray-100 justify-between"
            >
              <p className="text-slate-500 text-sm">{key}</p>
              <p className="text-sm font-medium text-gray-700 w-2/3 overflow-hidden whitespace-normal">
                {value}
              </p>
            </div>
          );
        })}
      </div>

      {/* Notes */}
      <div className="w-full">
        <h3 className="font-semibold mb-2">Notes:</h3>
        <div>
          {data.notes?.map((n, idx) => {
            return (
              <div
                key={idx}
                className="mb-2 py-2 px-3 flex gap-3 bg-gray-100 justify-between"
              >
                <p className="text-slate-500 text-sm">
                  {formatDate.DDMonYYYY(n.createdAt?.toString(), true)}
                </p>
                <p className="text-sm font-medium text-gray-700 w-2/3 overflow-hidden whitespace-normal">
                  {n.note}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Button */}
      <PermissionsProvider
        permission={AfriexPermissions.DISPUTES_PAGE}
        action={AfriexActions.CLICK_UPDATE_DISPUTES}
      >
        <div className="ml-auto">
          <Button
            name="handleUpdateDisputeModal"
            onClick={() => handleUpdateDisputeModal(data.id)}
            variant="solid"
            colorScheme="cyan"
          >
            Update Dispute
          </Button>
        </div>
      </PermissionsProvider>
    </div>
  );
};

export default DisputeSummary;
