import moment from "moment-timezone";
export function DDMonYYYY(dateString: string, showTime = false): string {
  const time = moment(dateString);
  const localTimeZone = moment.tz.guess(true);
  const date = time.tz(localTimeZone);
  const formatDate = moment(date).format(
    `DD MMM YYYY${showTime ? ", h:mm A" : ""}`
  );
  return formatDate;
}

export function dateTimeInTimeZone(dateString: string): string {
  const time = moment(dateString);
  const localTimeZone = moment.tz.guess(true);
  const date = time.tz(localTimeZone);
  const formatDate = moment(date).format("DD MMMM YYYY, h:mm:ss A z");
  return formatDate;
}
