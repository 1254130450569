const countries: { [key: string]: string } = {
  AE: "🇦🇪",
  AT: "🇦🇺",
  AU: "🇦🇺",
  BE: "🇧🇪",
  BJ: "🇧🇯",
  CA: "🇨🇦",
  CH: "🇨🇭",
  CI: "🇨🇮",
  CM: "🇨🇲",
  CN: "🇨🇳",
  DE: "🇩🇪",
  DK: "🇩🇰",
  EG: "🇪🇬",
  ES: "🇪🇸",
  ET: "🇪🇹",
  EU: "🇪🇺",
  FR: "🇫🇷",
  GB: "🇬🇧",
  GH: "🇬🇭",
  GR: "🇬🇷",
  HT: "🇭🇹",
  ID: "🇮🇩",
  IE: "🇮🇪",
  IN: "🇮🇳",
  IT: "🇮🇹",
  KE: "🇰🇪",
  NG: "🇳🇬",
  NL: "🇳🇱",
  MT: "🇲🇹",
  MX: "🇲🇽",
  PH: "🇵🇭",
  PT: "🇵🇹",
  PK: "🇵🇰",
  RO: "🇷🇴",
  RU: "🇷🇺",
  SA: "🇸🇦",
  SE: "🇸🇪",
  SI: "🇸🇮",
  SG: "🇸🇬",
  TG: "🇹🇬",
  TH: "🇹🇭",
  TZ: "🇹🇿",
  UA: "🇺🇦",
  UG: "🇺🇬",
  US: "🇺🇸",
  ZA: "🇿🇦",
  ZM: "🇿🇲",
};
export default countries;

export const stripeCountries = ["CA", "US", "GB"];
export const mapCountryCodeToFlag = (countryCode: string) => {
  return countries?.[countryCode] ?? "";
};
