import { useParams } from "react-router-dom";
import { Header, Tabs } from "../../../components";
import { TabItem } from "../../../types";
import TierSummary from "./Summary";
import TierUserList from "./TierUserList";
import useTierDetails from "./useTierDetails";

const TierDetails = () => {
  const params = useParams();
  const tierId = params?.tierId ?? "";
  const { tier, isLoading } = useTierDetails(tierId);

  const userCountLabel = tier.userTotal
    ? ` (${tier.userTotal?.toLocaleString()})`
    : "";

  const summaryTab = {
    id: "details",
    title: "Details",
    content: <TierSummary isDetail isLoading={isLoading} item={tier} />,
  };
  const tabs: TabItem[] = tier?.referralCode
    ? [summaryTab]
    : [
        summaryTab,
        {
          id: "users",
          title: "Users" + userCountLabel,
          content: <TierUserList />,
        },
      ];
  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={tier?.name ?? ""} />
      <Tabs tabs={tabs} queryKey={"tab"} />
    </div>
  );
};
export default TierDetails;
