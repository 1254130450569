import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import TierFormModal from "./TierFormModal";
import { AfriexRateInfo, AfriexTier } from "../../../types";
import { isEmpty } from "lodash";

type TierUpdateButtonProps = {
  initialData?: AfriexTier;
  rateInfo?: AfriexRateInfo;
};

const TierUpdateButton = ({
  initialData,
  rateInfo,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Create Rate Tier +"
    : "Update Tier";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <TierFormModal
      isOpen={showFullScreen}
      onClose={toggleFullScreen}
      initialData={initialData}
      rateInfo={rateInfo}
    />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {updateLabel}
    </Button>
  );
};

export default TierUpdateButton;
