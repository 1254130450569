import { Link } from "../../../components";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { appPaths } from "../../../config/routesConfig";
import {
  AfriexActions,
  AfriexCurrency,
  AfriexPermissions,
} from "../../../types";

type SummaryProp = {
  item: AfriexCurrency;
};

const Summary = ({ item }: SummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-10 py-3">
      <div className="flex justify-between items-center w-full">
        <h3 className=" font-semibold">{item?.name} Details</h3>
      </div>

      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Name</div>
          <div className="col-span-2">{item?.name}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Code</div>
          <div className="col-span-2">{item?.code}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Symbol</div>
          <div className="col-span-2">{item?.symbol ?? "Not Set"}</div>
        </div>

        <div className="grid grid-cols-3 gap-3 my-2">
          <div>Status</div>
          <div className="col-span-2">{item?.isActive?.toString()}</div>
        </div>
        <PermissionsProvider
          permission={AfriexPermissions.SETTINGS_PAGE}
          action={AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY}
        >
          <div className="grid grid-cols-3 gap-3 my-2">
            <div className="col-span-6">
              <div className="flex justify-between">
                <span className="text-red-500"></span>
                <Link
                  to={`../${appPaths.config}/currencies/${item?.code}`}
                  className="btn border-2 p-2 text-sm bg-green-500 text-white disabled:opacity-70 disabled:text-slate-200"
                >
                  Manage
                </Link>
              </div>
            </div>
          </div>
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default Summary;
