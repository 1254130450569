import axios from "../api/axios";
import {
  AfriexPaginatedResponse,
  SupportedCountryCodes,
  SupportedCurrencies,
  TransactionChannels,
  TransactionTypes,
} from "../types";
import { AfriexQueryParams } from "../types/AfriexQueryParams";
import { TransactionProcessors } from "../types/ProcessorTypes";

const commonEndpoint = "/v2/admin/blocked/payment-methods";

export interface BlockedPaymentMethod {
  id?: string;
  country: SupportedCountryCodes;
  accountIdentifier: string;
  type: TransactionChannels;
  reason: string;
  provider?: string;
  createdAt?: string;
}

export type BlockedPaymentMethodResponse = AfriexPaginatedResponse<
  BlockedPaymentMethod[]
>;
export type BlockedPaymentMethodFilters = AfriexQueryParams &
  Pick<BlockedPaymentMethod, "accountIdentifier">;

export type UpdateProcessorRequest = {
  currency: SupportedCurrencies;
  type: TransactionTypes;
  paymentMethodName: TransactionProcessors;
};

export const blockedPaymentMethodService = {
  getList: async (
    filters: BlockedPaymentMethodFilters
  ): Promise<BlockedPaymentMethodResponse> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `${commonEndpoint}?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },

  removeOne: async (id: string): Promise<void> => {
    const response = await axios.delete(commonEndpoint + `/${id}`);
    return response.data;
  },
  saveOne: async (
    body: BlockedPaymentMethod
  ): Promise<BlockedPaymentMethod> => {
    const response = await axios.post(commonEndpoint, body);
    return response.data;
  },
};
