import { XIcon } from "@heroicons/react/solid";
import { Field, Form, Formik } from "formik";
import { isEmpty, isFinite, pickBy } from "lodash";
import { Button } from "../../components";
import { SupportedCurrencies, TransactionTypes } from "../../types";
import { logger } from "../../utils/logger";
import { FeePaymentMethod, TransactionFee } from "./type";
import { overrideFeeSchema } from "./validation";

const editableInputClasses = "w-24 rounded-md border-2 border-gray-300";

const FeeEntryModalContent = ({
  fee,
  onSave,
}: {
  fee?: Partial<TransactionFee>;
  onSave: (fee?: TransactionFee) => void;
}) => {
  const inputClasses = editableInputClasses;

  const handleSave = (values: Partial<TransactionFee>) => {
    const {
      paymentMethod,
      baseValue: fixedValue,
      percentValue: percentage,
      maxFeeValue: maxFee,
      currencyPair,
      limitType,
      limit,
    } = values;

    const newOverride: TransactionFee = {
      ...(fee ?? {}),
      paymentMethod: paymentMethod,
      baseValue: isFinite(fixedValue) ? Number(fixedValue) : undefined,
      percentValue: isFinite(percentage) ? Number(percentage) : undefined,
      maxFeeValue: isFinite(maxFee) ? Number(maxFee) : undefined,
      limitType: limitType,
      currencyPair: !isEmpty(currencyPair) ? values.currencyPair : undefined,
      limit: limit,
      type: "override",
      transactionType: values.transactionType ?? TransactionTypes.E2E,
    };

    const cleanOverride = pickBy(
      newOverride,
      (value) => isFinite(value) || !isEmpty(value)
    );
    try {
      // revalidate incase form was modified
      overrideFeeSchema.validateSync(cleanOverride);
    } catch (e: any) {
      logger.log(e.message);
      alert(
        "Error validating fee override. Please check the form and try again."
      );
      return;
    }

    onSave(cleanOverride as any);
  };

  return (
    <div className="override-entry mb-4 ">
      <Formik
        initialValues={{
          type: "override",
          paymentMethod: "",
          currencyPair: "",
          baseValue: "",
          percentValue: "",
          limitType: "",
          limit: "",
          transactionType: "",
          ...(fee ?? {}),
        }}
        validationSchema={overrideFeeSchema}
        onSubmit={(values) => {
          return handleSave(values as any);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => {
          const methodOrCurrencyError =
            errors.paymentMethod || errors.currencyPair;
          const destination = values.currencyPair?.split(":")?.[1];
          const destinationCurrency =
            destination === "*" ? undefined : destination;

          return (
            <Form>
              <div className="flex justify-between items-center">
                <h3 className="text-xl">New Fee</h3>
                <XIcon
                  className="w-7 h-7 text-black"
                  color="black"
                  onClick={() => onSave(undefined)}
                />
              </div>

              <br />
              <div className={`grid gap-4 grid-cols-2`}>
                <div className={`mr-1 col-span-1`}>
                  <label className="my-auto">Transaction Type:</label>
                  <Field
                    as="select"
                    name="transactionType"
                    className={`${inputClasses} w-full block`}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleChange(e);
                    }}
                    value={values.transactionType}
                  >
                    <option value={TransactionTypes.E2E}>E2E</option>
                    {transactionTypes.map((method) => (
                      <option key={method} value={method}>
                        {method}
                      </option>
                    ))}
                  </Field>
                </div>
                <br />
                <hr className="col-span-2" />
                <div className="mr-1">
                  Payment Method:
                  <Field
                    as="select"
                    name="paymentMethod"
                    className={`${inputClasses} w-full block`}
                    disabled={values.currencyPair?.length ?? 0 > 3}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedMethod =
                        Object.create(FeePaymentMethod)[e.target.value];
                      if (selectedMethod !== "") {
                        setFieldValue("currencyPair", undefined);
                      }
                      handleChange(e);
                    }}
                    value={values.paymentMethod}
                  >
                    <option value={""}>Any</option>
                    {methods.map((method) => (
                      <option key={method} value={method}>
                        {method}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="mr-1">
                  Currency Pair:
                  <div className="flex flex-column">
                    <Field
                      as="select"
                      name="sourceCurrency"
                      className={`${inputClasses} w-0 block`}
                      disabled={!isEmpty(values.paymentMethod)}
                      value={values.currencyPair?.split(":")?.[0] ?? "*"}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const destination =
                          values.currencyPair?.split(":")?.[1];
                        const currencyPair = `${e.target.value}:${isEmpty(destination) ? "*" : destination}`;
                        const pair =
                          currencyPair.replace(/\*/g, "")?.length <= 1
                            ? undefined
                            : currencyPair;
                        handleChange("currencyPair")({
                          ...e,
                          target: {
                            name: "currencyPair",
                            value: pair,
                          },
                        });
                        if (
                          values.paymentMethod !== "" &&
                          e.target.value !== "*"
                        ) {
                          setFieldValue("paymentMethod", undefined);
                        }
                      }}
                    >
                      <option value={"*"}>Any</option>
                      {currencyPairs.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Field>
                    <Field
                      as="select"
                      className={`${inputClasses} w-0 block`}
                      name="destinationCurrency"
                      disabled={!isEmpty(values.paymentMethod)}
                      value={values.currencyPair?.split(":")?.[1] ?? "*"}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const source = values.currencyPair?.split(":")?.[0];
                        const currencyPair = `${isEmpty(source) ? "*" : source}:${e.target.value}`;
                        const pair =
                          currencyPair.replace(/\*/g, "")?.length <= 1
                            ? undefined
                            : currencyPair;
                        handleChange("currencyPair")({
                          ...e,
                          target: {
                            name: "currencyPair",
                            value: pair,
                          },
                        });
                        if (
                          values.paymentMethod !== "" &&
                          e.target.value !== "*"
                        ) {
                          setFieldValue("paymentMethod", undefined);
                        }
                      }}
                    >
                      <option value={"*"}>Any</option>
                      {currencyPairs.map((currency) => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div className="col-span-2">
                  <p
                    className={`${methodOrCurrencyError ? "text-red-500" : "text-gray-500"} text-sm`}
                  >
                    {methodOrCurrencyError}
                  </p>
                </div>
                <div className={`mr-1`}>
                  <label className="my-auto">Limit Type:</label>
                  <Field
                    as="select"
                    className={inputClasses}
                    name="limitType"
                    value={values.limitType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleChange(e);
                    }}
                  >
                    <option value={""}>None</option>
                    <option value="above">Above</option>
                    <option value="below">Below</option>
                  </Field>
                </div>
                <div className={`mr-1`}>
                  <label className="my-auto">Limit Value ($):</label>
                  <Field
                    as="input"
                    type="number"
                    className={inputClasses}
                    min={0}
                    name="limit"
                    value={values.limit}
                    disabled={isEmpty(values.limitType)}
                    required={!isEmpty(values.limitType)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                  />
                  {<div className="text-sm text-red-500">{errors.limit}</div>}
                </div>
                <div className="col-span-2">
                  <p className="text-gray-500 text-sm">
                    When limits are set, this fee will be applied only if the
                    limit on transaction amount in the USD is met.
                  </p>
                </div>
                <hr className="col-span-2" />
                <div className={`mr-1`}>
                  <label className="my-auto">
                    Fixed ({destinationCurrency ?? "$"}):
                  </label>
                  <Field
                    as="input"
                    className={inputClasses}
                    type="number"
                    min={0}
                    name="baseValue"
                    value={values.baseValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                  />
                  {errors.baseValue && (
                    <div className="text-sm text-red-500">
                      {errors.baseValue}
                    </div>
                  )}
                </div>
                <div className={" "}>
                  <label className="my-auto">Percentage (%):</label>
                  <Field
                    as="input"
                    type="number"
                    name="percentValue"
                    className={inputClasses}
                    value={values.percentValue}
                    max={5}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <p className="text-gray-500 text-sm">
                    Either fixed amount or percentage be entered or both. <br />
                    Formular of Fee:
                    <span className="mx-1 text-black">
                      fixed + (amount * percent / 100)
                    </span>
                    <br />
                    NB: Fixed fee currency is source currency unless destination
                    currency is specified.
                  </p>
                </div>
              </div>
              <div className=" justify-between mr-3">
                <Button
                  className="bg-blue-500 text-white mt-6 mx-1"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FeeEntryModalContent;

const methods = Object.values(FeePaymentMethod);
const transactionTypes = Object.values([
  TransactionTypes.DEPOSIT,
  TransactionTypes.WITHDRAW,
  TransactionTypes.SWAP,
  TransactionTypes.E2E,
  TransactionTypes.VIRTUAL_CARD_LOAD,
  TransactionTypes.VIRTUAL_CARD_SPEND,
  TransactionTypes.VIRTUAL_CARD_UNLOAD,
  TransactionTypes.ACH,
]);
const currencyPairs = Object.values(SupportedCurrencies);
