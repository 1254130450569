import { AfriexVirtualAccount } from "../../../types";
import VirtualAccountInfo from "./VirtualAccountInfo";

const VirtualAccountInfoView = ({ data }: { data: AfriexVirtualAccount }) => {
  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Global Bank Account Information".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <VirtualAccountInfo data={data} />
    </div>
  );
};

export default VirtualAccountInfoView;
