import { AfriexRate, SupportedAssets } from "../../types";

export const applyDiscountToRates = (
  rates: AfriexRate[],
  discount: number,
  affectedCurrencies?: SupportedAssets[]
): AfriexRate[] => {
  const increase = 1 + Number(discount);
  return rates.map((rate) => ({
    ...rate,
    value:
      (affectedCurrencies && !affectedCurrencies.includes(rate.toSymbol)) ||
      rate.fromSymbol === rate.toSymbol
        ? rate.value
        : (increase * Number(rate.value)).toString(),
  }));
};
