import { useCallback, useEffect, useState } from "react";
import { tierService } from "../../../services/tierService";
import { AfriexTransaction } from "../../../types";
import { AfriexQueryParams } from "../../../types/AfriexQueryParams";
import { TierListProps } from "./types";

const useTierTransactionList = (
  tierId: string
): TierListProps<Partial<AfriexTransaction>> => {
  const [tierTransactionList, setTierTransactionList] = useState<
    Partial<AfriexTransaction>[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);

  const fetchTierTransactionList = useCallback(
    async (params: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data, total } = await tierService.getTierTransactionList(
          tierId,
          params
        );
        setTierTransactionList(data);
        setItemTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [tierId]
  );

  useEffect(() => {
    fetchTierTransactionList({});
  }, [fetchTierTransactionList]);

  return {
    error,
    fetchList: fetchTierTransactionList,
    isLoading,
    page,
    setPage,
    total: itemTotal,
    list: tierTransactionList,
  };
};

export default useTierTransactionList;
