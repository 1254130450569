import { useCallback, useEffect, useState } from "react";
import { TabItem } from "./types";

const useTabs = (tabs: TabItem[], queryKey?: string) => {
  const [currentTab, setCurrentTab] = useState<TabItem>(tabs[0]);

  useEffect(() => {
    if (queryKey) {
      const searchParams = new URLSearchParams(window.location.search);
      const tabId = searchParams.get(queryKey);
      const matchedTab = tabs.find((tab) => tab.id === tabId);

      if (matchedTab) {
        setCurrentTab(matchedTab);
      } else {
        setCurrentTab(tabs[0]);
      }
    }
  }, [queryKey, tabs]);

  const handleTabChange = useCallback(
    (tab: TabItem) => {
      if (queryKey) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(queryKey, tab.id);

        // Update URL without reloading the page
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?${searchParams.toString()}`
        );
      }
      setCurrentTab(tab);
    },
    [queryKey]
  );

  return { currentTab, handleTabChange };
};

export default useTabs;
