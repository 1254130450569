import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const saveLogin = localStorage.getItem("afriex-token");

  if (!isAuthenticated && !saveLogin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
