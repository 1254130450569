export const formatTime = (ms: number) => {
  const seconds = ms / 1000;
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const parts = [];
  if (hrs > 0) parts.push(`${hrs} hrs`);
  if (mins > 0) parts.push(`${mins} mins`);
  if (secs > 0) parts.push(`${secs} secs`);

  return parts.join(" ");
};
