import axios from "../api/axios";
import { AfriexPaymentMethod } from "../types";

import { UserActiveCard } from "../screens/User/Details/types";

export const paymentMethodService = {
  getUserActiveCards: async (userId: string): Promise<UserActiveCard[]> => {
    if (!userId) {
      return [];
    }
    const url = `/v2/admin/payment-methods/cards/${userId}`;
    const response = await axios.get(url);
    const { data } = response;
    return data;
  },
  updateCardBankRegulatedStatus: async (
    userId: string,
    paymentMethodId: string,
    isRegulated: boolean
  ): Promise<AfriexPaymentMethod> => {
    const body = {
      isRegulated,
      userId,
    };
    const response = await axios.patch(
      `/v2/admin/payment-methods/cards/issuer/${paymentMethodId}`,
      body
    );
    return response.data;
  },
};
