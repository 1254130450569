import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RewardFilters, rewardService } from "../../../services/rewardService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { identity, pick, pickBy } from "lodash";
import { AfriexReward, AfriexRewardCard } from "../../../types";
import { replaceUnderScoreIds } from "../../../utils/replaceUnderScoreIds";

export type handler = () => void;
interface UseRewardProps {
  error: any;
  isLoading: boolean;
  isCardLoading: boolean;
  isSearch: boolean;
  handleUpdate: (body: Partial<AfriexReward>) => Promise<void>;
  handleAdd: (body: Partial<AfriexReward>) => Promise<void>;
  handleAddCards: (codeText: string, rewardId: string) => Promise<void>;
  handleSearch: (searchTerm: string) => Promise<void>;
  handleFilters: (filters: RewardFilters) => Promise<void>;
  fetchList: (params: RewardFilters) => Promise<void>;
  fetchCardList: (params: RewardFilters, rewardId: string) => Promise<void>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  total: number;
  cardTotal: number;
  cardList: AfriexRewardCard[];
  list: AfriexReward[];
  item: AfriexReward;
  fetchItem: (id: string) => Promise<void>;
  shouldShowForm: boolean;
  toggleForm: handler;
}

const useReward = (): UseRewardProps => {
  const [list, setList] = useState<AfriexReward[]>([] as AfriexReward[]);
  const [cardList, setCardList] = useState<AfriexRewardCard[]>(
    [] as AfriexRewardCard[]
  );
  const [item, setItem] = useState<AfriexReward>({} as AfriexReward);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [cardTotal, setCardTotal] = useState<number>(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [shouldShowForm, setShouldShowForm] = useState<boolean>(false);
  const [filters, setFilters] = useState<RewardFilters>({} as RewardFilters);

  list?.sort((a, b) => a.name.localeCompare(b.name));

  const fetchList = useCallback(
    async (params: RewardFilters) => {
      try {
        setIsLoading(true);
        const { data: items, total: itemsTotal } = await rewardService.getList({
          ...params,
          ...filters,
        });
        setList(replaceUnderScoreIds(items));
        setTotal(itemsTotal ?? 0);
      } catch (e) {
        showErrorMessage(`Fetching reward list failed:${e}`);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const fetchCardList = useCallback(
    async (params: RewardFilters, rewardId: string) => {
      try {
        if (!rewardId) return;
        setIsCardLoading(true);
        const { data: items, total: itemsTotal } =
          await rewardService.getCardList({ ...params, ...filters }, rewardId);

        setCardList(replaceUnderScoreIds(items));
        setCardTotal(itemsTotal ?? 0);
      } catch (e) {
        showErrorMessage(`Fetching reward list failed:${e}`);
      } finally {
        setIsCardLoading(false);
      }
    },
    [filters]
  );

  const fetchItem = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const item = await rewardService.getItem(id);
      setItem(item);
    } catch (e) {
      showErrorMessage(`Fetching reward details failed:${e}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFilters = useCallback(async (filters: RewardFilters) => {
    setIsSearch(false);
    // remove undefined values
    const cleanedFilters = pickBy(filters, identity);

    setFilters({ page: 0, ...cleanedFilters } as RewardFilters);
  }, []);

  useEffect(() => {
    setIsSearch(false);
    fetchList({} as RewardFilters);
  }, [fetchList]);

  const handleUpdate = useCallback(async (body: Partial<AfriexReward>) => {
    try {
      if (!body || !body?.id) {
        return;
      }
      const { id: rewardId } = body;
      setError(null);
      setIsLoading(true);
      const item = pick(body, [
        "pointsValue",
        "name",
        "tier",
        "type",
        "supportedCountries",
        "imageLink",
        "description",
        "ttl",
        "isDeactivated",
      ]);
      const reward = await rewardService.saveOne(rewardId, item);
      if (reward) {
        showSuccessMessage("Reward updated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Updating reward failed:${e}`);
    }
    setIsLoading(false);
  }, []);

  const handleAdd = useCallback(async (body: Partial<AfriexReward>) => {
    try {
      if (!body) {
        return;
      }

      setError(null);
      setIsLoading(true);
      const reward = await rewardService.createItem(body);
      if (reward) {
        showSuccessMessage("Reward added successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Adding reward failed:${e}`);
    }
    setIsLoading(false);
  }, []);

  const handleAddCards = useCallback(
    async (codeText: string, rewardId: string) => {
      try {
        if (!codeText) {
          showErrorMessage("Please enter codes one on each line");
          return;
        }

        const codes = codeText.split("\n");

        setError(null);
        setIsLoading(true);
        const reward = await rewardService.createCardItems(codes, rewardId);
        if (reward) {
          showSuccessMessage("Reward codes added successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (e) {
        showErrorMessage(`Adding reward codes failed:${e}`);
      }
      setIsLoading(false);
    },
    []
  );

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm) {
        setIsSearch(false);
        setFilters({} as RewardFilters);
        fetchList({} as RewardFilters);
        return;
      }
      setIsSearch(true);
      setFilters({
        page: 0,
        status: searchTerm?.trim()?.toString(),
      } as RewardFilters);
    },
    [fetchList]
  );

  const toggleForm = useCallback(() => {
    setShouldShowForm(!shouldShowForm);
  }, [shouldShowForm]);

  return {
    error,
    isLoading,
    isCardLoading,
    isSearch,
    handleUpdate,
    handleAdd,
    handleAddCards,
    handleSearch,
    handleFilters,
    fetchList,
    fetchCardList,
    page,
    setPage,
    total,
    cardTotal,
    cardList,
    list,
    item,
    fetchItem,
    shouldShowForm,
    toggleForm,
  };
};

export default useReward;
