import { getAdminRoleOptions } from "../../../../constants/formatters";
import { AdminFiltersProps } from "../../../../types/AfriexAdmin";
import { CustomSelect, SearchBar } from "../../../../components";
import { AfriexRoles } from "../../../../types";
import { useMemo } from "react";

const AdminFilters = ({
  currentFilters,
  clearFilters,
  selectCurrentFilters,
  handleAutoComplete,
}: AdminFiltersProps) => {
  const adminRoleOptions = useMemo(
    () => getAdminRoleOptions([AfriexRoles.USER]),
    []
  );
  return (
    <>
      <div className="mt-5 w-full">
        <div className="flex items-center justify-between">
          {/* Left Side if needed */}
          <div className="flex items-center space-x-3 w-3/5">
            <SearchBar
              handleSearch={handleAutoComplete}
              handleAutoComplete={handleAutoComplete}
              clearFilters={clearFilters}
              inputClassName="w-4/6"
              placeholder="Search By Afriex Email"
            />
          </div>

          <div className="flex items-center space-x-3">
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                selectCurrentFilters("roles", e.target.value)
              }
              value={currentFilters.roles}
              name="roles"
              options={adminRoleOptions}
              placeholder="Filter by Role"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminFilters;
