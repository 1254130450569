import { countryService } from "../../../services/countryService";
import {
  AfriexIDType,
  IDOptionsType,
  SupportedCountryCodes,
} from "../../../types";

export const primaryIDOptions = [
  { value: AfriexIDType.Passport, key: "Passport" },
  { value: AfriexIDType.DriversLicense, key: "Driver's License" },
];

export const fetchIDOptions = async (
  countryCode: SupportedCountryCodes
): Promise<{
  primaryIDs: IDOptionsType[];
  secondaryIDs: IDOptionsType[];
}> => {
  const getIDOptions = async (code: SupportedCountryCodes) => {
    try {
      const country = await countryService.getItem(code);
      if (!country || !country.idOptions) {
        console.warn(`No ID options found for country code: ${code}`);
        return { primaryIDs: [], secondaryIDs: [] };
      }

      const primaryIDs = country.idOptions
        .filter((id) => !id.isSecondary)
        .map((id) => ({
          value: id.id?.toLocaleLowerCase().includes("slip")
            ? AfriexIDType.NinSlip
            : (id.idType as AfriexIDType),
          key: id.longName as string,
        }));

      const secondaryIDs = country.idOptions
        .filter((id) => id.isSecondary)
        .map((id) => ({
          value: id.id?.toLocaleLowerCase().includes("slip")
            ? AfriexIDType.NinSlip
            : (id.idType as AfriexIDType),
          key: id.longName as string,
        }));

      return { primaryIDs, secondaryIDs };
    } catch (error) {
      console.error("Error fetching ID options:", error);
      return { primaryIDs: [], secondaryIDs: [] };
    }
  };

  return await getIDOptions(countryCode);
};
