import { useParams } from "react-router-dom";
import { DashboardCard, Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import TransactionSearch from "./TransactionSearch";
import useUserTransactionList from "./useUserTransactionList";
import { dashCardConfig } from "../../../config/menuConfig";
import TransactionTable from "./TransactionTable";
import { PaginationProps } from "../../../components/common/Pagination";

const UserTransactionList = () => {
  const { userId } = useParams();
  const {
    isLoading,
    isSearch,
    isPageReset,
    fetchTransactionList,
    pageInfo,
    transactionList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters,
    selectCurrentFilters,
    selectAppliedFilters,
  } = useUserTransactionList(userId ?? "");
  const transactionCount = pageInfo.total > 0 ? pageInfo.total : 0;
  const debits = pageInfo.summary ? pageInfo.summary.totalDebitCount : "-";
  const debitValue = pageInfo.summary ? pageInfo.summary.totalDebitAmount : "-";
  const credits = pageInfo.summary ? pageInfo.summary.totalCreditCount : "-";
  const creditValue = pageInfo.summary
    ? pageInfo.summary.totalCreditAmount
    : "-";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`User Transactions`} />
      <TransactionSearch
        transactions={transactionList}
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
      />

      {/* Transaction Cards Section */}
      <section className="flex flex-wrap p-3 pl-0 gap-4 mt-2">
        {dashCardConfig.map((content, idx) => {
          const label = content.label.toLocaleLowerCase();
          const value = label.includes("count")
            ? transactionCount
            : label === "debit value"
              ? debitValue
              : label === "credit value"
                ? creditValue
                : label === "credits"
                  ? credits
                  : debits;
          return (
            <DashboardCard
              key={idx}
              label={content.label}
              value={value}
              isMoney={content.isMoney}
            />
          );
        })}
      </section>

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <TransactionTable items={transactionList} />
        )}
      </section>

      {transactionList && transactionList.length ? (
        <Pagination
          onPageChange={fetchTransactionList as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default UserTransactionList;
