export const transformRatesMapToMatrix = (ratesMap: any): number[][] => {
  const size = Object.keys(ratesMap)?.length;
  const ratesMatrix: number[][] = new Array(size)
    .fill(null)
    .map(() => new Array(size).fill(0));
  for (let i = 0; i < size; i += 1) {
    const fromSymbol = Object.keys(ratesMap)[i];
    for (let j = 0; j < Object.keys(ratesMap[fromSymbol])?.length; j += 1) {
      const toSymbol = Object.keys(ratesMap[fromSymbol])[j];
      ratesMatrix[i][j] = ratesMap[fromSymbol][toSymbol];
    }
  }
  return ratesMatrix;
};
