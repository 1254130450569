export interface AfriexSecurityNoteRequest {
  note: string;
  admin?: string;
  type?: string;
  user: string;
}
export interface AfriexSecurityNote {
  id: string;
  createdAt: string;
  note: string;
  type: string;
  user: string;
  admin?: string;
  adminInfo?: AdminInfo;
  updatedAt?: string;
  reason?: string;
  unblockReason?: string;
  userState?: NoteUserState;
}

export enum NoteUserState {
  BLOCKED = "BLOCKED",
  UNBLOCKED = "UNBLOCKED",
  RESTRICTED = "RESTRICTED",
  NON_ACTION = "NON_ACTION",
}

export interface AfriexSecurityNotesQueryParams {
  limit?: number;
  page?: number;
}

type AdminInfo = {
  id: string;
  name: string;
  role: string;
};
