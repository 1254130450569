import { AfriexPerson } from "../../../types/AfriexPerson";
import { AfriexTransaction } from "../../../types";
import { ExclamationIcon } from "@heroicons/react/outline";

const RewardTransactionTable = ({
  items,
  user,
}: {
  items: AfriexTransaction[];
  user: AfriexPerson;
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-450 py-2 px-3 rounded-md bg-white border">
        <div className="flex flex-col gap-1 w-1/2 justify-center items-center">
          <p className="text-sm font-medium text-gray-600">Tier</p>
          <p className="font-semibold capitalize">
            {user?.rewardInfo?.rewardTier}
          </p>
        </div>

        <div className="flex flex-col gap-1 w-1/2 justify-center items-center">
          <p className="text-sm font-medium text-gray-600">Points</p>
          <p className="font-semibold">{user?.rewardInfo?.rewardPoints}</p>
        </div>
      </div>

      {items.length ? (
        <div>
          <p className=" font-semibold mb-3">History</p>
          <div className="flex w-450 flex-col justify-center rounded-md border border-receipt-line bg-white">
            {items?.map((transaction) => (
              <div
                key={transaction.id}
                className="flex justify-between py-3 px-4 border-b border-gray-200 last:border-b-0"
              >
                <div className="text-sm">
                  <p className="flex items-center text-gray-800 font-semibold">
                    To:{" "}
                    <span className="ml-2 uppercase">
                      {transaction.destinationAccountDetails.accountName}
                    </span>
                  </p>
                  <p className="text-gray-600 font-medium text-sm">
                    - {transaction.destinationAmount}{" "}
                    {transaction.destinationAsset}
                  </p>
                </div>
                <p className="flex items-center text-green-600 text-sm">
                  +{transaction?.meta?.rewardPoints} pts
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-1 justify-center mt-5">
          <ExclamationIcon className="w-5 h-5 text-failed-500" />
          <p className="text-gray-800">
            {"No recent reward transactions found."}
          </p>
        </div>
      )}
    </div>
  );
};

export default RewardTransactionTable;
