import axios from "../api/axios";
import {
  AfriexCurrency,
  AfriexPaginatedResponse,
  SupportedCurrencies,
  TransactionTypes,
} from "../types";
import { AfriexQueryParams } from "../types/AfriexQueryParams";
import { TransactionProcessors } from "../types/ProcessorTypes";

const commonEndpoint = "/v2/admin/currencies";

export type CurrencyResponse = AfriexPaginatedResponse<AfriexCurrency[]>;
export type CurrencyFilters = AfriexQueryParams;

export type UpdateProcessorRequest = {
  currency: SupportedCurrencies;
  type: TransactionTypes;
  paymentMethodName: TransactionProcessors;
};

export const currencyService = {
  getList: async (filters: CurrencyFilters): Promise<CurrencyResponse> => {
    const paginationParams = {
      page: 0,
      limit: 20,
      columns: ["name", "code", "symbol", "isActive"],
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `${commonEndpoint}?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  getItem: async (currencyCode: string): Promise<AfriexCurrency> => {
    const response = await axios.get(commonEndpoint + `/${currencyCode}`);

    return response.data;
  },
  createItem: async (
    body: Partial<AfriexCurrency>
  ): Promise<AfriexCurrency> => {
    const response = await axios.post(commonEndpoint, body);
    return response.data;
  },
  saveOne: async (
    itemId: string,
    body: Partial<AfriexCurrency>
  ): Promise<AfriexCurrency> => {
    const response = await axios.patch(commonEndpoint + `/${itemId}`, body);
    return response.data;
  },
};
