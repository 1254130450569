import { Modal, Button, SpinningLoader, Input } from "../../../components";
import { useCallback, useState } from "react";
import { AfriexCurrency } from "../../../types";

type FormProps = {
  onClose: VoidFunction;
  handleSave: (body: AfriexCurrency) => Promise<void>;
};
const UpdateForm = ({ onClose, handleSave }: FormProps) => {
  const [formValues, setFormValues] = useState<AfriexCurrency>({
    isActive: true,
  } as AfriexCurrency);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await handleSave(formValues);
    setIsLoading(false);
  }, [formValues, handleSave]);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">Add New Currency</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <Input
                  type="text"
                  label="Name"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      name: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Input
                  type="text"
                  label="Code"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      code: e?.target?.value?.toUpperCase() ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Input
                  type="text"
                  label="Symbol"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      symbol: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateForm;
