import { ICurrency } from "../types/Currency";

const currencies: ICurrency[] = [
  {
    code: "USD",
    name: "US Dollar",
    countryCode: "US",
  },
  {
    code: "NGN",
    name: "Nigerian Naira",
    countryCode: "NG",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
    countryCode: "CA",
  },

  {
    code: "GHS",
    name: " Ghanaian Cedis",
    countryCode: "GH",
  },

  {
    code: "KES",
    name: "Kenyan Shilling",
    countryCode: "KE",
  },
  {
    code: "UGX",
    name: "Ugandan Shilling",
    countryCode: "UG",
  },

  {
    code: "EUR",
    name: "Euro",
    countryCode: "EU",
  },
  {
    code: "GBP",
    name: "British Pounds",
    countryCode: "GB",
  },
  {
    code: "XOF",
    name: "West African CFA Franc",
    countryCode: "CI",
  },
  {
    code: "XAF",
    name: "Central African CFA Franc",
    countryCode: "CM",
  },
  {
    code: "HTG",
    name: "Haitian Gourde",
    countryCode: "HT",
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
    countryCode: "ET",
  },
  {
    code: "PKR",
    name: "Pakistani Rupee",
    countryCode: "PK",
  },
];

export default currencies;
