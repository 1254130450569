export default function SpinningLoader({ size = 24 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      className={`w-${size} h-${size} text-red-600 animate-spin`}
    >
      <path
        fill="#2D50A7"
        d="M267.636 0c-12.853 0-23.273 10.42-23.273 23.273v93.091c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273V23.273C290.909 10.42 280.489 0 267.636 0z"
      ></path>
      <path
        fill="#73A1FB"
        d="M267.638 372.364c-12.853 0-23.273 10.42-23.273 23.273v93.091c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273v-93.091c0-12.853-10.42-23.273-23.273-23.273z"
      ></path>
      <path
        fill="#355EC9"
        d="M185.355 140.808L119.529 74.98c-9.086-9.089-23.822-9.089-32.912 0-9.089 9.089-9.089 23.824 0 32.912l65.826 65.828a23.202 23.202 0 0016.455 6.817 23.197 23.197 0 0016.455-6.817c9.091-9.089 9.091-23.823.002-32.912z"
      ></path>
      <g fill="#C4D9FD">
        <path d="M477.091 232.727h-46.545c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273h46.545c12.853 0 23.273-10.42 23.273-23.273s-10.42-23.273-23.273-23.273zM382.83 338.283c-9.087-9.089-23.823-9.087-32.912 0-9.089 9.089-9.087 23.823 0 32.912l65.828 65.825a23.202 23.202 0 0016.457 6.816 23.198 23.198 0 0016.455-6.816c9.089-9.089 9.089-23.824 0-32.912l-65.828-65.825z"></path>
      </g>
      <path
        fill="#3D6DEB"
        d="M151.273 256c0-12.853-10.42-23.273-23.273-23.273H34.909c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273H128c12.853 0 23.273-10.42 23.273-23.273z"
      ></path>
      <path
        fill="#5286FA"
        d="M185.355 338.283c-9.087-9.089-23.824-9.089-32.912 0l-65.825 65.825c-9.089 9.087-9.089 23.824 0 32.912a23.202 23.202 0 0016.457 6.816 23.19 23.19 0 0016.455-6.816l65.825-65.825c9.089-9.087 9.089-23.823 0-32.912z"
      ></path>
    </svg>
  );
}
