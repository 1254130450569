import axios from "../api/axios";
import { NotifyFormData } from "../screens/Processor/Metrics/NotifyForm";
import { SupportedCurrencies, TransactionTypes } from "../types";
import { TransactionProcessors } from "../types/ProcessorTypes";

const commonEndpoint = "/v2/admin/processors";

export type ProcessorResponse = Partial<
  Record<TransactionTypes, Partial<Record<SupportedCurrencies, string>>>
>;

export type UpdateProcessorRequest = {
  currency: SupportedCurrencies;
  type: TransactionTypes;
  processorName: TransactionProcessors;
};
export type DownTimeNotificationRequest = {
  fromCurrency?: SupportedCurrencies;
  toCurrency?: SupportedCurrencies;
  transactionType: TransactionTypes;
  paymentInstrument?: string;
  processorName: TransactionProcessors;
  expiresAt: number; // unix timestamp
  message: string;
};

export const processorService = {
  getProcessorLatest: async (): Promise<ProcessorResponse> => {
    const response = await axios.get(`${commonEndpoint}/latest`);
    return response.data;
  },
  getProcessorAll: async (): Promise<TransactionProcessors[]> => {
    const response = await axios.get(commonEndpoint);
    return response.data;
  },
  updateProcessors: async (
    body: UpdateProcessorRequest
  ): Promise<ProcessorResponse> => {
    const response = await axios.patch(commonEndpoint, body);
    return response.data;
  },
  createDowntimeNotification: async (body: NotifyFormData): Promise<any> => {
    let expiresAt = Date.now() + 60 * 60 * 1000;
    switch (body.ttl) {
      case "2hrs":
        expiresAt = Date.now() + 2 * 60 * 60 * 1000;
        break;
      case "1hr":
        expiresAt = Date.now() + 60 * 60 * 1000;
        break;
      case "30mins":
        expiresAt = Date.now() + 30 * 60 * 1000;
        break;
      default: // 1hr
        expiresAt = Date.now() + 60 * 60 * 1000;
    }
    // slice off last 3 chars to get currency code
    const toCurrency = body.selectedCorridorPair
      ?.slice(-3)
      .toUpperCase() as SupportedCurrencies;
    const fromCurrency = body.selectedCorridorPair
      ?.slice(0, 3)
      .toUpperCase() as SupportedCurrencies;

    const { message } = body;
    const request: DownTimeNotificationRequest = {
      fromCurrency,
      toCurrency,
      transactionType: TransactionTypes.WITHDRAW,
      paymentInstrument: body.selectedPaymentInstrument,
      processorName: body.selectedProcessor as TransactionProcessors,
      expiresAt,
      message,
    };

    const response = await axios.post(`${commonEndpoint}/downtime`, request);
    return response.data;
  },

  getProcessorMetrics: async (): Promise<any> => {
    const response = await axios.get(`${commonEndpoint}/metrics`);
    return response.data;
  },
};
