import {
  Modal,
  Button,
  SpinningLoader,
  Input,
  CheckBox,
  AmountInput,
  CustomInput,
} from "../../../components";
import { AfriexAmount, AfriexReferralTier } from "../../../types";
import useSignupBonusUpdate from "./useSignupBonusUpdate";
import { TierActionType } from "./types";
import { isEmpty } from "lodash";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";

interface SignupBonusProps extends Pick<UserModalProps, "isOpen" | "onClose"> {
  data?: AfriexReferralTier;
}

const SignupBonusForm = ({ isOpen, onClose, data }: SignupBonusProps) => {
  const { dispatch, isLoading, handleSubmit, tier } = useSignupBonusUpdate(
    onClose,
    data
  );

  const {
    name,
    isDeactivated,
    referralCode,
    bonusAmountPerReferree,
    currencyCode,
    id: referralId,
  } = tier as AfriexReferralTier;

  const updateAction = {
    type: TierActionType.UPDATE_TIER,
    payload: {},
  };
  const updateLabel = isEmpty(data)
    ? "Create New Signup Bonus Tier +"
    : "Update Signup Bonus Tier";
  const submitLabel = isEmpty(data) ? "Submit" : "Update";
  const isDisabled = isEmpty(tier);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title={updateLabel}
    >
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(tier as AfriexReferralTier) as any;
            }}
            className="flex flex-col space-y-5"
          >
            <div className="flex flex-col gap-3 px-2">
              <CustomInput
                name="name"
                label="Name"
                type="text"
                value={name ?? ""}
                className="w-full"
                onChange={(e) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      name: e.target?.value ?? name,
                    },
                  })
                }
              />

              <AmountInput
                label="Bonus Amount Per Referee"
                fixedCurrency={referralId ? currencyCode : undefined}
                value={
                  {
                    amount: bonusAmountPerReferree as any,
                    currency: currencyCode,
                  } as any
                }
                onChange={(value: AfriexAmount) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      bonusAmountPerReferree: value?.amount
                        ? Number(value?.amount)
                        : bonusAmountPerReferree,
                      currencyCode: value?.currency
                        ? value?.currency
                        : currencyCode,
                    },
                  })
                }
              />

              <CustomInput
                name="referralCode"
                label="Referral Code"
                type="text"
                value={referralCode ?? ""}
                className="w-full"
                onChange={(e) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      referralCode: e.target?.value?.toLowerCase() ?? "",
                    },
                  })
                }
              />

              <CheckBox
                label="Mark as active"
                type="checkbox"
                checked={!isDeactivated}
                onChange={(e: any) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      isDeactivated: !e.target?.checked ?? isDeactivated,
                    },
                  })
                }
              />

              <div className="flex w-full justify-end space-x-2 py-3">
                <Button
                  type="button"
                  onClick={onClose}
                  variant="outline"
                  colorScheme="cyan"
                >
                  Cancel
                </Button>

                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="solid"
                  colorScheme="cyan"
                >
                  {submitLabel}
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </CustomModal>
  );
};

export default SignupBonusForm;
