type HeaderProps = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  icon?: React.ReactNode;
};
/**
 * Component to render a header
 * @param param0
 * @returns
 */
export default function Header({
  title,
  icon,
  children,
}: HeaderProps): JSX.Element {
  return (
    <header className="mt-5">
      <div className="flex items-center">
        {icon && <span className="mr-2">{icon}</span>}
        <h1 className="text-xl font-semibold">{title}</h1>
      </div>
      {children}
    </header>
  );
}
