import { PropsWithChildren, useState } from "react";
import { formatTime } from "./utils";

const Accordion = ({ title, children }: PropsWithChildren<any>) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={toggleAccordion}
        className="w-full text-left py-2 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
      >
        <div className="flex justify-between items-center">
          <span>{title}</span>
          <span>{isOpen ? "-" : "+"}</span>
        </div>
      </button>
      {isOpen && <div className="px-4 pb-4">{children}</div>}
    </div>
  );
};

export const CurrencyAccordion = ({ data: groupedByCurrency }: any) => {
  return (
    <div>
      {Object.keys(groupedByCurrency).map((currency) => (
        <Accordion key={currency} title={`Currency: ${currency}`}>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">
                  Corridor Pair
                </th>
                <th className="border border-gray-300 px-4 py-2">Processor</th>
                <th className="border border-gray-300 px-4 py-2">
                  Number of Transactions
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Success Rate (%)
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Average Time
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(groupedByCurrency[currency]).map((item, index) => {
                const {
                  corridor_pair,
                  processor,
                  total_no_txn_per_p_cp_pi,
                  success_count,
                  total_duration,
                } = item as any;
                const successRate =
                  (success_count / total_no_txn_per_p_cp_pi) * 100;
                const avgTime = success_count
                  ? total_duration / success_count
                  : 0;

                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  >
                    <td className="border border-gray-300 px-4 py-2">
                      {corridor_pair}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {processor}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {total_no_txn_per_p_cp_pi}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {successRate}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {formatTime(avgTime)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Accordion>
      ))}
    </div>
  );
};

export const ProcessorAccordion = ({ data: groupedByProcessor }: any) => {
  return (
    <div>
      {Object.keys(groupedByProcessor).map((processor) => (
        <Accordion key={processor} title={`Processor: ${processor}`}>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">
                  Corridor Pair
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Number of Transactions
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Success Rate (%)
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  Average Time
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedByProcessor[processor]).map(
                (corridorPair, index) => {
                  const totalTx =
                    groupedByProcessor[processor][corridorPair]
                      .total_no_txn_per_p_cp_pi;
                  const successTx =
                    groupedByProcessor[processor][corridorPair].success_count;
                  const totalDuration =
                    groupedByProcessor[processor][corridorPair].total_duration;
                  const successRate = (successTx / totalTx) * 100;
                  const avgTime = successTx ? totalDuration / successTx : 0;

                  return (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                    >
                      <td className="border border-gray-300 px-4 py-2">
                        {corridorPair}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {totalTx}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {successRate.toFixed(2)}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {formatTime(avgTime)}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Accordion>
      ))}
    </div>
  );
};
