import { useCallback, useEffect, useState } from "react";
import { referralService } from "../../../services/referralService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";

const useTierUserUpdate = (
  onClose: VoidFunction,
  tierId: string,
  userId?: string
) => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!userEmail && !userId) {
        showErrorMessage(
          `Please provide the email, phone number or user ID of the user`
        );
        setIsLoading(false);
        return;
      }

      if (userId) {
        await referralService.removeUserFromTier(tierId, userId);
        showSuccessMessage(`User: ${userId} removed from tier successfully`);
      } else {
        await referralService.addUserToTier(tierId, userEmail?.trim());
        showSuccessMessage(`User: ${userEmail} added to tier successfully`);
      }

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      const errorMessage = userId
        ? `Error removing user from tier ${e}`
        : `Error adding user to tier ${e}`;
      showErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [onClose, tierId, userId, userEmail]);

  const handleBulkUpload = useCallback(async () => {
    setIsLoading(true);
    try {
      const raw = userEmail?.trim();
      if (!raw) {
        showErrorMessage(
          `Please provide the email, phone number or user ID of each user one per line`
        );
        setIsLoading(false);
        return;
      }
      const userIds = raw.split("\n")?.map((id) => id.trim());
      if (!userIds) {
        showErrorMessage(`Please make sure that each user is on a new line`);
        setIsLoading(false);
        return;
      }

      await referralService.addUsersToTier(tierId, userIds);
      showSuccessMessage(`Users added to tier successfully`);

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      const errorMessage = userId
        ? `Error removing user from tier ${e}`
        : `Error adding user to tier ${e}`;
      showErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [onClose, tierId, userEmail, userId]);

  useEffect(() => {
    if (userId) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    handleSubmit,
    handleBulkUpload,
    isLoading,
    setUserEmail,
    userEmail,
  };
};

export default useTierUserUpdate;
