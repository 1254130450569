import { omit } from "lodash";
import { Button } from "..";
import { ComponentProps } from "react";

const ImageViewer = (
  props: Partial<HTMLImageElement> &
    Pick<ComponentProps<typeof Button>, "onClick">
) => {
  return (
    <>
      <label>{props.alt}</label>
      <img
        {...(omit(props, ["onClick"]) as any)}
        style={{ width: "30%" }}
        alt={props.alt}
      />
      <div className="flex justify-end">
        <Button variant="ghost" onClick={props.onClick}>
          Change {props.alt}
        </Button>
      </div>
      <hr />
    </>
  );
};

export default ImageViewer;
