import { AfriexTier } from "../../../types";

const UserTierTable = ({
  tierInfo,
  transactionCount,
}: {
  tierInfo: AfriexTier;
  transactionCount: number;
}) => (
  <div>
    <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
      <p className="text-slate-500 text-sm">Current Promo</p>
      <p className="text-sm font-medium text-gray-700 w-1/2">
        {tierInfo?.name}
      </p>
    </div>

    <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
      <p className="text-slate-500 text-sm">Transactions Left</p>
      <p className="text-sm font-medium text-gray-700 w-1/2">
        {transactionCount}
      </p>
    </div>

    <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
      <p className="text-slate-500 text-sm">Total Transactions Allowed</p>
      <p className="text-sm font-medium text-gray-700 w-1/2">
        {tierInfo?.transactionCountThreshold}
      </p>
    </div>

    <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
      <p className="text-slate-500 text-sm">Tier ID</p>
      <p className="text-sm font-medium text-gray-700 w-1/2">{tierInfo?.id}</p>
    </div>
  </div>
);

export default UserTierTable;
